import { Accordeon } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';

import { useStore } from '../../../../../../../shared/utils/IoC';
import { FieldsStore } from '../../../../../../../shared/mobx/stores';
import { EFieldFill } from '../../../../../../constants/FieldFill.enum';
import TopPanelStyled from '../../TopPanelControls.styles';

import { FieldFillController, FieldFillStore } from './mobx';
import { TooltipLabelAccordion } from './modules/CultureZonesFill/components';
import { IndicesTimeline } from './modules/IndicesFill/components';
import { IndicesFillStrategy } from './modules/IndicesFill/mobx';
import Styled from './FieldFillControls.styles';

export const FieldFillControls = observer(() => {
  // Контроллеры
  const fieldFillController = useStore(FieldFillController);

  // Сторы
  const fieldFillStore = useStore(FieldFillStore);
  const fieldsStore = useStore(FieldsStore);

  const [openArr, setOpenArr] = useState([false, false]);
  const accWrapper = useRef(null);

  const accordeonWrapperRef = useOnclickOutside(() => {
    setOpenArr([false, false]);
  });

  const handleFillSelect = (value: EFieldFill) => {
    fieldFillController.setFillStrategy(value);
  };

  useEffect(() => {
    return () => {
      fieldFillController.setFillStrategy(EFieldFill.None);
    };
  }, []);

  if (!fieldsStore.hasFields) {
    return null;
  }

  return (
    <React.Fragment>
      <TopPanelStyled.ControlPanelItem ref={accordeonWrapperRef}>
        <Styled.AccordeonWrapper ref={accWrapper}>
          <Accordeon
            onOpen={() => {
              setOpenArr([true, false]);
            }}
            onHover={() => 1}
            onChange={handleFillSelect}
            onMouseOut={() => 1}
            options={fieldFillController.getFillOptionsSet()}
            placeholder={'Выберите заливку'}
            isOpen={openArr[0]}
            dataTestId={'fill-switcher'}
            value={fieldFillStore.fieldFillValue}
          />
        </Styled.AccordeonWrapper>
      </TopPanelStyled.ControlPanelItem>

      {fieldFillController.showTooltipAccordion() && (
        <TopPanelStyled.ControlPanelItem ref={accordeonWrapperRef}>
          <Styled.AccordeonWrapper ref={accWrapper}>
            <TooltipLabelAccordion isOpen={openArr[1]} onOpen={() => setOpenArr([false, true])} />
          </Styled.AccordeonWrapper>
        </TopPanelStyled.ControlPanelItem>
      )}

      {fieldFillStore.strategy instanceof IndicesFillStrategy && (
        <TopPanelStyled.ControlPanelItem fullWidth isMarginRight>
          <IndicesTimeline indicesFillStrategy={fieldFillStore.strategy} />
        </TopPanelStyled.ControlPanelItem>
      )}
    </React.Fragment>
  );
});
