import { Field } from '../../../../../../../../../../api/models/field.model';

interface ICultures<T> {
  cultures: T;
  culturesPic: string;
}

const getCulturesNameListAndPicture = (cultureZones: Field['cultureZones']): ICultures<string> => {
  const culturesNameListAndPicture = cultureZones.reduce<ICultures<string[]>>(
    (acc, { culture }) => {
      if (culture.attrs?.largeAssistancePic) {
        acc.culturesPic = culture.attrs.largeAssistancePic;
      }

      if (acc.cultures) {
        acc.cultures.push(culture.name);
      } else {
        acc.cultures = [culture.name];
      }

      return acc;
    },
    {} as ICultures<string[]>
  );

  return {
    ...culturesNameListAndPicture,
    cultures: culturesNameListAndPicture.cultures.join(', '),
  };
};

export const getCultures = (field: Field): ICultures<string> =>
  field?.cultureZones?.length
    ? getCulturesNameListAndPicture(field.cultureZones)
    : {
        cultures: 'Нет культуры',
        culturesPic: '',
      };
