import styled from 'styled-components';

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 8px;

  padding-bottom: 10px;
`;

const StyledContainerClearedHeader = {
  TitleWrapper,
  Wrapper,
};

export default StyledContainerClearedHeader;
