import styled from 'styled-components';

const Error = styled.div`
  color: #df2e22;
  font-feature-settings: 'clig' off, 'liga' off;
  /* body: reg14 */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.1px;
`;

const Errors = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Inputs = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const StyledStoAttributeOdzDependencyNumeric = {
  Error,
  Errors,
  Inputs,
  Wrapper,
};

export default StyledStoAttributeOdzDependencyNumeric;
