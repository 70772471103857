import styled from 'styled-components';

const Svg = styled.svg`
  &:hover {
    & rect {
      transition: all 0.15s ease-in-out;

      fill: #f3f4f8;
    }
  }
`;

const Wrapper = styled.button`
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  background: transparent;
`;

const StyledButtonUpload = {
  Svg,
  Wrapper,
};

export default StyledButtonUpload;
