import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Field } from '../../../../../../../../api/models/field.model';
import {
  Arrow,
  Content as ToolContent,
  IconWrapper,
  Item,
  Label as ToolLabel,
  Wrapper as ToolWrapper,
} from '../../../../../../../shared/components/ToolTip/style';
import { Colors } from '../../../../../../../shared/constans/colors';
import { useWarningBeforeLeavingThePage } from '../../../../../../../shared/hooks/useWarningBeforeLeavingThePage';
import { ReactComponent as AddIcon } from '../assets/add-icon.svg';
import { ReactComponent as BinIcon } from '../../../../../../../shared/static/bin_red.svg';
import { ReactComponent as EditIcon } from '../../../../../../../shared/static/edit.svg';
import { ReactComponent as HistoryLeafIcon } from '../../../../../../static/history-leaf.svg';
import { useStore } from '../../../../../../../shared/utils/IoC';
import {
  AccessRuleVisibility,
  ControlAccessRulesWrapper,
} from '../../../../../../components/AccessRulesWrapper/ControlAccessRulesWrapper';
import { UiStore } from '../../../../../../stores/ui.store';
import { useFieldsRoutes } from '../../../../hooks';

type Props = {
  closeMenu: () => void;
  setIsCardFocused: (value: boolean) => void;
  onDeleteClick: () => void;
  showModal: boolean;
  field: Field;
  dataTestId: string;
  positions: {
    x: number;
    y: number;
  };
};

export const ContextMenu = ({
  positions,
  closeMenu,
  setIsCardFocused,
  showModal,
  field,
  dataTestId,
  onDeleteClick,
}: Props) => {
  // Навигация
  const navigate = useNavigate();
  const fieldsRoutes = useFieldsRoutes();

  // Сторы
  const uiStore = useStore(UiStore);

  const { showWarningBeforeLeavingThePage } = useWarningBeforeLeavingThePage();

  const handleRef = el => {
    if (!el) {
      return;
    }
    if (positions.y + el.clientHeight > window.innerHeight)
      uiStore.openTip(positions.x + 35, window.innerHeight - el.clientHeight);

    el.focus();
  };

  uiStore.setContextMenuCloseFunc(() => {
    closeMenu();
    uiStore.closeTip();
    setIsCardFocused(false);
  });

  const closeContextMenu = () => {
    closeMenu();
    uiStore.closeTip();
    setIsCardFocused(false);
  };

  const onFieldEditClick = () => {
    closeContextMenu();

    if (showModal) {
      showWarningBeforeLeavingThePage(() => {
        navigate(fieldsRoutes.fieldEditRoute(field.id));
      });
      return;
    }

    navigate(fieldsRoutes.fieldEditRoute(field.id));
  };

  const handleSeasonHistoryClick = () => {
    navigate(fieldsRoutes.fieldSeasonsViewRoute(field.id));
    closeContextMenu();
  };

  const handleAddCultureZoneClick = () => {
    navigate(fieldsRoutes.fieldSeasonsViewRoute(field.id), {
      state: { enableDraw: true },
    });

    closeContextMenu();
  };

  return (
    <ToolWrapper
      ref={handleRef}
      onBlur={event => {
        event.stopPropagation();
        closeContextMenu();
      }}
      tabIndex={-1}
      data-test-id={`${dataTestId}context-menu`}
    >
      <ToolContent>
        <ControlAccessRulesWrapper mode={AccessRuleVisibility.Hide} actionName="field.editField">
          <Item onClick={onFieldEditClick}>
            <IconWrapper>
              <EditIcon />
            </IconWrapper>
            <ToolLabel>Редактировать</ToolLabel>
          </Item>
        </ControlAccessRulesWrapper>

        {field.cultureZones.length < 1 && (
          <Item onClick={handleAddCultureZoneClick}>
            <IconWrapper>
              <AddIcon />
            </IconWrapper>
            <ToolLabel>Добавить культуру</ToolLabel>
          </Item>
        )}

        <Item onClick={handleSeasonHistoryClick}>
          <IconWrapper>
            <HistoryLeafIcon />
          </IconWrapper>
          <ToolLabel>История посевов</ToolLabel>
        </Item>

        <ControlAccessRulesWrapper mode={AccessRuleVisibility.Hide} actionName="field.deleteField">
          <Item
            onClick={e => {
              e.stopPropagation();
              onDeleteClick();
              closeContextMenu();
            }}
          >
            <IconWrapper>
              <BinIcon />
            </IconWrapper>
            <ToolLabel color={Colors.red}>Удалить</ToolLabel>
          </Item>
        </ControlAccessRulesWrapper>
      </ToolContent>
      <Arrow style={{ top: '15px' }} />
    </ToolWrapper>
  );
};
