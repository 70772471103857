import React, { FC } from 'react';

import { IContainerDefaultHeader as IDefaultHeader } from '../../models';
import { useDataTestIdV2 } from '../../../../../../../utils/hooks/locators';
import { ContainerTitle as Title } from '../../../ContainerTitle';

import Styled from './ContainerDefaultHeader.styles';

interface IProps {
  dataTestId: string;
  header: IDefaultHeader;
}

const ContainerDefaultHeader: FC<IProps> = ({ dataTestId, header }) => {
  const getDataTestId = useDataTestIdV2(dataTestId);

  return (
    <Styled.Wrapper {...getDataTestId()}>
      <Styled.TitleWrapper {...getDataTestId('title-wrapper')}>
        <Title title={header.title} />

        {header?.additionalContent ? header.additionalContent : null}
      </Styled.TitleWrapper>
    </Styled.Wrapper>
  );
};

ContainerDefaultHeader.displayName = 'ContainerDefaultHeader';

export default ContainerDefaultHeader;
