import { Colors, ESpaceSize } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const Wrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  padding: '8px 12px',
});

const Text = styled('span')({
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '20px',
  color: Colors.accentGreen,
  marginLeft: ESpaceSize.S,
});

const IconWrapper = styled('div')({
  width: 16,
  height: 16,
  borderRadius: '50%',
  backgroundColor: Colors.lightGreen,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export default {
  Wrapper,
  Text,
  IconWrapper,
};
