import { cloneDeep } from 'lodash';

import {
  lazyInject,
  provide,
} from '../../../../../../../../../../../../../../../../../../shared/utils/IoC';
import { StoAttributeCoreStore as Store } from '../../stores';
import {
  StoChecklistsTableChangesService as ChecklistsTableChangesService,
  StoChecklistsTableConfigsService as ChecklistsTableConfigsService,
} from '../../../../../../../mobx/services';
import { IStoParams } from '../../../../../../../../../../../../../../../models';
import {
  IStoAttribute,
  IStoAttributeOdzDependencyPut,
  IStoChecklistsAttrsTableAttrUpdate,
} from '../../../../../../../../../../../../../../../../../../../api/models/as-fields/new/sto';
import { ComparisonTableBuilderController as ComparisonTableController } from '../../../../../../../../../../../../../../../../../../shared/features/ComparisonTableBuilder/mobx/controllers';
import { StoChecklistsCoreStore as ChecklistsStore } from '../../../../../../../mobx/stores';
import { EStoChecklistsTableColumnName as ETableColumnName } from '../../../../../../../constants';
import { IComparisonTableBuilderCellConfig } from '../../../../../../../../../../../../../../../../../../shared/features/ComparisonTableBuilder/models/configs';
import { StoAttributeUserDictionaryService as UserDictionaryService } from '../StoAttributeUserDictionaryService';
import { IStoChecklistsAttrsTableUserDictionaryValueUpdate as IUserDictionaryValueUpdate } from '../../../../../../../../../../../../../../../../../../../api/models/as-fields/new/sto/StoChecklistsParamsTable/StoTable.model';
import StoAttributeOdzService from '../StoAttributeOdzService/StoAttributeOdz.service';
import { StoAttributeRequestsService as RequestsService } from '../StoAttributeRequestsService';
import { StoChecklistsTableHelpers as TableHelpers } from '../../../../../../../helpers';
import { EStoOdzCommonError } from '../../../constants';
import { EChecklistAttributeType } from '../../../../../../../../../../../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';

type TAttributeGetterProps = Pick<IStoParams, 'checklistId' | 'stageId' | 'attributeId'>;

const checkIfIsEmpty = (value: string | number): boolean => {
  if (value === '') return true;
  if (value === null) return true;

  return false;
};

@provide.transient()
class StoAttributeChangesService {
  @lazyInject(Store)
  protected store: Store;

  @lazyInject(ChecklistsStore)
  protected checklistsStore: ChecklistsStore;

  @lazyInject(ChecklistsTableChangesService)
  protected checklistsTableChangesService: ChecklistsTableChangesService;

  @lazyInject(ChecklistsTableConfigsService)
  protected checklistsTableConfigsService: ChecklistsTableConfigsService;

  @lazyInject(ComparisonTableController)
  protected comparisonTableController: ComparisonTableController;

  @lazyInject(UserDictionaryService)
  protected userDictionaryService: UserDictionaryService;

  @lazyInject(StoAttributeOdzService)
  protected odzService: StoAttributeOdzService;

  @lazyInject(RequestsService)
  protected requestsService: RequestsService;

  public initiateAttribute = ({
    checklistId,
    stageId,
    attributeId,
  }: Pick<IStoParams, 'checklistId' | 'stageId' | 'attributeId'>): void => {
    const attributeUpdate = this.checklistsStore.getAttrUpdate(checklistId, stageId, attributeId);
    this.store.setPrevAttributeUpdate(cloneDeep(attributeUpdate));

    /**
     * Если мы этот атрибут уже редактировали в рамках данной сессии,
     * то не перезаписываем хранящиеся данные для отправки на бэк.
     */
    if (this.store.hasAlreadyChangedAttrId(attributeId)) return;

    this.checklistsTableChangesService.changeAttr(
      checklistId,
      stageId,
      {
        id: attributeId,
        isRequired: this.store.attribute.isRequired,
        isLinkedPhotoAvailable: this.store.attribute.isLinkedPhotoAvailable,
        isLinkedPhotoEnabled: this.store.attribute.isLinkedPhotoEnabled,
        isLinkedPhotoRequired: this.store.attribute.isLinkedPhotoRequired,
        isToolTipEnabled: this.store.attribute.isToolTipEnabled,
        toolTip: this.store.attribute.toolTip,
        userDictionaryValues: this.userDictionaryService.initiateUserDictionaryValues(),
        stoODZFormType: this.odzService.initiateOdz(),
      },
      { isWithoutChangeLogging: true }
    );

    this.store.setAlreadyChangedAttrId(attributeId);
  };

  public changeAttribute = (
    params: TAttributeGetterProps,
    data: Partial<IStoChecklistsAttrsTableAttrUpdate>
  ): void => {
    this.store.setHasErrors(false);

    this.checklistsTableChangesService.changeAttr(params.checklistId, params.stageId, {
      id: params.attributeId,
      ...data,
    });

    const changedPropList = Object.keys(data) as (keyof IStoAttribute)[];
    const formattedPropList = changedPropList.filter(prop => prop !== 'id');

    formattedPropList.forEach(prop => this.store.setChangedAttrProp(prop));

    this.store.setHasChanges(true);
  };

  public addNewUserDictionaryValue = (
    params: TAttributeGetterProps,
    value: IUserDictionaryValueUpdate
  ): void => {
    const attributeUpdate = this.checklistsStore.getAttrUpdate(
      params.checklistId,
      params.stageId,
      params.attributeId
    );

    const updatedValueList: IUserDictionaryValueUpdate[] = [
      ...attributeUpdate.userDictionaryValues,
      value,
    ];

    this.userDictionaryService.addNewValue(value);

    this.changeAttribute(params, {
      userDictionaryValues: updatedValueList,
    });
  };

  public toggleSelectionUserDictionaryValue = (
    params: TAttributeGetterProps,
    value: IUserDictionaryValueUpdate,
    isSelected: boolean
  ): void => {
    const attributeUpdate = this.checklistsStore.getAttrUpdate(
      params.checklistId,
      params.stageId,
      params.attributeId
    );

    let updatedValueList: IUserDictionaryValueUpdate[];

    if (isSelected) {
      updatedValueList = [...attributeUpdate.userDictionaryValues, value];
    } else {
      updatedValueList = attributeUpdate.userDictionaryValues.filter(el => el.id !== value.id);
    }

    this.userDictionaryService.toggleSelection(value.id, isSelected);

    this.changeAttribute(params, {
      userDictionaryValues: updatedValueList,
    });
  };

  public removeUserDictionaryValue = (
    params: TAttributeGetterProps,
    valueUpdate: IUserDictionaryValueUpdate
  ): void => {
    const attributeUpdate = this.checklistsStore.getAttrUpdate(
      params.checklistId,
      params.stageId,
      params.attributeId
    );

    this.userDictionaryService.removeItem(valueUpdate.id);

    this.changeAttribute(params, {
      userDictionaryValues: attributeUpdate.userDictionaryValues.filter(
        ({ id }) => id !== valueUpdate.id
      ),
    });
  };

  public editUserDictionaryValue = (
    params: TAttributeGetterProps,
    valueUpdate: IUserDictionaryValueUpdate
  ): void => {
    const attributeUpdate = this.checklistsStore.getAttrUpdate(
      params.checklistId,
      params.stageId,
      params.attributeId
    );

    this.userDictionaryService.editValue(valueUpdate.id, valueUpdate.value);

    this.changeAttribute(params, {
      userDictionaryValues: attributeUpdate.userDictionaryValues.map(value => {
        if (value.id === valueUpdate.id) {
          return {
            ...value,
            value: valueUpdate.value,
          };
        } else {
          return value;
        }
      }),
    });
  };

  public undoTheChanges = ({ checklistId, stageId, attributeId }: TAttributeGetterProps): void => {
    /**
     * Если это не первый переход на данную страницу (редактирование этого же атрибута)
     * и это оставление страницы без сохранений, то сбрасываем данные и проверяем,
     * было ли вообще что-либо добавлено в рамках этой сессии при работе с чек-листами,
     * и если ничего не было добавлено, то удаляем идентификатор атрибута из коллекции
     * ранее измененных атрибутов, иначе полученные данные с бэка не будут отображаться
     * на странице.
     */
    const isWithoutPrevChanges = Object.keys(this.store.prevAttributeUpdate).length === 3;

    this.checklistsStore.setAttrUpdate(checklistId, stageId, this.store.prevAttributeUpdate);

    if (!isWithoutPrevChanges) return;

    this.store.deleteAlreadyChangedAttrId(attributeId);
  };

  public checkIfHasErrors = (params: TAttributeGetterProps): boolean => {
    const attributeUpdate = this.checklistsStore.getAttrUpdate(
      params.checklistId,
      params.stageId,
      params.attributeId
    );

    if (attributeUpdate.isToolTipEnabled && !attributeUpdate.toolTip) {
      this.store.setHasErrors(true);

      return true;
    } else {
      return false;
    }
  };

  public checkIfHasOdzErrors = (params: TAttributeGetterProps): boolean => {
    if (!this.odzService.isNeedOdz) return false;

    const attributeUpdate = this.checklistsStore.getAttrUpdate(
      params.checklistId,
      params.stageId,
      params.attributeId
    );

    if (!attributeUpdate.stoODZFormType.izODZEnabled) return false;

    const hasSomeGroups: boolean = attributeUpdate.stoODZFormType.odzGroups.length > 1;

    let isEmpty = false;

    attributeUpdate.stoODZFormType.odzGroups.forEach(group => {
      const checkIfDictionaryIsEmpty = (): boolean => {
        if (!this.odzService.isDictionaryOdz) return false;

        return (
          !group.odzCriteria.dictionaryValues.red.length &&
          !group.odzCriteria.dictionaryValues.yellow.length &&
          !group.odzCriteria.dictionaryValues.green.length
        );
      };

      const checkIfNumericIsEmpty = (): boolean => {
        if (!this.odzService.isNumericOdz) return false;

        return (
          checkIfIsEmpty(group.odzCriteria.numericValues.redLow) &&
          checkIfIsEmpty(group.odzCriteria.numericValues.redHigh) &&
          checkIfIsEmpty(group.odzCriteria.numericValues.yellowHigh) &&
          checkIfIsEmpty(group.odzCriteria.numericValues.yellowLow)
        );
      };

      const isOdzEmpty = checkIfDictionaryIsEmpty() || checkIfNumericIsEmpty();

      if (!isOdzEmpty && !attributeUpdate.stoODZFormType.isComplexODZ) {
        return;
      }

      if (attributeUpdate.stoODZFormType.isComplexODZ) {
        const isDepEmpty = this.checkIfHasDepAttrsErrors(group.id, group.dependencyAttributes);
        if (isDepEmpty) isEmpty = true;
      }

      if (!isOdzEmpty && attributeUpdate.stoODZFormType.isComplexODZ) {
        return;
      }

      if (!attributeUpdate.stoODZFormType.isComplexODZ) {
        this.store.setOdzError(group.id, EStoOdzCommonError.Empty);
        isEmpty = true;
        return;
      }

      if (hasSomeGroups) this.store.setOdzError(group.id, EStoOdzCommonError.GroupEmpty);
      else this.store.setOdzError(group.id, EStoOdzCommonError.Empty);

      isEmpty = true;
    });

    return isEmpty;
  };

  protected checkIfHasDepAttrsErrors = (
    groupId: string,
    attrList: IStoAttributeOdzDependencyPut[]
  ): boolean => {
    let isEmpty = false;

    attrList.forEach(attr => {
      switch (attr.type) {
        case EChecklistAttributeType.DictionaryLink: {
          if (!attr.dictionaryValues.length) {
            isEmpty = true;
            this.store.setOdzDepError(groupId, attr.id, EStoOdzCommonError.DependencyEmpty);
          }
          break;
        }

        case EChecklistAttributeType.Enum: {
          if (!attr.dictionaryValues.length) {
            isEmpty = true;
            this.store.setOdzDepError(groupId, attr.id, EStoOdzCommonError.DependencyEmpty);
          }
          break;
        }

        case EChecklistAttributeType.Int: {
          const isNumericEmpty: boolean =
            checkIfIsEmpty(attr.numericValues.from) && checkIfIsEmpty(attr.numericValues.to);

          if (isNumericEmpty) {
            isEmpty = true;
            this.store.setOdzDepError(groupId, attr.id, EStoOdzCommonError.DependencyEmpty);
          }
          break;
        }

        case EChecklistAttributeType.Double: {
          const isNumericEmpty: boolean =
            checkIfIsEmpty(attr.numericValues.from) && checkIfIsEmpty(attr.numericValues.to);

          if (isNumericEmpty) {
            isEmpty = true;
            this.store.setOdzDepError(groupId, attr.id, EStoOdzCommonError.DependencyEmpty);
          }
          break;
        }

        default:
      }
    });

    return isEmpty;
  };

  public checkIfOdzGroupsIsEmpty = (params: TAttributeGetterProps): boolean => {
    const attributeUpdate = this.checklistsStore.getAttrUpdate(
      params.checklistId,
      params.stageId,
      params.attributeId
    );

    if (!attributeUpdate.stoODZFormType.izODZEnabled) return false;

    const isEmpty = attributeUpdate.stoODZFormType.odzGroups.some(group => {
      if (this.odzService.isDictionaryOdz) {
        return (
          !group.odzCriteria.dictionaryValues.red.length &&
          !group.odzCriteria.dictionaryValues.yellow.length &&
          !group.odzCriteria.dictionaryValues.green.length
        );
      }

      if (this.odzService.isNumericOdz) {
        return (
          checkIfIsEmpty(group.odzCriteria.numericValues.redLow) &&
          checkIfIsEmpty(group.odzCriteria.numericValues.redHigh) &&
          checkIfIsEmpty(group.odzCriteria.numericValues.yellowHigh) &&
          checkIfIsEmpty(group.odzCriteria.numericValues.yellowLow)
        );
      }

      return false;
    });

    return isEmpty;
  };

  public checkIfHasNoSelectedDependency = (params: TAttributeGetterProps): boolean => {
    const attributeUpdate = this.checklistsStore.getAttrUpdate(
      params.checklistId,
      params.stageId,
      params.attributeId
    );

    const hasNoSelectedDependency =
      attributeUpdate.stoODZFormType.izODZEnabled &&
      attributeUpdate.stoODZFormType.isComplexODZ &&
      !attributeUpdate.stoODZFormType.odzGroups.length;

    if (hasNoSelectedDependency) {
      this.store.setHasNoSelectedDependency(true);

      return true;
    } else {
      return false;
    }
  };

  public updateCellsInTheTable = (ids: TAttributeGetterProps): void => {
    const cellList = this.createCellList(ids);

    cellList.forEach(cell => {
      this.comparisonTableController.updateCell(
        ids.checklistId,
        TableHelpers.createRowId(ids.stageId, ids.attributeId),
        cell.columnId,
        cell
      );
    });
  };

  protected createCellList = (
    props: TAttributeGetterProps
  ): IComparisonTableBuilderCellConfig[] => {
    return this.store.changedAttrPropList.reduce<IComparisonTableBuilderCellConfig[]>(
      (list, prop) => {
        switch (prop) {
          case 'isRequired': {
            list.push(this.createIsRequiredCellConfig(props));
            break;
          }

          case 'isLinkedPhotoEnabled': {
            list.push(this.createPhotosCellConfig(props));
            break;
          }

          case 'isToolTipEnabled': {
            list.push(this.createToolTipCellConfig(props));
            break;
          }

          case 'stoODZFormType':
            list.push(this.createOdzCellConfig(props));
            break;

          default:
        }

        return list;
      },
      []
    );
  };

  protected createIsRequiredCellConfig = ({
    checklistId,
    stageId,
    attributeId,
  }: TAttributeGetterProps): IComparisonTableBuilderCellConfig => {
    const { isRequired } = this.checklistsStore.getAttrUpdate(checklistId, stageId, attributeId);

    return this.checklistsTableConfigsService.createBooleanCellConfigForUpdate({
      stageId,
      attributeId,
      columnId: ETableColumnName.Required,
      value: {
        booleanValue: isRequired,
      },
    });
  };

  protected createPhotosCellConfig = ({
    checklistId,
    stageId,
    attributeId,
  }: TAttributeGetterProps): IComparisonTableBuilderCellConfig => {
    const { isLinkedPhotoEnabled } = this.checklistsStore.getAttrUpdate(
      checklistId,
      stageId,
      attributeId
    );

    return this.checklistsTableConfigsService.createBooleanCellConfigForUpdate({
      stageId,
      attributeId,
      columnId: ETableColumnName.Photos,
      value: {
        booleanValue: isLinkedPhotoEnabled,
      },
    });
  };

  protected createToolTipCellConfig = ({
    checklistId,
    stageId,
    attributeId,
  }: TAttributeGetterProps): IComparisonTableBuilderCellConfig => {
    const { isToolTipEnabled } = this.checklistsStore.getAttrUpdate(
      checklistId,
      stageId,
      attributeId
    );

    return this.checklistsTableConfigsService.createBooleanCellConfigForUpdate({
      stageId,
      attributeId,
      columnId: ETableColumnName.Help,
      value: {
        booleanValue: isToolTipEnabled,
      },
    });
  };

  protected createOdzCellConfig = ({
    checklistId,
    stageId,
    attributeId,
  }: TAttributeGetterProps): IComparisonTableBuilderCellConfig => {
    const { stoODZFormType } = this.checklistsStore.getAttrUpdate(
      checklistId,
      stageId,
      attributeId
    );

    return this.checklistsTableConfigsService.createBooleanCellConfigForUpdate({
      stageId,
      attributeId,
      columnId: ETableColumnName.Odz,
      value: {
        booleanValue: stoODZFormType.izODZEnabled,
      },
    });
  };
}

export default StoAttributeChangesService;
