import { Geometry } from '@turf/turf';
import { PolylineOptions } from 'leaflet';
import { cloneDeep } from 'lodash';

import { CultureZone } from '../../../../../../../../../../api/models/field.model';
import { BaseCulturePolygon } from '../../../../../../../../../shared/features/map/utils/MapElements/polygons';

/**
 * КЗ-полигон для {@link CulturesLayerGroup}.
 * Наследуется от базового КЗ-полигона
 */
class CulturePolygon extends BaseCulturePolygon {
  private _optionsToReset: PolylineOptions;

  private _lastValidGeometry: Geometry;

  private _isSelected = false;

  constructor(geometry: Geometry, dataModel: CultureZone, isVisible = true) {
    super(geometry, dataModel);

    if (!isVisible) {
      this.setTransparentStyle();
    }

    this._lastValidGeometry = this.getInfo().geometry;
  }

  public getLastValidGeometry() {
    return this._lastValidGeometry;
  }

  public get isSelected() {
    return this._isSelected;
  }

  /**
   * Сохраняет текущие опции полигона и устанавливает прозрачную заливку
   */
  public setTransparentStyle(): void {
    this._optionsToReset = cloneDeep(this.options);

    this.setStyle({
      fillColor: 'transparent',
    });

    this._prevOptions = this.options;
  }

  /**
   * Восстанавливает сохраненные опции
   */
  public restoreSavedOptions(): void {
    if (this._optionsToReset) {
      this._optionsToReset = {
        ...this._optionsToReset,
        color: this.isSelected ? '#780BBB' : '#FD7E09',
      };

      this.setStyle(this._optionsToReset);
      this._prevOptions = this._optionsToReset;
    }
  }

  public toggleHighlight(enable: boolean) {
    const color = enable ? '#780BBB' : '#FD7E09';
    const classListFn = enable ? 'add' : 'remove';

    this.setStyle({ color });
    this.getElement()?.classList[classListFn]('animated-select');

    this._prevOptions.color = color;
    this.updateGeomanCachedColor(color);

    this._isSelected = enable;
  }

  /**
   * Обновляет последнюю валидную геометрию
   */
  public updateValidGeometry(geometry: Geometry) {
    this._lastValidGeometry = geometry;
  }
}

export default CulturePolygon;
