import React, { FC } from 'react';

import { IContainerClearedHeader as IClearedHeader } from '../../models';
import { useDataTestIdV2 } from '../../../../../../../utils/hooks/locators';
import { ContainerTitle as Title } from '../../../ContainerTitle';

import Styled from './ContainerClearedHeader.styles';

interface IProps {
  dataTestId: string;
  header: IClearedHeader;
}

const ContainerClearedHeader: FC<IProps> = ({ dataTestId, header }) => {
  const getDataTestId = useDataTestIdV2(dataTestId);

  return (
    <Styled.Wrapper {...getDataTestId()}>
      <Styled.TitleWrapper {...getDataTestId('title-wrapper')}>
        <Title title={header.title} />

        {header?.additionalContent ? header.additionalContent : null}
      </Styled.TitleWrapper>
    </Styled.Wrapper>
  );
};

ContainerClearedHeader.displayName = 'ContainerClearedHeader';

export default ContainerClearedHeader;
