import { Field } from '../../../../../../../../api/models/field.model';
import { IMapPolygonError } from '../../../../../../../shared/features/map/models';
import {
  MapDrawerController,
  MapPolygonController,
} from '../../../../../../../shared/features/map/modules';
import { MapEventBus } from '../../../../../../../shared/features/map/modules/MapCore';
import {
  BasePolygon,
  FieldLayerGroup,
} from '../../../../../../../shared/features/map/utils/MapElements';
import { PolygonValidator } from '../../../../../../../shared/features/map/utils/validators';
import { lazyInject, provide } from '../../../../../../../shared/utils/IoC';
import { EFieldFill } from '../../../../../../constants/FieldFill.enum';
import { EFieldTooltip } from '../../../../../../constants/FieldTooltip.enum';
import { ECurrDrawPolygonStatus } from '../../types';
import drawOptions from '../../utils/constants/DrawPolygonOptions.constant';
import { FIELD_POLYGON_OPTIONS, EFieldsMode } from '../../../../utils';
import FieldsMapCoreController from '../../../../mobx/controllers/FieldsMapCoreController/FieldsMapCoreController';
import Service from '../services/FieldsCreate.service';
import Store from '../stores/FieldsCreate.store';

@provide.transient()
class FieldsCreateController extends FieldsMapCoreController {
  @lazyInject(Store)
  private store: Store;

  @lazyInject(Service)
  private service: Service;

  @lazyInject(MapDrawerController)
  private mapDrawerController: MapDrawerController;

  @lazyInject(MapPolygonController)
  private mapPolygonController: MapPolygonController;

  public async initialize() {
    this.coreStore.fieldsMode = EFieldsMode.CREATE;

    // Получаем список полей с бэка. Сетаем их в FieldsStore
    const fieldsList = await this.service.fetchFieldsList();

    // Инициализируем карту. Делаем связь полигон - поле
    await this.buildMap(fieldsList, this.getLayerGroupConfig);
    this.registerMapEvents();

    // Убираем заливку культруных зон и тултипы
    this.fieldFillController.setFillStrategy(EFieldFill.None);
    this.fieldTooltipController.setTooltipContent(EFieldTooltip.None);

    // Подготовка карты. Включаем рисование
    this.mapDrawerController.enableDrawPolygon(drawOptions);
  }

  public destroy() {
    super.destroy();

    this.mapDrawerController.resetDrawPolygon(true);

    // удаляет поля из Store
    this.store.clearCreatedFieldsById();

    this.store.drawStatus = ECurrDrawPolygonStatus.FINISHED;
  }

  public changeFieldName(fieldId: number, value: string) {
    const field = this.store.getCreatedFieldById(fieldId);

    this.store.updateFiled({ ...field, name: value });
    field.polygon.setTooltipContent(value);
  }

  public submitFields() {
    const { createdFieldsList } = this.store;

    return this.service.submitFields(createdFieldsList).then(createdFieldsIds => {
      this.coreStore.fieldToCenter = createdFieldsIds?.[0];
    });
  }

  public deleteCreatedField(fieldId: number): void {
    const field = this.store.getCreatedFieldById(fieldId);

    this.mapPolygonController.remove(field?.polygon.id);
    this.store.deleteCreatedFieldById(fieldId);

    this.store.updateErrors();
  }

  // Расширяет базовый метод
  protected getLayerGroupConfig(field: Field) {
    const baseConfig = super.getLayerGroupConfig(field);

    baseConfig.layerGroup = new FieldLayerGroup(field, {
      optimization: { isClusterable: false, isRenderViewport: true },
      fieldOptions: FIELD_POLYGON_OPTIONS.creating,
    });

    return baseConfig;
  }

  protected registerMapEvents() {
    const listener = MapEventBus.on('draw.polygon.create', this.handleCreatePolygon);
    const listener1 = MapEventBus.on('draw.polygon.edit', this.handleEditPolygon);
    const listener2 = MapEventBus.on('draw.polygon.vertex.add', this.handleAddVertex);

    this.coreStore.setMapEventListeners([listener, listener1, listener2]);
  }

  private handleCreatePolygon = (polygon: BasePolygon) => {
    const newField = this.store.setCreatedField(polygon);

    polygon.setTooltipContent(newField.name);
    this.validatePolygon(polygon);
  };

  public handleEditPolygon = (polygon: BasePolygon): IMapPolygonError[] => {
    const creatableField = this.store.getCreatedFieldById(polygon.id);

    if (!creatableField) {
      return;
    }

    creatableField.areaInHectare = polygon.getInfo().area;
    polygon.rerenderTooltip();

    return this.validatePolygon(polygon);
  };

  private handleAddVertex = (marker: L.Marker) => {
    if (!marker.options.draggable) {
      this.store.drawStatus = ECurrDrawPolygonStatus.UNFINISHED;
    }
  };

  private validatePolygon(polygon: BasePolygon): IMapPolygonError[] {
    const allPolygons = [...this.fieldsPolygonsList, ...this.store.createdFieldsPolygonsList];

    const validator = new PolygonValidator(polygon)
      .checkIntersections(allPolygons)
      .checkIsAreaTooBig();

    this.store.updateErrors();

    return validator.errors.list;
  }
}

export default FieldsCreateController;
