import { ReactComponent as NoSeasonsIconSvg } from '../../../../assets/map-add-entity-mock.svg';
import Styled from '../../ListWrapper.styles';

const NoSeasonsPlaceholder = () => {
  return (
    <Styled.PlaceholderWrapper data-test-id={'fields-list-no-seasons'}>
      <NoSeasonsIconSvg />
      <Styled.PlaceholderTitle>У вас ещё нет сезонов</Styled.PlaceholderTitle>

      <Styled.PlaceholderDescription>
        Добавьте сезон, чтобы получить возможность добавлять поля
      </Styled.PlaceholderDescription>
    </Styled.PlaceholderWrapper>
  );
};

export default NoSeasonsPlaceholder;
