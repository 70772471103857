import { Colors } from '@farmlink/farmik-ui';

type TFieldStyleType =
  | 'display'
  | 'displayTransparent'
  | 'creating'
  | 'edit'
  | 'import'
  | 'error'
  | 'selected';

type TProps = {
  [key in TFieldStyleType]: L.PolylineOptions;
};

const FIELD_POLYGON_OPTIONS: TProps = {
  display: {
    fillColor: '#FFF9E8',
    fillOpacity: 0.2,
    color: Colors.addSunny,
  },
  displayTransparent: {
    fillColor: '#FFF9E8',
    fillOpacity: 0,
    color: Colors.addSunny,
  },
  creating: {
    fillColor: '#FD7E09',
    fillOpacity: 0.1,
    color: '#FD7E09',

    // Включаем geoman для всех полигонов в режиме создания. (Нужно для того чтобы работала функция "snappable")
    pmIgnore: false,
  },
  edit: {
    fillColor: '#FD7E09',
    fillOpacity: 0.1,
    color: '#FD7E09',

    // Включаем geoman для всех полигонов в режиме редактирования. (Нужно для того чтобы работала функция "snappable")
    pmIgnore: false,
  },
  import: {
    fillColor: Colors.hoverGray,
    fillOpacity: 0.25,
    color: Colors.hoverGray,
    // colorOpacity: 0.7,
  },
  error: {
    // fillColor: '#FFF9E8',
    color: Colors.red,
  },
  selected: {
    fillColor: 'transparent',
    fillOpacity: 0,
    color: Colors.generalWhite,
  },
};

const CULTURE_POLYGON_OPTIONS = {
  cultureZone: (fill: string) => {
    if (!fill) {
      return {
        fill: `url(https://thumbs.dreamstime.com/b/imitation-transparent-background-seamless-vector-illustration-69028332.jpg)` as any,
        fillOpacity: 0.5,
        color: '#FD7E09',
      };
    }

    return {
      fill: true,
      fillColor: fill,
      fillOpacity: 1,
      color: '#FD7E09',
    };
  },
};

export { FIELD_POLYGON_OPTIONS, CULTURE_POLYGON_OPTIONS };
