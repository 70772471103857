import styled, { css, keyframes } from 'styled-components';

import { EStoStatus } from '../../../../../../../../api/models/as-fields/STO/STO.model';

import downloadIconDefault from './assets/icons/downloadIconDefault.svg';
import downloadIconHover from './assets/icons/downloadIconHover.svg';
import spinnerIcon from './assets/icons/spinner.svg';

const CultureIcon = styled.img`
  width: 40px;
  height: 40px;

  border-radius: 50%;
`;

const Name = styled.div`
  width: 100%;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  color: #151f32;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
  letter-spacing: -0.15px;
`;

const Header = styled.div`
  width: 100%;

  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;

  gap: 8px;
`;

const FieldsDescription = styled.div`
  color: #7e8b9d;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.1px;
`;

const CultureName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  color: #151f32;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.1px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const rotateAnimation = keyframes`
  100% {
    transform:rotate(360deg); 
  }
`;

const LoadingIcon = styled.div`
  width: 24px;
  height: 24px;

  background: url(${spinnerIcon});

  animation: ${rotateAnimation} 1s linear infinite;
`;

const DownloadIcon = styled.div`
  transition: all 0.15s ease-in-out;

  width: 24px;
  height: 24px;

  cursor: pointer;

  background: url(${downloadIconDefault}) no-repeat center;

  &:hover {
    background: url(${downloadIconHover});
  }
`;

const getStatusColors = (status: EStoStatus) => {
  switch (status) {
    case EStoStatus.Draft:
      return css`
        color: #7e8b9d;
        background-color: #f6f7f8;
      `;

    case EStoStatus.Active:
      return css`
        color: #00945d;
        background-color: #ecf8ef;
      `;

    case EStoStatus.Modified:
      return css`
        color: #ef6517;
        background-color: #fff5e4;
      `;

    case EStoStatus.Inactive:
      return css`
        color: #df2e22;
        background-color: #fef5f5;
      `;

    default:
  }
};

const Status = styled.div<{ $status: EStoStatus }>`
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.1px;

  border-radius: 8px;
  padding: 2px 8px;

  ${({ $status }) => getStatusColors($status)};
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;

  gap: 8px;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 168px;

  display: flex;
  flex-direction: column;
  gap: 16px;

  border-radius: 16px;
  padding: 16px;

  cursor: pointer;
  background-color: white;

  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

  &:hover {
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.1), 0 6px 8px 0 rgba(0, 0, 0, 0.05),
      0 0 1px 0 rgba(0, 0, 0, 0.15);
  }

  transition: 0.3s ease-in-out;
`;

const StyledStoCard = {
  CultureIcon,
  Name,
  Header,
  FieldsDescription,
  CultureName,
  Content,
  LoadingIcon,
  DownloadIcon,
  Status,
  Footer,
  Wrapper,
};

export default StyledStoCard;
