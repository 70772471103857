const STO_NOTIFICATION_MESSAGES = {
  createStoSuccess: 'Черновик СТО создан',
  createStoError: 'Ошибка создания СТО',

  updateStoSuccess: 'Черновик СТО обновлён',
  updateStoError: 'Ошибка обновления СТО',

  publishStoSuccess: 'СТО успешно опубликован',
  publishStoError: 'Ошибка публикации СТО',

  activateStoSuccess: 'СТО успешно активирован',
  activateStoError: 'Ошибка активации СТО',

  deactivateStoSuccess: 'Сто успешно деактивирован',
  deactivateStoError: 'Ошибка деактивации СТО',

  discardStoSuccess: 'Ранее внесённые изменения удалены',
  discardStoError: 'Ошибка удаления изменений',

  deleteStoSuccess: 'Черновик СТО удалён',
  deleteStoError: 'Ошибка удаления черновика СТО',

  transferErrorIsLastField: 'Невозможно перенести последнее поле из активного СТО',
  transferErrorStoIsDraft: 'СТО имеет неопубликованные изменения',
};

export default STO_NOTIFICATION_MESSAGES;
