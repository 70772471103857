import { IndexModel } from '../../../../../../../../../../../../api/models/indices.model';
import { Axios } from '../../../../../../../../../../../shared/utils/axios2';
import { lazyInject, provide } from '../../../../../../../../../../../shared/utils/IoC';
import { SeasonsStore } from '../../../../../../../../../../stores/seasons.store';
import { TFetchArgs } from '../../models/FetchArgs/FetchArgs.model';
import IndicesStore from '../stores/Indices.store';

@provide.transient()
class IndicesService {
  @lazyInject(Axios)
  private axios: Axios;

  @lazyInject(IndicesStore)
  private indicesStore: IndicesStore;

  @lazyInject(SeasonsStore)
  private seasonsStore: SeasonsStore;

  public fetchIndices(partialArgs: TFetchArgs): Promise<IndexModel[]> {
    this.indicesStore.setLoading(true);

    const response = this.axios.api.getIndicesList({
      fieldId: partialArgs.fieldId,
      indexName: partialArgs.indexName,
      year: Number(this.seasonsStore.selectedSeason),
    });

    return response
      .then(({ content }) => content)
      .catch(() => [])
      .finally(() => {
        this.indicesStore.setLoading(false);
      });
  }
}

export default IndicesService;
