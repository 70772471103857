import MarkerSvg from '../../../../../../../shared/static/map.mark.svg';
import Styled from '../../ListWrapper.styles';

const NoItemsPlaceholder = () => {
  return (
    <Styled.PlaceholderWrapper data-test-id={'fields-list-no-fields'}>
      <Styled.PlaceholderImage src={MarkerSvg} />
      <Styled.PlaceholderTitle>У вас ещё нет полей</Styled.PlaceholderTitle>

      <Styled.PlaceholderDescription>
        Добавьте поля, чтобы получить доступ к снимкам вегетации и данным погоды
      </Styled.PlaceholderDescription>
    </Styled.PlaceholderWrapper>
  );
};

export default NoItemsPlaceholder;
