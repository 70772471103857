import {
  lazyInject,
  provide,
} from '../../../../../../../../../../../../../../../../../../shared/utils/IoC';
import { StoAttributeRequestsService as RequestsService } from '../StoAttributeRequestsService';
import { StoAttributeCoreStore as Store } from '../../stores';
import { StoAttributeChangesService as ChangesService } from '../StoAttributeChangesService';
import { StoAttributeUserDictionaryService as UserDictionaryService } from '../StoAttributeUserDictionaryService';
import { StoAttributeOdzService as OdzService } from '../StoAttributeOdzService';
import { IStoParams } from '../../../../../../../../../../../../../../../models';
import {
  IStoAttribute as IAttribute,
  IStoAttributeOdzCriteriaPut as IOdzCriteriaPut,
  IStoAttributeOdzDependencyPut as IDependencyPut,
  IStoAttributeOdzDictionaryValue,
  IStoChecklistsAttrsTableAttrUpdate,
} from '../../../../../../../../../../../../../../../../../../../api/models/as-fields/new/sto';
import { EChecklistAttributeType } from '../../../../../../../../../../../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';

@provide.transient()
class StoAttributeCoreService {
  @lazyInject(Store)
  protected store: Store;

  @lazyInject(RequestsService)
  protected requestsService: RequestsService;

  @lazyInject(ChangesService)
  protected changesService: ChangesService;

  @lazyInject(UserDictionaryService)
  protected userDictionaryService: UserDictionaryService;

  @lazyInject(OdzService)
  protected odzService: OdzService;

  public get isNumericOdz() {
    return this.odzService.isNumericOdz;
  }
  public get isDictionaryOdz() {
    return this.odzService.isDictionaryOdz;
  }

  public get isNeedOdz() {
    return this.odzService.isNeedOdz;
  }

  public initiatePage = async (params: IStoParams): Promise<void> => {
    const attribute = await this.requestsService.fetchAttribute(params);

    if (!attribute) return;

    if (attribute?.stoODZFormType?.isComplexODZ) {
      await this.requestsService.fetchDependencyAttributeList(params);
    }

    if (attribute.attribute.type === EChecklistAttributeType.Enum) {
      const optionList = await this.requestsService.getEnumOptionList({
        attributeId: params.attributeId,
      });

      this.store.setDictionaryOptionList(optionList);
    }

    if (attribute.attribute.type === EChecklistAttributeType.DictionaryLink) {
      const optionList = await this.requestsService.getDictionaryOptionList({
        latestVersion: true,
        remoteName: attribute.attribute.dictionaryLink,
        fetchAttributes: true,
        originalOnly: true,
      });

      this.store.setDictionaryOptionList(optionList);
    }

    if (attribute.attribute.type === EChecklistAttributeType.UserDictionaryLink) {
      await this.userDictionaryService.initiateUserDictionaryRegistry({
        organizationId: params.orgId,
        checklistId: params.checklistId,
        stageId: params.stageId,
        attributeId: params.attributeId,
      });
    }

    this.changesService.initiateAttribute(params);

    this.store.setIsLoading(false);
  };

  public addDepEnumOptionList = async (depAttrId: string): Promise<void> => {
    const optionList = await this.requestsService.getEnumOptionList({ attributeId: depAttrId });
    this.store.setDepAttrOptionList(depAttrId, optionList);
  };

  public addDepDictionaryOptionList = async (
    depAttrId: string,
    dictionaryLink: string
  ): Promise<void> => {
    const optionList = await this.requestsService.getDictionaryOptionList({
      latestVersion: true,
      remoteName: dictionaryLink,
      fetchAttributes: true,
      originalOnly: true,
    });

    this.store.setDepAttrOptionList(depAttrId, optionList);
  };

  public checkIfOdzGroupsIsEmpty: ChangesService['checkIfOdzGroupsIsEmpty'] = params => {
    return this.changesService.checkIfOdzGroupsIsEmpty(params);
  };

  public enableOdz = (
    params: Pick<IStoParams, 'checklistId' | 'stageId' | 'attributeId'>
  ): void => {
    const stoODZFormType = this.odzService.enableOdz(params);

    this.changesService.changeAttribute(params, { stoODZFormType });
  };

  public disableOdz = (
    params: Pick<IStoParams, 'checklistId' | 'stageId' | 'attributeId'>
  ): void => {
    const stoODZFormType = this.odzService.disableOdz(params);

    this.changesService.changeAttribute(params, { stoODZFormType });
  };

  public enableOdzDependency = async (params: IStoParams): Promise<void> => {
    await this.requestsService.fetchDependencyAttributeList(params);

    const stoODZFormType = this.odzService.enableOdzDependency();

    this.changesService.changeAttribute(params, { stoODZFormType });
  };

  public disableOdzDependency = (params: IStoParams): void => {
    const stoODZFormType = this.odzService.disableOdzDependency(params);

    this.changesService.changeAttribute(params, { stoODZFormType });
  };

  public changeOdzDependency = (
    depAttribute: IAttribute | null,
    params: Pick<IStoParams, 'checklistId' | 'stageId' | 'attributeId'>
  ): void => {
    const stoODZFormType = this.odzService.changeDependency(depAttribute, params);

    this.changesService.changeAttribute(params, { stoODZFormType });
  };

  public addOdzGroup = (
    params: Pick<IStoParams, 'checklistId' | 'stageId' | 'attributeId'>
  ): void => {
    const stoODZFormType = this.odzService.addGroup(params);

    this.changesService.changeAttribute(params, { stoODZFormType });
  };

  public removeOdzGroup = (
    groupId: string,
    params: Pick<IStoParams, 'checklistId' | 'stageId' | 'attributeId'>
  ): void => {
    const stoODZFormType = this.odzService.removeGroup(groupId, params);

    this.changesService.changeAttribute(params, { stoODZFormType });
  };

  public changeOdzNumericValue = (
    params: Pick<IStoParams, 'checklistId' | 'stageId' | 'attributeId'>,
    groupId: string,
    value: Partial<IOdzCriteriaPut['numericValues']>
  ): void => {
    const stoODZFormType = this.odzService.changeNumericValue(params, groupId, value);

    this.changesService.changeAttribute(params, { stoODZFormType });
  };

  public changeOdzDictionaryValue = (
    params: Pick<IStoParams, 'checklistId' | 'stageId' | 'attributeId'>,
    groupId: string,
    value: Partial<IOdzCriteriaPut['dictionaryValues']>
  ): void => {
    const stoODZFormType = this.odzService.changeDictionaryValue(params, groupId, value);

    this.changesService.changeAttribute(params, { stoODZFormType });
  };

  public changeOdzDependencyNumericValue = (
    params: Pick<IStoParams, 'checklistId' | 'stageId' | 'attributeId'>,
    groupId: string,
    dependencyId: string,
    value: Partial<IDependencyPut['numericValues']>
  ): void => {
    const stoODZFormType = this.odzService.changeDependencyNumericValue(
      params,
      groupId,
      dependencyId,
      value
    );

    this.changesService.changeAttribute(params, { stoODZFormType });
  };

  public changeOdzDependencyDictionaryValue = (
    params: Pick<IStoParams, 'checklistId' | 'stageId' | 'attributeId'>,
    groupId: string,
    dependencyId: string,
    value: IStoAttributeOdzDictionaryValue[]
  ): void => {
    const stoODZFormType = this.odzService.changeDependencyDictionaryValue(
      params,
      groupId,
      dependencyId,
      value
    );

    this.changesService.changeAttribute(params, { stoODZFormType });
  };

  public changeAttribute: ChangesService['changeAttribute'] = (params, data) => {
    this.changesService.changeAttribute(params, data);
  };

  public addNewUserDictionaryValue: ChangesService['addNewUserDictionaryValue'] = (
    params,
    value
  ) => {
    this.changesService.addNewUserDictionaryValue(params, value);
  };

  public toggleSelectionUserDictionaryValue: ChangesService['toggleSelectionUserDictionaryValue'] = (
    params,
    value,
    isSelected
  ) => {
    this.changesService.toggleSelectionUserDictionaryValue(params, value, isSelected);
  };

  public removeUserDictionaryValue: ChangesService['removeUserDictionaryValue'] = (
    params,
    value
  ) => {
    this.changesService.removeUserDictionaryValue(params, value);
  };

  public editUserDictionaryValue: ChangesService['editUserDictionaryValue'] = (params, value) => {
    this.changesService.editUserDictionaryValue(params, value);
  };

  public saveChanges: ChangesService['updateCellsInTheTable'] = props => {
    const hasErrors = this.changesService.checkIfHasErrors(props);
    const hasOdzErrors = this.changesService.checkIfHasOdzErrors(props);
    const hasNoSelectedDependency = this.changesService.checkIfHasNoSelectedDependency(props);

    if (hasErrors || hasOdzErrors || hasNoSelectedDependency) return;

    this.changesService.updateCellsInTheTable(props);

    this.store.clearHasChanges();
    this.store.setIsSaved(true);
  };

  public undoTheChanges: ChangesService['undoTheChanges'] = params => {
    this.changesService.undoTheChanges(params);
  };

  public removeItemHighlight: UserDictionaryService['removeItemHighlight'] = itemId => {
    this.userDictionaryService.removeItemHighlight(itemId);
  };

  public addOdzError = (groupId: string, error: string): void => {
    this.store.setOdzError(groupId, error);
  };

  public removeOdzError = (groupId: string, error: string): void => {
    this.store.deleteOdzError(groupId, error);
  };

  public clearStore = (): void => {
    this.store.clearIsLoading();
    this.store.clearIsSaved();
    this.store.clearHasChanges();
    this.store.clearHasErrors();
    this.store.clearHasNoSelectedDependency();
    this.store.clearAttribute();
    this.store.clearPrevAttributeUpdate();
    this.store.clearChangedAttrProps();
    this.store.clearUserDictionaryItemList();
    this.store.clearDictionaryOptionList();
    this.store.clearOdzErrorListByGroupId();
    this.store.clearOdzDepErrorListByAttrIdByGroupId();
    this.store.clearDependencyOptionList();
    this.store.clearDepAttrOptionListByAttrId();
  };

  public clearAlreadyChangedAttrIds = (): void => {
    this.store.clearAlreadyChangedAttrIds();
  };
}

export default StoAttributeCoreService;
