// шаг `/sto` идёт от родительского роутера
enum EStoRoute {
  Listing = '/',

  StoItem = '/:stoId',

  // Вкладки
  StoItemCreate = '/create',
  StoItemSummary = '/:stoId/summary',
  StoItemFieldList = '/:stoId/fields',

  StoItemOperationList = '/:stoId/operations',
  StoItemOperation = '/:stoId/operations/:operationId',

  StoItemChecklists = '/:stoId/operations/:operationId/checklists',
  StoItemChecklist = '/:stoId/operations/:operationId/checklists/:checklistId',
  StoItemAttribute = '/:stoId/operations/:operationId/checklists/:checklistId/stages/:stageId/attributes/:attributeId',

  StoItemHistory = '/:stoId/history',
}

export default EStoRoute;
