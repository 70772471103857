import { useModal } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import React, { FC, useMemo } from 'react';

import { Field } from '../../../../../../../../../../api/models/field.model';
import { FieldsStore } from '../../../../../../../../../shared/mobx/stores';
import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { SeasonsStore } from '../../../../../../../../stores/seasons.store';
import { ViewListItem } from '../../../../../../components';
import { FieldsMapCoreStore } from '../../../../../../mobx';
import { EFieldsCommonModalName } from '../../../../../../modals';
import { FieldsViewController as Controller } from '../../../../mobx';

interface IProps {
  field: Field;
  index: number;
}

const FieldViewListItem: FC<IProps> = ({ field, index }) => {
  // Контроллеры
  const controller = useStore(Controller);

  // Сторы
  const fieldsStore = useStore(FieldsStore);
  const seasonsStore = useStore(SeasonsStore);
  const sharedFieldsStore = useStore(FieldsMapCoreStore);

  const modal = useModal();

  const isSelectedField = useMemo(() => {
    return sharedFieldsStore.isSelectedField(field);
  }, [field, sharedFieldsStore.selectedField?.id]);

  const handleClick = () => {
    controller.selectField(field);
  };

  const handleDeleteField = () => {
    const modalId = EFieldsCommonModalName.DeleteFieldWarning;

    modal.openModalByModalId(modalId, null, checkboxValue => {
      const seasonFromDelete = checkboxValue ? null : Number(seasonsStore.selectedSeason);

      void controller.deleteField(field, seasonFromDelete);

      modal.closeModal();
    });
  };

  return (
    <ViewListItem
      field={field}
      dataTestId={`fields-list-section-item-${index}`}
      isLast={index === fieldsStore.fieldsCount - 1}
      isSelected={isSelectedField}
      onClick={handleClick}
      onDelete={handleDeleteField}
    />
  );
};

export default observer(FieldViewListItem);
