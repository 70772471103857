import { Checkbox } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { FC, useState } from 'react';

import { EExperimentsAccessActions } from '../../../../../../../../../shared/constants/access-rules-action';
import { CheckAccessStore } from '../../../../../../../../../authorization/stores/checkAccess.store';
import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { OrganizationsStore } from '../../../../../../../../stores/organizations.store';
import { IInputProps } from '../../types/CommonProps.types';

import Styled from './ExperimentCheckbox.styles';

const ExperimentCheckbox: FC<IInputProps> = ({ cultureZone }) => {
  const { getAccessRuleValue } = useStore(CheckAccessStore);
  const { selectedOrganizationId } = useStore(OrganizationsStore);

  const [checked, setChecked] = useState(cultureZone?.experimentReady);

  const isForbidden = !getAccessRuleValue(EExperimentsAccessActions.IncludeCultureZones);

  const handleChange = value => {
    if ('experimentReady' in cultureZone) {
      cultureZone.experimentReady = value;
    }
  };

  if (selectedOrganizationId !== 'my' && isForbidden) {
    return null;
  }

  return (
    <Styled.Wrapper>
      <Styled.Label>Опыт</Styled.Label>
      <Checkbox
        label={'Включить участок в опыт'}
        value={checked}
        disable={!cultureZone}
        onChange={handleChange}
      />
    </Styled.Wrapper>
  );
};

export default observer(ExperimentCheckbox);
