import { ButtonLink } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { toFixedWithCeil } from '../../../../../../../../../shared/utils/toFixedWithCeil';
import { useFieldsRoutes } from '../../../../../../hooks';
import { FieldsMapCoreStore } from '../../../../../../mobx/stores';
import { getUniqueCulturesNameList } from '../../../../../../utils';
import {
  AccessRuleVisibility,
  ControlAccessRulesWrapper,
} from '../../../../../../../../components/AccessRulesWrapper/ControlAccessRulesWrapper';

import FieldName from './components/FieldName/FieldName';
import Styled from './FieldInfo.styles';
import { getCultures } from './utils';

export const FieldInfo: FC = observer(() => {
  // Навигация
  const navigate = useNavigate();
  const routes = useFieldsRoutes();

  // Сторы
  const sharedFieldsStore = useStore(FieldsMapCoreStore);

  const [cultures, setCultures] = useState<string>('');
  const [culturesImage, setCulturesImage] = useState<string>('');

  const { selectedField } = sharedFieldsStore;

  const handleFieldEdit = () => {
    navigate(routes.fieldEditRoute());
  };

  const handleOpenSeasonsHistory = () => {
    navigate(routes.fieldSeasonsViewRoute());
  };

  useEffect(() => {
    const { culturesPic } = getCultures(selectedField);
    const culturesList = getUniqueCulturesNameList(selectedField?.cultureZones);

    setCultures(culturesList.length > 0 ? culturesList.join(', ') : 'Нет культуры');
    setCulturesImage(culturesPic);
  }, [selectedField]);

  return (
    <Styled.Wrapper data-test-id="widget-field-info">
      <Styled.FieldNameWrapper>
        {selectedField && <FieldName field={selectedField} />}
      </Styled.FieldNameWrapper>

      <Styled.Content>
        <Styled.SquareLabel data-test-id="widget-field-info-square">
          {selectedField && `${toFixedWithCeil(selectedField.area) || 0} га`}
        </Styled.SquareLabel>

        <ControlAccessRulesWrapper mode={AccessRuleVisibility.Hide} actionName="field.editField">
          <ButtonLink color={'accent'} onClick={handleFieldEdit} dataTestId={'edit-field-area'}>
            Редактировать поле
          </ButtonLink>
        </ControlAccessRulesWrapper>

        <Styled.CulturesWrapper>
          <Styled.CulturesLabel>{cultures}</Styled.CulturesLabel>
        </Styled.CulturesWrapper>

        <ButtonLink
          color={'accent'}
          onClick={handleOpenSeasonsHistory}
          dataTestId={'edit-field-seazons'}
        >
          История посевов
        </ButtonLink>
      </Styled.Content>

      {culturesImage && <Styled.CulturePic src={culturesImage} />}
    </Styled.Wrapper>
  );
});
