import { lazyInject, provide } from '../../../../../utils/IoC';
import { IContextualLink as ILink, IContextualLinkConfig as ILinkConfig } from '../../../models';
import { ContextualLinkCrudService as CrudService } from '../ContextualLinkCrudService';
import { ContextualLinkStorageService as StorageService } from '../ContextualLinkStorageService';

@provide.transient()
class ContextualLinkCoreService {
  @lazyInject(CrudService)
  protected crudService: CrudService;

  @lazyInject(StorageService)
  protected storageService: StorageService;

  public addLink = (link: ILink): void => {
    this.crudService.addLink(link);
    this.storeLink(link);
  };

  public createLink = (config: ILinkConfig): void => {
    const link = this.crudService.createLink(config);

    this.storeLink(link);
  };

  public addStoredLinks = (): void => {
    const linkList = this.storageService.getLinkList();

    this.crudService.addLinkList(linkList);
  };

  public removeLink = (id: string): void => {
    this.crudService.removeLink(id);
    this.storageService.removeLink(id);
  };

  protected storeLink = (link: ILink): void => {
    this.storageService.setLink(link);
  };
}

export default ContextualLinkCoreService;
