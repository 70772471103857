import { throttle } from 'lodash';
import { useEffect, useRef } from 'react';

import { TChangeImportedFieldReq as RequestData } from '../../../../../../../api/endpoints/fields/import/changeImportedField';
import { Field } from '../../../../../../../api/models/field.model';
import { useStore } from '../../../../../../shared/utils/IoC';
import { FieldsImportController as Controller } from '../mobx';

/**
 * Данный хук выполняет запрос на обновление поля каждый раз, когда происходит его изменение
 */
function useEditableFieldUpdater(field: Field) {
  // Контроллеры
  const controller = useStore(Controller);

  const throttledApiRequest = useRef(
    throttle((data: RequestData) => {
      void controller.changeImportedField(data);
    }, 3000)
  );

  useEffect(() => {
    if (!field?.geometry) {
      return;
    }

    const data: RequestData = {
      id: field.id,
      name: field.name,
      geoJson: {
        type: 'Feature',
        geometry: field.geometry,
      },
    };

    throttledApiRequest.current(data);
  }, [field?.geometry]);
}

export default useEditableFieldUpdater;
