import styled, { css } from 'styled-components';

import { Colors } from '../../../../../../../shared/constans/colors';
import { BorderRadius, FontSizes } from '../../../../../../../shared/constans/sizes';

export const FieldWeatherWrapper = styled.div<{ isFullWeather: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: auto;
  background: ${Colors.white};
  border-radius: ${BorderRadius.default};
  margin-right: 0;
  @media (min-width: 1366px) {
    margin-right: ${({ isFullWeather }) => (isFullWeather ? `24px` : `0`)};
  }
`;

export const Label = styled.div<{ isFullWeather: boolean }>`
  @media (max-width: 1365px) {
    height: 52px;
  }
  height: ${({ isFullWeather }) => (isFullWeather ? '64px' : '56px')};
  padding: 0 16px 0 24px;
  @media (max-width: 1024px) {
    padding: ${({ isFullWeather }) => (isFullWeather ? '0 16px 0 24px' : '0 2px 0 16px')};
  }
  border-bottom: 1px solid ${Colors.lightnessGray};
  display: flex;
  position: relative;
  justify-content: ${({ isFullWeather }) => (isFullWeather ? 'center' : 'space-between')};
  align-items: center;
  p {
    ${FontSizes.large}
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* font-size: 16px; */
    font-weight: 600;
    /* line-height: 16px; */
    flex-shrink: 1;
    margin: 0;
    transform: ${({ isFullWeather }) => (isFullWeather ? 'translateY(10px)' : 'translateY(6px)')};
    &.weather_condition {
      @media (max-width: 1366px) {
        ${({ isFullWeather }) => {
          if (isFullWeather)
            return css`
              transform: translateY(5px);
            `;
        }};
      }
      ${({ isFullWeather }) => (isFullWeather ? 'font-size: 16px' : '')};
    }
    &.go_back {
      ${({ isFullWeather }) => {
        if (isFullWeather) return 'position: absolute; left: 32px; bottom: 21px;';
        else return '';
      }};
    }
    &::first-letter {
      text-transform: uppercase;
    }
    @media (max-width: 1365px) {
      ${FontSizes.mediumBold}
    }
    &.go_back {
      ${FontSizes.medium}
      color: ${Colors.darkGray};
      display: flex;
      align-items: center;
      cursor: pointer;
      flex-shrink: 0;
      img {
        margin-right: 8px;
      }
    }
    &.more_details {
      @media (max-width: 1365px) {
        ${FontSizes.default}
      }
      @media (max-width: 1024px) {
        font-weight: 400;
      }
      ${FontSizes.medium}
      color: ${Colors.green};
      display: flex;
      align-items: center;
      cursor: pointer;
      flex-shrink: 0;
      padding-right: ${({ isFullWeather }) => (isFullWeather ? '10px' : '6px')};
      @media (max-width: 1024px) {
        padding-right: 10px;
      }
      img {
        margin-left: 8px;
      }
    }
  }
`;

export const Content = styled.div<{ isFullWeather: boolean }>`
  padding: ${({ isFullWeather }) => (isFullWeather ? '16px 44px 16px 34px' : '16px 24px')};
  @media (max-width: 1024px) {
    padding: ${({ isFullWeather }) =>
      isFullWeather ? '16px 32px 16px 32px' : '12px 16px 20px 16px'};
  }
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Panel = styled.div<{ isFullWeather?: boolean }>`
  /* right panel */
  &.left_panel {
    padding-right: 18px;
  }
  &.right_panel {
    flex-shrink: 1;
    overflow: hidden;
    width: 100%;
    max-width: ${({ isFullWeather }) => (isFullWeather ? '350px' : '290px')};
  }
  .geopoint {
    /* @media (max-width: 1365px) {
      ${FontSizes.defaultBold}
    } */
    ${FontSizes.mediumBold}
    @media (max-width: 1024px) {
      ${({ isFullWeather }) => (isFullWeather ? FontSizes.mediumBold : FontSizes.defaultBold)};
    }
    display: flex;
    align-content: center;
    color: ${Colors.green};
    margin-bottom: 14px;
    img {
      margin-right: 10px;
    }
  }
  .sunset_sunrise {
    display: flex;
    margin-bottom: 26px;
    @media (max-width: 1024px) {
      margin-bottom: ${({ isFullWeather }) => (isFullWeather ? '26px' : '4px')};
    }
    & > div {
      ${FontSizes.defaultBold}
      display: flex;
      align-items: center;
      margin-right: 4px;
      img {
        margin-right: 4px;
      }
    }
  }
  .condition {
    display: flex;
    img {
      width: 64px;
      height: 64px;
    }
    .temperature {
      margin-left: 16px;
      @media (max-width: 1024px) {
        margin-top: ${({ isFullWeather }) => (isFullWeather ? '0' : '4px')};
      }

      &_current {
        font-size: 40px;
        font-weight: 800;
        line-height: 44px;
        color: ${Colors.black};
      }
      &_feelslike {
        /* @media (max-width: 1365px) {
          ${FontSizes.default}
        } */
        ${FontSizes.medium}
        color: ${Colors.darkGray};
        white-space: nowrap;
      }
    }
  }
  /* right panel */
  .current_state {
    display: flex;
    align-items: end;
    justify-content: space-between;
    margin-bottom: 4px;
    &:last-of-type {
      margin-bottom: 0px;
    }
    .label {
      /* @media (max-width: 1365px) {
        ${FontSizes.default}
      } */
      ${FontSizes.medium}
      @media (max-width: 1024px) {
        ${({ isFullWeather }) => (isFullWeather ? FontSizes.medium : FontSizes.default)};
      }
      display: flex;
      align-items: flex-start;
      color: ${Colors.darkGray};
      white-space: nowrap;
      flex-shrink: 1;
      overflow: hidden;
      img {
        margin-right: 8px;
      }
      span {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 8px;
        /* @media (max-width: 1240px) {
          display: none;
        } */
      }
    }
    .value {
      /* @media (max-width: 1365px) {
        ${FontSizes.default}
      } */
      ${FontSizes.medium}
      @media (max-width: 1024px) {
        ${({ isFullWeather }) => (isFullWeather ? FontSizes.medium : FontSizes.default)};
      }
      color: ${Colors.black};
      white-space: nowrap;
      /* @media (max-width: 1240px) {
        overflow: hidden;
        text-overflow: ellipsis;
      } */
    }
  }
`;
