import { observer } from 'mobx-react';
import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { PointCoordinatesControls } from '../../../../../shared/features/map/components';
import { useStore } from '../../../../../shared/utils/IoC';
import { OrganizationsStore } from '../../../../stores/organizations.store';
import { SeasonsStore } from '../../../../stores/seasons.store';
import { LegendControls, TopPanelControls } from '../../components';
import FieldsLayout from '../../FieldsLayout';

import FieldEditList from './components/List/List';
import useNotifications from './hooks/useNotifications';
import { FieldEditController as Controller, FieldEditStore as Store } from './mobx';

const FieldEditPage: FC = () => {
  // Навигация
  const { fieldId } = useParams<{ fieldId: string }>();

  // Контроллеры
  const controller = useStore(Controller);

  // Сторы
  const store = useStore(Store);
  const seasonsStore = useStore(SeasonsStore);
  const organizationsStore = useStore(OrganizationsStore);

  useNotifications();

  const season = seasonsStore.selectedSeason;
  const orgId = organizationsStore.selectedOrganizationId;

  useEffect(() => {
    if (!fieldId || !season || !orgId) {
      return;
    }

    void controller.initialize(fieldId);

    return () => {
      controller.destroy();
    };
  }, [season, orgId]);

  return (
    <FieldsLayout
      id={'fields-view-map'}
      leftPanel={<FieldEditList field={store.editableField} />}
      map={{
        config: {
          displayLocate: true,
        },
        controls: [<TopPanelControls />, <PointCoordinatesControls />, <LegendControls />],
      }}
    />
  );
};

export default observer(FieldEditPage);
