import { useModal, useSidebar } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useLocation } from 'react-router-dom';

import { PointCoordinatesControls } from '../../../../../shared/features/map/components';
import MapDrawerStore from '../../../../../shared/features/map/modules/MapDrawer/stores/MapDrawer.store';
import { useStore } from '../../../../../shared/utils/IoC';
import { LegendControls, MapDrawControls, TopPanelControls } from '../../components';
import FieldsLayout from '../../FieldsLayout';
import useFieldRoutes from '../../hooks/useFieldsRoutes';
import { EMapModeId } from '../../../../constants/MapModeId.enum';

import EditZoneLeftPanel from './components/EditZoneLeftPanel/EditLeftPanel';
import SeasonsListLeftPanel from './components/SeasonsListLeftPanel/SeasonsListLeftPanel';
import useMapNotifications from './hooks/useMapNotifications';
import {
  FieldCulturesController as CulturesController,
  FieldCulturesStore as CulturesStore,
  FieldSeasonsViewController as SeasonsController,
} from './mobx';
import { FIELD_SEASONS_MODALS_UNIQUE_KEY, fieldSeasonsModalConfigList } from './modals';

type TRouterParams = {
  orgId?: string;
  fieldId?: string;
};

const FieldSeasonsPage: FC = () => {
  // Навигация
  const { fieldId } = useParams<TRouterParams>();
  const { state: routeState } = useLocation();
  const navigate = useNavigate();
  const routes = useFieldRoutes();

  // Сторы
  const culturesStore = useStore(CulturesStore);
  const mapDrawerStore = useStore(MapDrawerStore);

  // Контроллеры
  const seasonsController = useStore(SeasonsController);
  const culturesController = useStore(CulturesController);

  useMapNotifications();
  const modal = useModal();
  const sidebar = useSidebar();

  const getLeftPanel = () => {
    if (culturesStore.editableCultureZone || mapDrawerStore.isDrawEnabled) {
      return <EditZoneLeftPanel />;
    }

    return <SeasonsListLeftPanel />;
  };

  useEffect(() => {
    sidebar.setSidebarVisibility(false);

    seasonsController.initialize(fieldId).then(isInit => {
      if (!isInit) {
        navigate(routes.fieldsViewRoute);

        return;
      }

      // @ts-ignore
      if (routeState?.enableDraw) {
        culturesController.applyCultureZoneToSameSizeSinglePolygon();
      }
    });

    return () => {
      seasonsController.destroy();
      sidebar.setSidebarVisibility(true);
    };
  }, [fieldId]);

  useEffect(() => {
    modal.registerModalList(fieldSeasonsModalConfigList, FIELD_SEASONS_MODALS_UNIQUE_KEY);
  }, []);

  return (
    <FieldsLayout
      id={EMapModeId.FieldSeasons}
      leftPanel={getLeftPanel()}
      map={{
        config: {
          displayLocate: true,
        },
        controls: [
          <TopPanelControls createControls={<MapDrawControls />} />,
          <LegendControls mapModeId={EMapModeId.FieldSeasons} />,
          <PointCoordinatesControls />,
        ],
      }}
    />
  );
};

export default observer(FieldSeasonsPage);
