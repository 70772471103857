import { ENotificationType, useModal, useNotificator } from '@farmlink/farmik-ui';

import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { StoController } from '../../../../mobx';
import {
  STO_DELETE_DRAFT_MODAL_ID,
  STO_DISCARD_DRAFT_MODAL_ID,
  STO_PUBLISH_MODAL_ID,
} from '../../../../modals';
import {
  ENotificationScheme,
  getNotificationScheme,
} from '../../../../../../../../../shared/utils/helpers/getNotificationScheme';
import { useStoNavigation } from '../../../../../../hooks';
import { StoListController } from '../../../../../../containers/StoListContainer/mobx/controllers';
import { TypeApiRequest } from '../../../../../../../../../shared/utils/axios2';

const useStoActionButton = (globalStoId?: string) => {
  const controller = useStore(StoController);
  const stoListingController = useStore(StoListController);

  const { openModalByModalId } = useModal();
  const { setNotification } = useNotificator();
  const { navigateToListing } = useStoNavigation();

  const activateSto = () => {
    controller
      .activateSto()
      .then(() => {
        setNotification(
          getNotificationScheme(ENotificationScheme.Dark, 'СТО успешно активировано', {
            type: ENotificationType.Success,
          })
        );
      })
      .catch(e => {
        setNotification(
          getNotificationScheme(ENotificationScheme.Dark, 'Ошибка активации СТО', {
            type: ENotificationType.Warning,
          })
        );
      });
  };

  const deactivateSto = () => {
    controller
      .deactivateSto()
      .then(() => {
        setNotification(
          getNotificationScheme(ENotificationScheme.Dark, 'СТО успешно деактивировано', {
            type: ENotificationType.Success,
          })
        );
      })
      .catch(() => {
        setNotification(
          getNotificationScheme(ENotificationScheme.Dark, 'Ошибка деактивации СТО', {
            type: ENotificationType.Warning,
          })
        );
      });
  };

  const publishSto = () => {
    openModalByModalId(STO_PUBLISH_MODAL_ID);
  };

  const deleteSto = (stoId?: string) => {
    openModalByModalId(STO_DELETE_DRAFT_MODAL_ID, null, () =>
      controller
        .deleteChanges(stoId || globalStoId)
        .then(() => {
          setNotification(
            getNotificationScheme(ENotificationScheme.Dark, 'Изменения успешно удалены', {
              type: ENotificationType.Success,
            })
          );

          navigateToListing();
        })
        .catch(() => {
          setNotification(
            getNotificationScheme(ENotificationScheme.Dark, 'Ошибка удаления изменений', {
              type: ENotificationType.Warning,
            })
          );
        })
    );
  };

  const discardDraft = (stoId?: string) => {
    openModalByModalId(STO_DISCARD_DRAFT_MODAL_ID, null, () =>
      controller
        .discardDraft(stoId)
        .then(() => {
          setNotification(
            getNotificationScheme(ENotificationScheme.Dark, 'Изменения успешно отменены', {
              type: ENotificationType.Success,
            })
          );
        })
        .catch(() => {
          setNotification(
            getNotificationScheme(ENotificationScheme.Dark, 'Ошибка отмены изменений', {
              type: ENotificationType.Warning,
            })
          );
        })
    );
  };

  const downloadReport = ({
    stoId,
    stoName,
    versionId,
  }: {
    stoId: string;
    stoName: string;
    versionId: string;
  }): void => {
    const payload: TypeApiRequest<'getStoReport'> = {
      id: stoId,
    };

    if (versionId) {
      payload.versionId = versionId;
    } else {
      payload.includeDrafts = true;
    }

    stoListingController.fetchStoReport({ stoName }, payload, {
      actions: {
        handleFailure: () => {
          const notification = getNotificationScheme(
            ENotificationScheme.Dark,
            'Ошибка при скачивании СТО.\n Попробуйте снова или обратитесь к администратору',
            {
              type: ENotificationType.Warning,
            }
          );

          setNotification(notification);
        },
      },
    });
  };

  return {
    activateSto,
    deactivateSto,
    publishSto,
    deleteSto,
    discardDraft,
    downloadReport,
  };
};

export default useStoActionButton;
