import styled from 'styled-components';

const ButtonWrapper = styled.div<{ $isHidden?: boolean }>`
  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'block')};

  flex: 0 0 300px;
  & > button {
    justify-content: center;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 32px;
  margin-bottom: 48px;
`;

const StyledStoChecklistControlButtons = {
  ButtonWrapper,
  Wrapper,
};

export default StyledStoChecklistControlButtons;
