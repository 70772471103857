import { Icon, useContextualHelpActions } from '@farmlink/farmik-ui';
import { FC } from 'react';

import OverflowTextTooltip from '../../../../../../../../../shared/components/ExtendedAutoTooltip/OverflowTextTooltip';
import { useDataTestIdV2 } from '../../../../../../../../../shared/features/utils/hooks/locators';
import SharedStyled from '../../shared/styles/Shared.styles';
import { useContextualLink } from '../../../../../../../../../shared/features/ContextualLink/hooks';
import { usePageContextualHelp } from '../../../../../../../../../shared/hooks';
import {
  ContextualPaths,
  EContextualParentPath,
} from '../../../../../../../../../shared/constants/contextualPath';

import Styled from './PanelHeader.styles';

interface IProps {
  title: string;
  subtitle: string;
  onBackClick: () => void;
  mode: 'view' | 'edit';
}

const SeasonsListLeftPanelHeader: FC<IProps> = ({ title, subtitle, onBackClick, mode }) => {
  usePageContextualHelp(EContextualParentPath.CultureZonesEditor);
  const helpActions = useContextualHelpActions();

  const ContextualHelpIcon = helpActions.getContextualHelpIcon(
    EContextualParentPath.CultureZonesEditor,
    mode === 'view'
      ? ContextualPaths.CultureZonesEditorTitleView
      : ContextualPaths.CultureZonesEditorTitleEdit
  );

  const getDataTestId = useDataTestIdV2('field-seasons-leftpanel-header');

  return (
    <Styled.Wrapper {...getDataTestId('wrapper')}>
      <Styled.IconWrapper onClick={onBackClick} {...getDataTestId('arrow-icon')}>
        <Icon icon={'arrow_left'} size={'small'} />
      </Styled.IconWrapper>

      <SharedStyled.TextWrapper {...getDataTestId('text-wrapper')}>
        <OverflowTextTooltip content={title ?? ''}>
          {ref => <Styled.Title ref={ref} {...getDataTestId('title')} />}
        </OverflowTextTooltip>

        <OverflowTextTooltip content={subtitle ?? ''}>
          {ref => <SharedStyled.Subtitle ref={ref} {...getDataTestId('subtitle')} />}
        </OverflowTextTooltip>
      </SharedStyled.TextWrapper>

      <Styled.IconWrapper {...getDataTestId('info-icon')}>
        {ContextualHelpIcon ? ContextualHelpIcon : null}
      </Styled.IconWrapper>
    </Styled.Wrapper>
  );
};

export default SeasonsListLeftPanelHeader;
