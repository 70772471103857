import React, { FC, memo } from 'react';

import { useDataTestIdV2 } from '../../../../../../../utils/hooks/locators';
import { IContainerDefaultTitle as IDefaultTitle } from '../../models';

import Styled from './ContainerDefaultTitle.styles';

interface IProps {
  dataTestId: string;
  title: IDefaultTitle;
}

const ContainerDefaultTitle: FC<IProps> = ({ dataTestId, title }) => {
  const getDataTestId = useDataTestIdV2(dataTestId);

  return (
    <Styled.Wrapper {...getDataTestId('wrapper')}>
      <Styled.Title {...getDataTestId('title')}>{title.text}</Styled.Title>
    </Styled.Wrapper>
  );
};

ContainerDefaultTitle.displayName = 'ContainerDefaultTitle';

export default memo(ContainerDefaultTitle);
