import { ENotificationHeight, ENotificationType, useNotificator } from '@farmlink/farmik-ui';
import { INotificationStyle } from '@farmlink/farmik-ui/dist/Notificator/Notificator';
import { useEffect } from 'react';

import MapDrawerStore from '../../../../../../shared/features/map/modules/MapDrawer/stores/MapDrawer.store';
import { useStore } from '../../../../../../shared/utils/IoC';

const infoStyle: INotificationStyle = {
  type: ENotificationType.Info,
  height: ENotificationHeight.BIG,
  hideProgressBar: true,
  autoClose: 10000,
  placement: 'top-right',
};

function useMapNotifications() {
  const notificator = useNotificator();

  const mapDrawerStore = useStore(MapDrawerStore);

  useEffect(() => {
    if (mapDrawerStore.isDrawEnabled) {
      notificator.clearAll();

      notificator.setNotification({
        message: 'Замкните контур для создания фигуры',
        style: infoStyle,
      });
    }
  }, [mapDrawerStore.isDrawEnabled]);

  useEffect(() => {
    notificator.clearAll();

    if (mapDrawerStore.isEditEnabled) {
      notificator.setNotification({
        message: 'Перемещайте точки для изменения контура',
        style: infoStyle,
      });
    }
  }, [mapDrawerStore.isEditEnabled]);

  useEffect(() => {
    return () => notificator.clearAll();
  }, []);
}

export default useMapNotifications;
