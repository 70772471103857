import styled, { CSSProperties } from 'styled-components';

const Body = styled.div<{ $bodyStyles?: CSSProperties }>(({ $bodyStyles }) => ({
  ...($bodyStyles ?? {}),
}));

const Wrapper = styled.div<{ $containerStyles?: CSSProperties }>(({ $containerStyles }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '16px',
  padding: '24px',
  gap: '4px',
  backgroundColor: 'white',
  ...($containerStyles ?? {}),
}));

const StyledContainer = {
  Body,
  Wrapper,
};

export default StyledContainer;
