import { PolylineOptions } from 'leaflet';
import { makeAutoObservable, reaction } from 'mobx';

import { FIELD_POLYGON_OPTIONS } from '../../../../../../../../dashboard/modules/fields/utils/constants/PolygonOptions.constant';
import { mapToArray } from '../../../../../../../utils/helpers/map/mapToArray';
import { IMapPolygonError } from '../../../../../models';
import { EPolygonErrorType } from '../../../../../models/PolygonErrors/PolygonErrors.model';
import { BasePolygon } from '../../../index';

import hasIntersectionsError from './hasIntersections.error';

class PolygonErrors {
  /**
   * При изменении данной коллекции перерисовывает стиль в зависимости от наличия ошибок
   */
  private _errors = new Map<EPolygonErrorType, IMapPolygonError>();

  constructor(private polygon: BasePolygon, private errorStyle?: PolylineOptions) {
    makeAutoObservable(this);

    reaction(
      () => this._errors.size,
      () => this.toggleErrorStyle()
    );
  }

  // Хранит ссылки на полигоны с которыми пересекается данный полигон
  public intersections = new Map<number, BasePolygon>();

  /**
   * Возвращает булевое значение наличия ошибок у полигона
   * @param type - если передать, то првоерит на наличие конкретной ошибки
   */
  public has = (type?: EPolygonErrorType): boolean => {
    if (type) {
      return this._errors.has(type);
    }

    return Boolean(this._errors.size);
  };

  /**
   * Возвращает ошибку по типу
   */
  public get = (type?: EPolygonErrorType): IMapPolygonError | null => {
    return this._errors.get(type) ?? null;
  };

  /**
   * Возвращает массив ошибок
   */
  public get list(): IMapPolygonError[] {
    return mapToArray(this._errors);
  }

  /**
   * Возвращает коллекцию ошибок
   */
  public get collection(): Map<EPolygonErrorType, IMapPolygonError> {
    return this._errors;
  }

  /**
   * Добавляет ошибку в коллекцию. Автомаически меняет стиль полигона
   */
  public addError(error: IMapPolygonError): void {
    this._errors.set(error.type, error);
  }

  /**
   * Удаляет ошибку из коллекции. Если ошибок больше нет, то менят стиль на дефолтный
   */
  public removeError(errorType: EPolygonErrorType): void {
    this._errors.delete(errorType);
  }

  /**
   * Добавляет ссылку на пересекающийся полигон, добавляет ошибку пересечения
   */
  public addIntersection(polygon: BasePolygon): void {
    this.intersections.set(polygon.id, polygon);

    const error = hasIntersectionsError();
    this._errors.set(error.type, error);
  }

  /**
   * Удаляет ссылку на пересекающийся полигон.
   * В случае если пересечений больше нет, то удаляет ошибку
   */
  public removeIntersection(polygonId: number): void {
    this.intersections.delete(polygonId);

    if (!this.intersections.size) {
      this._errors.delete(EPolygonErrorType.Intersection);
    }
  }

  /**
   * Удаляет текущий класс из всех связанных полигонов
   */
  public clearIntersectionLinks(): void {
    this.intersections.forEach(polygon => {
      polygon.errors.removeIntersection(this.polygon.id);
    });
  }

  /**
   * Очищает коллекцию ошибок. Меняет стиль на дефолтный (this._initialOptions)
   */
  public clearErrors(): void {
    this._errors.clear();
  }

  /**
   * Меняет стиль в зависимости от наличия ошибки
   */
  private toggleErrorStyle(): void {
    const errorStyle = this.errorStyle ?? FIELD_POLYGON_OPTIONS.error;

    if (this.has()) {
      this.polygon.setStyle(errorStyle);
      this.polygon.updateGeomanCachedColor(errorStyle?.color);
    } else {
      this.polygon.setPrevStyle();
    }
  }
}

export default PolygonErrors;
