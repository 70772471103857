import { useModal } from '@farmlink/farmik-ui';
import { FC, ReactElement, useEffect } from 'react';

import { Map } from '../../../shared/features/map/components';
import { IMapGlobalConfig } from '../../../shared/features/map/models';
import { MapCoreController } from '../../../shared/features/map/modules';
import { useDataTestIdV2 } from '../../../shared/features/utils/hooks/locators';
import { useStore } from '../../../shared/utils/IoC';

import { FullWeatherSlider } from './components/FullWeatherSlider';
import { BottomPanelContainer } from './components';
import { FIELDS_COMMON_MODALS_UNIQUE_KEY, fieldsCommonModalConfigList } from './modals';
import Styled from './FieldsLayout.styles';

interface IProps {
  id: string;
  leftPanel: ReactElement;
  map: {
    config: Omit<IMapGlobalConfig, 'mapKey'>;
    controls: ReactElement[];
    bottomPanel?: {
      content: ReactElement;
      defaultOpen: boolean;
    };
  };
  weatherWidget?: boolean;
}

const FieldsLayout: FC<IProps> = props => {
  // Контроллеры
  const mapCoreController = useStore(MapCoreController);

  const modal = useModal();

  const centerOfMap = mapCoreController.getCenterOfMap();

  const getDataTestId = useDataTestIdV2(props.id);

  useEffect(() => {
    modal.registerModalList(fieldsCommonModalConfigList, FIELDS_COMMON_MODALS_UNIQUE_KEY);
  }, []);

  return (
    <Styled.Wrapper {...getDataTestId('layout-wrapper')}>
      {props.leftPanel}

      <Styled.MapsControlsWrapper data-test-id={'fields-map'}>
        {props?.weatherWidget && <FullWeatherSlider />}

        <Styled.MapWrapper {...getDataTestId('map-wrapper')}>
          <Map
            globalConfig={{
              ...props.map.config,
              leafletOptions: {
                center: centerOfMap.latLng,
                zoom: centerOfMap.zoom,
                ...props.map.config.leafletOptions,
              },
              mapKey: `${props.id}-map`,
            }}
          />

          {props.map.controls.map(control => control)}
        </Styled.MapWrapper>

        {props.map.bottomPanel && (
          <BottomPanelContainer
            content={props.map.bottomPanel.content}
            defaultOpen={props.map.bottomPanel.defaultOpen}
          />
        )}
      </Styled.MapsControlsWrapper>
    </Styled.Wrapper>
  );
};

export default FieldsLayout;
