import React, { FC, memo } from 'react';
import { isFunction } from 'lodash';

import { useContainerDataTestId as useDataTestId } from '../../hooks';

import { TContainerHeader as THeader } from './models';
import {
  ContainerDefaultHeader as DefaultHeader,
  ContainerClearedHeader as ClearedHeader,
} from './components';

interface IHeaderProps {
  header: THeader;
}

const ContainerHeader: FC<IHeaderProps> = ({ header }) => {
  const getDataTestId = useDataTestId();

  const formattedDataTestId = getDataTestId('header')['data-test-id'];

  if (isFunction(header)) {
    return (
      <>
        {header({
          dataTestId: formattedDataTestId,
        })}
      </>
    );
  }

  if (header?.default) {
    return <DefaultHeader dataTestId={formattedDataTestId} header={header.default} />;
  }

  if (header?.cleared) {
    return <ClearedHeader dataTestId={formattedDataTestId} header={header.cleared} />;
  }

  return <></>;
};

ContainerHeader.displayName = 'ContainerHeader';

export default memo(ContainerHeader);
