import React, { FC, useEffect, useState } from 'react';
import { NewDropdown, TNewDropdownProps, Typography, useModal } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';

import { useDataTestIdV2 } from '../../../../../../../../../../../../../../../../../../../../../shared/features/utils/hooks/locators';
import { useStore } from '../../../../../../../../../../../../../../../../../../../../../shared/utils/IoC';
import { StoAttributeCoreStore as Store } from '../../../../../../mobx/stores';
import { StoAttributeCoreController as Controller } from '../../../../../../mobx/controllers';
import { useStoParams } from '../../../../../../../../../../../../../../../../../../hooks';
import { StoStore } from '../../../../../../../../../../../../../../../../mobx';
import { StoChecklistsCoreStore as ChecklistsStore } from '../../../../../../../../../../mobx/stores';
import { ISelectOption } from '../../../../../../../../../../../../../../../../../../../../../../types/selectOption';
import { IStoAttribute as IAttribute } from '../../../../../../../../../../../../../../../../../../../../../../api/models/as-fields/new/sto';
import { StoAttributeOdzModals } from '../../modals';

import Styled from './StoAttributeOdzDependency.styles';

const StoAttributeOdzDependency: FC = () => {
  const store = useStore(Store);
  const stoStore = useStore(StoStore);
  const checklistsStore = useStore(ChecklistsStore);

  const controller = useStore(Controller);

  const params = useStoParams();
  const modalActions = useModal();

  const { stoODZFormType } = checklistsStore.getAttrUpdate(
    params.checklistId,
    params.stageId,
    params.attributeId
  );

  /**
   * Специальная логика, чтобы заставить работать правильно дропдаун с модальным окном при выборе значения.
   */
  const [_selectedValue, _setSelectedValue] = useState<ISelectOption | null>(null);

  useEffect(() => {
    return () => {
      store.clearHasNoSelectedDependency();
    };
  }, []);

  const getDefaultValue = (): ISelectOption<IAttribute> => {
    if (!stoODZFormType.odzGroups?.length) return null;

    const [firstGroup] = stoODZFormType.odzGroups;

    if (!firstGroup?.dependencyAttributes?.length) return null;

    const [firstDep] = firstGroup.dependencyAttributes;

    return store.dependencyOptionList.find(o => o.value === firstDep.id);
  };

  useEffect(() => {
    _setSelectedValue(getDefaultValue());
  }, [getDefaultValue()]);

  const checkIfIsBlockedByPermissions = (): boolean => {
    if (params.versionId || stoStore.isViewOnly) return true;
  };

  const handleChange: TNewDropdownProps['config']['field']['onChange'] = (_, otherData) => {
    if (!_selectedValue) {
      controller.changeOdzDependency(otherData.option.initialModel, params);
      return;
    }

    _setSelectedValue(otherData?.option);

    const handleSuccess = (): void => {
      if (!otherData?.option) {
        controller.changeOdzDependency(null, params);
        return;
      }

      controller.changeOdzDependency(otherData.option.initialModel, params);
      _setSelectedValue(otherData.option);
    };

    const handleDeny = (): void => {
      _setSelectedValue(getDefaultValue());
    };

    modalActions.openModalByModalId(
      StoAttributeOdzModals.warningBeforeDependencyChange.id,
      null,
      handleSuccess,
      handleDeny
    );
  };

  const getDataTestId = useDataTestIdV2('sto__attribute__odz__dependency');

  return (
    <Styled.Wrapper {...getDataTestId()}>
      <Styled.Header {...getDataTestId('header')}>
        <Typography dataTestId={getDataTestId('title')['data-test-id']} variant={'bodyAccent'}>
          Выберите определяющий параметр, от показателей которого будет зависеть область допустимых
          значений:
        </Typography>
      </Styled.Header>

      <Styled.Body {...getDataTestId('body')}>
        <NewDropdown
          config={{
            field: {
              onChange: handleChange,
              defaultValue: _selectedValue,
              icons: {
                clear: {},
              },
              placeholder: 'Выберите значение',
            },
            body: {
              optionList: store.dependencyOptionList,
            },
            visual: {
              label: 'Определяющий параметр',
              isBlocked: checkIfIsBlockedByPermissions(),
            },
            other: {
              dataTestId: getDataTestId('selector')['data-test-id'],
            },
          }}
        />
      </Styled.Body>
    </Styled.Wrapper>
  );
};

StoAttributeOdzDependency.displayName = 'StoAttributeOdzDependency';

export default observer(StoAttributeOdzDependency);
