type KeysMatching<T, V> = {
  [K in keyof T]: T[K] extends V ? K : never;
}[keyof T];

/**
 * Преобразует массив в коллекцию
 * @param array - массив для преобразования
 * @param key - ключ коллекции. (Может быть только строкой или числом)
 */
function arrayToMap<T>(
  array: Array<T>,
  key: KeysMatching<T, number | string>
): Map<T[typeof key], T> {
  if (typeof key !== 'string' && typeof key !== 'number') {
    return new Map();
  }

  return new Map(array.map(element => [element[key] as any, element]));
}

export default arrayToMap;
