import { Chip as FChip } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const Chip = styled(FChip)`
  margin-bottom: 0;
  margin-top: auto;
  margin-left: 20px;
  font-weight: 400;
`;

const Styled = {
  Chip,
};

export default Styled;
