import { Colors } from '@farmlink/farmik-ui';
import styled from 'styled-components';

type PropType = {
  visibility: boolean;
  transitionTime: string;
};

const PSlider = styled.div<PropType>`
  background: ${Colors.secondaryGray};

  position: relative;
  box-sizing: border-box;
  
  transition: ${({ transitionTime }) => `max-height ${transitionTime} linear 0s;`}
  max-height: ${({ visibility }) => (visibility ? '500px' : '0px;')};
`;

export default {
  PSlider,
};
