import { useModal } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { FC, useEffect } from 'react';

import { useStore } from '../../../../../shared/utils/IoC';
import { FieldsStore } from '../../../../../shared/mobx/stores';
import { OrganizationsStore } from '../../../../stores/organizations.store';
import { SeasonsStore } from '../../../../stores/seasons.store';
import { LegendControls, TopPanelControls } from '../../components';
import FieldsLayout from '../../FieldsLayout';
import {
  FIELDS_IMPORT_MODALS_UNIQUE_KEY,
  fieldsImportModalConfigList,
} from '../FieldsImport/modals';

import FieldsViewBottomWidget from './components/BottomWidget/BottomWidget';
import FieldsViewList from './components/List/List';

import { FieldsViewController as Controller } from './index';

const FieldsViewPage: FC = () => {
  // Контроллеры
  const controller = useStore(Controller);

  // Сторы
  const organizationsStore = useStore(OrganizationsStore);
  const seasonsStore = useStore(SeasonsStore);
  const fieldsStore = useStore(FieldsStore);

  const season = seasonsStore.selectedSeason;
  const orgId = organizationsStore.selectedOrganizationId;
  const fieldsList = fieldsStore.sortedByNameFieldList;

  const modal = useModal();

  useEffect(() => {
    if (!season || !orgId) {
      return;
    }

    void controller.initialize();

    return () => {
      controller.destroy();
    };
  }, [season, orgId]);

  useEffect(() => {
    modal.registerModalList(fieldsImportModalConfigList, FIELDS_IMPORT_MODALS_UNIQUE_KEY);
  }, []);

  return (
    <FieldsLayout
      id={'fields-view-map'}
      leftPanel={<FieldsViewList list={fieldsList} />}
      map={{
        config: {
          displayLocate: true,
        },
        controls: [<TopPanelControls />, <LegendControls />],
        bottomPanel: {
          content: <FieldsViewBottomWidget />,
          defaultOpen: false,
        },
      }}
      weatherWidget
    />
  );
};

export default observer(FieldsViewPage);
