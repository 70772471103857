import { Button } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { FC, useMemo, useState } from 'react';

import { useStore } from '../../../../../../../shared/utils/IoC';
import { StoController, StoStore } from '../../mobx';
import { StoOperationsCoreController } from '../../modules/StoOperations/mobx/controllers';
import { EStoTab } from '../../models/StoCommon';
import { EStoAction, EStoStatus } from '../../../../../../../../api/models/as-fields/STO/STO.model';
import { useStoParams } from '../../../../hooks';

import Styled from './StoControlButtons.styles';
import { useStoControls } from './hooks';

const StoControlButtons: FC = () => {
  const store = useStore(StoStore);
  const controller = useStore(StoController);
  const operationsCoreController = useStore(StoOperationsCoreController);

  const [isFetching, setIsFetching] = useState(false);

  const controls = useStoControls();
  const { versionId } = useStoParams();

  const handleButtonClick = () => {
    if (isFetching) {
      return;
    }

    setIsFetching(true);

    if (store.isCreateMode) {
      controller
        .createSto()
        .then(STO => controls.createStoSuccessHandler(STO.id))
        .catch(() => controls.createStoErrorHandler())
        .finally(() => {
          setIsFetching(false);
        });
    }

    if (store.selectedSto?.id) {
      controller
        .updateSto({
          operationsSuccessHandler: operationsCoreController.allowAvailAbleSettingsManagement,
        })
        .then(response => controls.updateStoSuccessHandler(response))
        .catch(e => controls.updateStoErrorHandler(e))
        .finally(() => {
          setIsFetching(false);
        });
    }
  };

  const primaryButtonText = useMemo(() => {
    if (store.isCreateMode) {
      return 'Создать СТО';
    }

    return 'Сохранить';
  }, [store.isCreateMode]);

  const isBlocked = useMemo(() => {
    if (store.isCreateMode) {
      return !store.isCreateStoFormValid;
    }

    if (store?.hasChanges) {
      return !store.isEditStoFormValid;
    }

    return false;
  }, [
    store.isCreateMode,
    store.isCreateStoFormValid,
    store.isEditStoFormValid,
    store?.updatedStoData,
    store?.createStoData,
  ]);

  const isHideButton = useMemo(() => {
    if (store?.StoTab === EStoTab.History) return true;
    if (store?.selectedSto?.calculatedStatus?.status === EStoStatus.Inactive) return true;
    if (!store?.isCreateMode && !store?.selectedSto?.availableActions?.includes(EStoAction.StoEdit))
      return true;

    return store?.isViewOnly;
  }, [
    store?.isCreateMode,
    store?.isViewOnly,
    store?.StoTab,
    store.selectedSto?.availableActions,
    versionId,
  ]);

  return (
    <Styled.Wrapper>
      <Styled.ButtonWrapper $isHidden={isHideButton}>
        <Button
          color={'primary'}
          type={'button'}
          onClick={handleButtonClick}
          disabled={isBlocked}
          dataTestId={
            store.isCreateMode ? 'fullscreen-create-sto-button' : 'fullscreen-save-sto-button'
          }
        >
          {primaryButtonText}
        </Button>
      </Styled.ButtonWrapper>
    </Styled.Wrapper>
  );
};

StoControlButtons.displayName = 'StoControlButtons';

export default observer(StoControlButtons);
