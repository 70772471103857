export interface ISto {
  createDate: string;
  createdBy: string;
  culture: {
    attrs: any;
    code: string;
    description: string;
    id: string;
    level: number;
    name: string;
    parentId: string;
    path: string;
    picLink?: {
      downloadUrl: string;
      fileContentType: string;
      fileName: string;
      fileSize: number;
      fileUploadDate: string;
      fileUploadUserId: string;
      id: string;
    };
  };
  cultureZones: string[];
  id: string;
  isDefault: boolean;
  latestVersion: string;
  name: string;
  organizationId: string;
  publishDate: string;
  seasonYear: number;
  status: EStoStatus;
  totalArea: number;
  versionComment: string;
  versionName: string;
  totalCultureZones: number;
  calculatedStatus: {
    date: string;
    status: EStoStatus;
  };
}

export enum EStoStatus {
  Draft = 'DRAFT',
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Modified = 'MODIFIED',
}

/**
 * DTO создания СТО
 * @DTO StoCreateDto
 * @interface ICreateSto
 */
export interface ICreateSto {
  name: string;
  organizationId: string;
  cultureId: string;
  seasonYear: number;
  isDefault: boolean;
  cultureZones?: string[];
}

/**
 * DTO обновления СТО
 * @DTO IUpdateSTO
 * @interface IUpdateSto
 */
export interface IUpdateSto {
  name: string;
  status: EStoStatus;
  organizationId: string;
  culture: { id: string; name: string };
  seasonYear: number;
  isDefault: boolean;
  cultureZones?: string[];
  transferCultureZones?: string[];
  stoItems: IStoItem[];
  stoOperations?: IUpdateStoOperation[];
}

export interface IUpdateStoOperation {
  techOperationTypeId: string;
}

interface IStoItem {
  stoId: string;
  techOperationTypeId: string;
  checklistId: string;
  createdBy: string;
  /**
   * дата в формате YYYY-MM-DD
   */
  createDate: string;
  /**
   * дата в формате YYYY-MM-DD
   */
  publishDate: string;
  /**
   * дата в формате YYYY-MM-DD
   */
  deleteDate: string;
  isDraft: boolean;
}

/**
 * Полный DTO СТО
 *
 * @DTO StoContentDto
 * @interface IStoContent
 */
export interface IStoContent {
  id: string;
  name: string;
  availableActions: EStoAction[];
  organization: {
    name: string;
    representativeFullName: string;
    representativePosition: string;
    organizationId: string;
    INN: string;
    type: {
      orgTypeId: number;
      OrgType: string;
    };
  };
  calculatedStatus: {
    status: EStoStatus;
    date: string;
  };
  culture: {
    id: string;
    name: string;
  };
  seasonYear: number;
  status: EStoStatus;
  /**
   * дата в формате YYYY-MM-DD
   */
  createDate: string;
  /**
   * дата в формате YYYY-MM-DD
   */
  publishDate: string;
  /**
   * дата в формате YYYY-MM-DD
   */
  deleteDate: string;
  isDefault: boolean;
  isDraft: boolean;
  cultureZones: string[];
  stoItems: IStoItem[];
  stoOperations?: IStoOperation[];
}

interface IStoOperation {
  id: string;
  stoId: string;
  createdBy: string;
  updateBy: string;
  createDate: string;
  publishDate: string;
  deleteDate: string;
  isDraft: boolean;
  stoChecklists: IStoChecklist[];
  techOperationType: {
    id: string;
    name: string;
  };
}

interface IStoChecklist {
  id: string;
  stoId: string;
  techOperationTypeId: string;
  checklistId: string;
  createdBy: string;
  updatedBy: string;
  createDate: string;
  publishDate: string;
  deleteDate: string;
  isDraft: boolean;
}

export enum EStoAction {
  StoView = 'sto.view',
  StoEdit = 'sto.edit',
  StoPublish = 'sto.publish',
  StoDiscardCard = 'sto.edit.discardDraft',
  StoDeactivate = 'sto.publish.deactivate',
  StoActivate = 'sto.publish.activate',
  StoDelete = 'sto.edit.delete',
  StoDownload = 'sto.view.download',
}
