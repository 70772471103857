import { useModal } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { FC, useEffect } from 'react';

import { PointCoordinatesControls } from '../../../../../shared/features/map/components';
import { useStore } from '../../../../../shared/utils/IoC';
import { OrganizationsStore } from '../../../../stores/organizations.store';
import { SeasonsStore } from '../../../../stores/seasons.store';
import FieldsLayout from '../../FieldsLayout';
import { LegendControls, MapDrawControls, TopPanelControls } from '../../components';

import FieldsCreateList from './components/List/List';
import useMapNotifications from './hooks/useMapNotifications';
import { FIELDS_CREATE_MODALS_UNIQUE_KEY, fieldsCreateModalConfigList } from './modals';
import { FieldsCreateController as Controller, FieldsCreateStore as Store } from './mobx';

const FieldsCreatePage: FC = () => {
  // Контроллеры
  const controller = useStore(Controller);

  // Сторы
  const store = useStore(Store);
  const seasonsStore = useStore(SeasonsStore);
  const organizationsStore = useStore(OrganizationsStore);

  const season = seasonsStore.selectedSeason;
  const orgId = organizationsStore.selectedOrganizationId;

  const modal = useModal();
  useMapNotifications();

  useEffect(() => {
    if (!season || !orgId) {
      return;
    }

    void controller.initialize();

    return () => {
      controller.destroy();
    };
  }, [season, orgId]);

  useEffect(() => {
    modal.registerModalList(fieldsCreateModalConfigList, FIELDS_CREATE_MODALS_UNIQUE_KEY);
  }, []);

  return (
    <FieldsLayout
      id={'fields-create'}
      leftPanel={<FieldsCreateList list={store.createdFieldsList} />}
      map={{
        config: {
          displayLocate: true,
        },
        controls: [
          <TopPanelControls createControls={<MapDrawControls />} />,
          <PointCoordinatesControls />,
          <LegendControls />,
        ],
      }}
    />
  );
};

export default observer(FieldsCreatePage);
