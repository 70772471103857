import {
  ENotificationHeight,
  ENotificationType,
  ENotificatorTheme,
  useNotificator,
} from '@farmlink/farmik-ui';
import { INotificationStyle } from '@farmlink/farmik-ui/dist/Notificator/Notificator';
import { useEffect } from 'react';

import { useStore } from '../../../../../../shared/utils/IoC';
import { FieldsCreateStore as Store } from '../mobx';

const infoStyle: INotificationStyle = {
  type: ENotificationType.Info,
  height: ENotificationHeight.BIG,
  hideProgressBar: true,
  autoClose: 10000,
  placement: 'top-right',
};

function useMapNotifications() {
  // Сторы
  const store = useStore(Store);

  const notificator = useNotificator();

  useEffect(() => {
    if (store.isFinishedPolygon) {
      notificator.clearAll();

      return;
    }

    notificator.setNotification({
      message: 'Замкните контур, чтобы завершить создание поля',
      style: {
        ...infoStyle,
        theme: ENotificatorTheme.Light,
      },
    });
  }, [store.isFinishedPolygon]);

  useEffect(() => {
    return () => notificator.clearAll();
  }, []);
}

export default useMapNotifications;
