// @ts-nocheck

import L, { Content, LatLngBounds, Layer, LayerGroup, LayerOptions, Tooltip } from 'leaflet';
import { cloneDeep } from 'lodash';

import { BaseTooltipOptions } from '../../constants';
import { BasePolygon } from '../polygons';

abstract class BaseLayerGroup extends LayerGroup {
  public id: number;
  public isSelected = false;

  /**
   * Указывает в какой родительский слой необходимо добавлять/удалять данный слой.
   * Очень небезопасное решение. Надо подумать над механизмом определения слоя-родителя
   */
  public parentLayerKey: 'deflateLayer' | 'instance' = 'instance';

  public initialBounds: LatLngBounds | null = null;

  protected constructor(layers?: Layer[], options?: LayerOptions) {
    super(layers, options);

    this.id = L.Util.stamp(this);

    this.initialBounds = this.getBounds();
  }

  public abstract select(): void;

  public abstract deselect(): void;

  public abstract getMainPolygon(): BasePolygon;

  public redrawTooltip() {
    const tooltip = cloneDeep(this.getTooltip());

    if (!tooltip) {
      return;
    }

    this.unbindTooltip();
    this.bindTooltip(tooltip.getContent(), tooltip.options);
  }

  public setTooltipContent(content: Content | Tooltip): this {
    if (!this.getTooltip()) {
      this.bindTooltip(content, BaseTooltipOptions);

      return;
    }

    return super.setTooltipContent(content);
  }

  public addLayer(layer): this {
    if (this.hasLayer(layer)) {
      return this;
    }

    layer.addEventParent(this);

    return super.addLayer(layer);
  }

  public removeLayer(layer): this {
    if (!this.hasLayer(layer)) {
      return this;
    }

    if (layer in this._layers) {
      // eslint-disable-next-line no-param-reassign
      layer = this._layers[layer];
    }

    layer.removeEventParent(this);

    return super.removeLayer(layer);
  }

  public getBounds() {
    const bounds = new LatLngBounds();

    for (const id in this._layers) {
      if (Object.hasOwn(this._layers, id)) {
        const layer = this._layers[id];
        bounds.extend(layer.getBounds ? layer.getBounds() : layer.getLatLng());
      }
    }
    return bounds;
  }
}

export default BaseLayerGroup;
