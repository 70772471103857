import { AutoTooltip } from '@farmlink/farmik-ui';
import React, { useMemo } from 'react';

import Styled from '../ViewListItem.styles';

const MAX_FILED_NAME_LENGTH = 18;

type Props = {
  dataTestId: string;
  fieldName: string;
  cultures: string;
};

export const FieldNameAndCulture = ({ dataTestId, fieldName, cultures }: Props) => {
  const renderName = useMemo(() => {
    return fieldName?.length > MAX_FILED_NAME_LENGTH
      ? `${fieldName?.substr(0, MAX_FILED_NAME_LENGTH)}...`
      : fieldName;
  }, [fieldName]);

  const cultureLabel = useMemo(() => {
    return cultures?.length > MAX_FILED_NAME_LENGTH
      ? `${cultures.substr(0, MAX_FILED_NAME_LENGTH)}...`
      : cultures;
  }, [cultures]);

  return (
    <Styled.LabelsWrapper>
      <AutoTooltip content={fieldName} disabled={fieldName?.length <= MAX_FILED_NAME_LENGTH}>
        <Styled.FieldName data-test-id={`${dataTestId}-field-name`}>{renderName}</Styled.FieldName>
      </AutoTooltip>

      {cultures?.length ? (
        <AutoTooltip content={cultures} disabled={cultures?.length <= MAX_FILED_NAME_LENGTH}>
          <Styled.CultureLabel data-test-id={`${dataTestId}-culture-culture`}>
            {cultureLabel}
          </Styled.CultureLabel>
        </AutoTooltip>
      ) : (
        <Styled.CulturePlaceholder data-test-id={`${dataTestId}-culture-no-culture`}>
          Нет культуры
        </Styled.CulturePlaceholder>
      )}
    </Styled.LabelsWrapper>
  );
};
