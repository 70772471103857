import * as turf from '@turf/turf';

import { IMapPolygonError } from '../../models';
import { mergePolygons } from '../helpers';
import { hasIntersectionsError } from '../MapElements/polygons/BasePolygon/errors';
import isOutOfBorderError from '../MapElements/polygons/BasePolygon/errors/isOutOfBorder.error';
import { BasePolygon, CulturePolygon, CulturesLayerGroup } from '../MapElements';
import { DEFAULT_SAME_POLYGON_DEFERENCE_THRESHOLD } from '../constants';

/**
 * Класс валидации для КЗ-полигона.
 * Содержит следующие валидации:
 * 1. Проверка на пересечения с другими КЗ-полигонами
 * 2. Проверка нахождения внутри границ родительского полигона.
 */
class CulturePolygonValidator {
  constructor(private polygon: CulturePolygon, protected parentLayer: CulturesLayerGroup) {}

  /**
   * Проверяет полигон из конструктора на пересечение с другими КЗ-полигонами внутри parentLayer.
   */
  public checkIntersections(): this {
    const allCulturesPolygons = this.parentLayer?.getCulturePolygonsList();

    if (!allCulturesPolygons) {
      return this;
    }

    if (allCulturesPolygons.length === 2) {
      const [notSamePolygon] = allCulturesPolygons.filter(
        polygon => polygon.id !== this.polygon.id
      );

      const difference = turf.difference(this.polygon.toTurf(), notSamePolygon.toTurf());

      if (difference === null || turf.area(difference) < DEFAULT_SAME_POLYGON_DEFERENCE_THRESHOLD) {
        return this;
      }
    }

    const turfPolygonsList = allCulturesPolygons
      .filter(polygon => polygon.id !== this.polygon.id)
      .map(polygon => polygon.toTurf());

    const mergedPolygon = mergePolygons(turfPolygonsList);

    if (!mergedPolygon) {
      return this;
    }

    const bufferedPolygon = this.reducePolygonSize(this.polygon);
    const intersect = turf.intersect(bufferedPolygon, mergedPolygon);

    if (Boolean(intersect)) {
      const error = hasIntersectionsError('Имеются пересечения культурных зон');
      this.polygon.errors.addError(error);
    }

    return this;
  }

  /**
   * Проверяет что КЗ-полигон из конструктора не выходит за пределы parentLayer
   */
  public checkIsOutOfParent(): this {
    const mainPolygon = this.parentLayer?.getMainPolygon();
    const bufferedPolygon = this.reducePolygonSize(this.polygon);

    if (!mainPolygon) {
      return this;
    }

    const diff = turf.difference(bufferedPolygon, mainPolygon.toTurf());

    if (diff) {
      const error = isOutOfBorderError('Культурная зона выходит за границы поля');
      this.polygon.errors.addError(error);
    }

    return this;
  }

  /**
   * В случае если есть ошибки, устанавливает предыдущую валидную геометрию для полигона.
   * Возвращает список ошибок полигона
   */
  public resetToLastValidGeometry(): IMapPolygonError[] {
    const errors = this.polygon.errors.list;

    if (!this.polygon.errors.has()) {
      const currGeometry = this.polygon.getInfo().geometry;
      this.polygon.updateValidGeometry(currGeometry);

      return errors;
    }

    const lastValidGeometry = this.polygon.getLastValidGeometry();
    this.polygon.changeGeometry(lastValidGeometry);

    this.polygon.errors.clearErrors();

    return errors;
  }

  /**
   * Уменьшаем исходный полигон на 1 см по всему периметру.
   * Это необходимо из-за особенностей работы функции "snap" у geoman.
   * Без этого уменьшения в половине случаев валидация не будет работать
   */
  private reducePolygonSize(polygon: BasePolygon) {
    return turf.buffer(polygon.toTurf(), -1, { units: 'centimeters' });
  }
}

export default CulturePolygonValidator;
