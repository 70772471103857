import { ITab } from '@farmlink/farmik-ui/dist/Tabs/types';

import { lazyInject, provide } from '../../../../../../../shared/utils/IoC';
import { EStoTab, IStoFullscreenConfig } from '../../models/StoCommon';
import { StoStore } from '../stores';
import { StoFieldsTab, SummaryTab, StoOperations as Operations, HistoryTab } from '../../modules';
import { EStoStatus } from '../../../../../../../../api/models/as-fields/STO/STO.model';

@provide.transient()
class StoTabService {
  @lazyInject(StoStore)
  protected store: StoStore;

  getFullscreenConfigFromTabDictionary = (
    tab: EStoTab,
    isCreation?: boolean
  ): IStoFullscreenConfig => {
    if (isCreation) {
      return {
        dataTestIdPrefix: 'createSTO',
        goBackText: 'К списку СТО',
        titleText: 'Новый стандарт техопераций',
        fullscreenContent: <SummaryTab />,
      };
    }

    const fullscreenConfigMap: Record<EStoTab, IStoFullscreenConfig> = {
      [EStoTab.Summary]: {
        dataTestIdPrefix: 'sto__summary',
        goBackText: 'К списку СТО',
        titleText: this.store.selectedSto?.name,
        fullscreenContent: <SummaryTab />,
      },
      [EStoTab.Fields]: {
        dataTestIdPrefix: 'sto__fields',
        goBackText: 'К списку СТО',
        titleText: this.store.selectedSto?.name,
        fullscreenContent: <StoFieldsTab />,
      },
      [EStoTab.Operations]: {
        dataTestIdPrefix: 'sto__operations',
        goBackText: 'К списку СТО',
        titleText: 'Новый стандарт техопераций',
        fullscreenContent: <Operations />,
      },
      [EStoTab.History]: {
        dataTestIdPrefix: 'sto__history',
        goBackText: 'К списку СТО',
        titleText: this.store.selectedSto?.name,
        fullscreenContent: <HistoryTab />,
      },
    };

    return fullscreenConfigMap[tab];
  };

  private getTabConfig = (options?: {
    isCreation?: boolean;
    hasNoFields?: boolean;
    hasPublishedVersions?: boolean;
  }): ITab[] => {
    return [
      { id: EStoTab.Summary, label: 'Постановка' },
      { id: EStoTab.Fields, label: 'Поля', disabled: options?.isCreation },
      {
        id: EStoTab.Operations,
        label: 'Операции',
        disabled: options?.isCreation || options?.hasNoFields,
      },
      ...(options?.hasPublishedVersions
        ? [
            {
              id: EStoTab.History,
              label: 'Версии',
            },
          ]
        : []),
    ];
  };

  calculateStoItemTab = (isCreation?: boolean, stoTabFromUrl?: EStoTab): EStoTab => {
    if (isCreation) {
      this.store.setStoTab(EStoTab.Summary);

      this.store.setAvailableTabList(this.getTabConfig({ isCreation }));

      return EStoTab.Summary;
    }

    const hasFields = this.store?.selectedSto?.cultureZones?.length > 0;
    const hasPublishedVersions =
      this.store?.selectedSto?.calculatedStatus?.status !== EStoStatus.Draft;

    let tab = hasFields ? EStoTab.Operations : EStoTab.Fields;

    if (stoTabFromUrl) {
      tab =
        stoTabFromUrl === EStoTab.History && hasPublishedVersions
          ? EStoTab.History
          : stoTabFromUrl === EStoTab.Operations && hasFields
          ? EStoTab.Operations
          : stoTabFromUrl === EStoTab.Fields
          ? EStoTab.Fields
          : EStoTab.Summary;
    }

    this.store.setStoTab(tab);
    this.store.setAvailableTabList(
      this.getTabConfig({ hasNoFields: !hasFields, hasPublishedVersions })
    );

    return tab;
  };

  calculateStoItemTabConfigByParams = (
    tab: EStoTab,
    isCreation?: boolean
  ): IStoFullscreenConfig => {
    const config = this.getFullscreenConfigFromTabDictionary(tab, isCreation);

    this.store.setStoFullscreenConfig(config);

    return config;
  };
}

export default StoTabService;
