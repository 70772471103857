import React, { FC, useMemo } from 'react';
import { observer } from 'mobx-react';
import { CSSProperties } from 'styled-components';
import { Outlet } from 'react-router-dom';

import { ComparisonTableBuilder } from '../../../../../../../../../../../../../shared/features/ComparisonTableBuilder';
import { useStoParams } from '../../../../../../../../../../hooks';
import { Container } from '../../../../../../../../../../../../../shared/features/UI/new/Container';
import { useStore } from '../../../../../../../../../../../../../shared/utils/IoC';
import { StoChecklistsCoreStore as ChecklistsStore } from '../../mobx/stores';
import { StoChecklistsCoreController as StoChecklistsController } from '../../mobx/controllers';
import { StoChecklistsChecklistSwitcher } from '../../components';

import { StoChecklistControlButtons } from './containers';

const StoChecklist: FC = () => {
  const checklistsStore = useStore(ChecklistsStore);
  const checklistsController = useStore(StoChecklistsController);

  const params = useStoParams();

  const containerTitle = checklistsStore.getChecklist(params.checklistId)?.name;

  const isChecklistSelected = checklistsController.checkIfChecklistIsSelected(params.checklistId);

  const selectedChecklistsStyles = useMemo<CSSProperties>(() => {
    if (isChecklistSelected) return {};

    return {
      opacity: '0.6',
      pointerEvents: 'none',
    };
  }, [checklistsController.checkIfChecklistIsSelected(params.checklistId)]);

  return (
    <>
      <Outlet />

      <Container
        dataTestId={'sto__checklists-attrs__table'}
        containerStyles={{ gap: '16px', ...selectedChecklistsStyles }}
        header={{
          cleared: {
            title: {
              default: {
                text: containerTitle,
              },
            },
            additionalContent:
              checklistsStore.checklistList.length < 2 ? null : <StoChecklistsChecklistSwitcher />,
          },
        }}
      >
        <ComparisonTableBuilder
          builderId={params.checklistId}
          tableName={'sto__checklists-attrs__table'}
        />
      </Container>

      <StoChecklistControlButtons />
    </>
  );
};

StoChecklist.displayName = 'StoChecklist';

export default observer(StoChecklist);
