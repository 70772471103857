import { FC } from 'react';

import { useStore } from '../../../../../../../../../../../../../shared/utils/IoC';
import { ReactComponent as AddIcon } from '../../../../../../../../../../../../static/action-icons/add-icon.svg';
import { FieldCulturesController as CulturesController } from '../../../../../../../../mobx';

import Styled from './AddNewButton.styles';

interface IProps {
  dataTestId: { 'data-test-id': string };
}

const AddNewButton: FC<IProps> = ({ dataTestId }) => {
  // Контроллеры
  const culturesController = useStore(CulturesController);

  const handleClick = () => {
    culturesController.enableDrawPolygon();
  };

  return (
    <Styled.Wrapper onClick={handleClick} {...dataTestId}>
      <Styled.IconWrapper>
        <AddIcon />
      </Styled.IconWrapper>

      <Styled.Text>Добавить участок</Styled.Text>
    </Styled.Wrapper>
  );
};

export default AddNewButton;
