import { makeAutoObservable } from 'mobx';

import { provide } from '../../../../../../../shared/utils/IoC';

@provide.singleton()
class BottomWidgetStore {
  private _isOpened = false;
  private animationMs = 150;

  constructor() {
    makeAutoObservable(this);
  }

  public get cssTransitionTime() {
    return `${this.animationMs / 1000}s`;
  }

  public get isOpened() {
    return this._isOpened;
  }

  public open(cb?: () => void) {
    this._isOpened = true;

    if (typeof cb === 'function') {
      setTimeout(cb, this.animationMs);
    }
  }

  public close(cb?: () => void) {
    this._isOpened = false;

    if (typeof cb === 'function') {
      setTimeout(cb, this.animationMs);
    }
  }
}

export default BottomWidgetStore;
