import React, { FC, useState, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '../../../../../../../shared/utils/IoC';
import { FieldsStore } from '../../../../../../../shared/mobx/stores';
import { SwiperSlider } from '../../../../../../../shared/components/SwiperSlider/SwiperSlider';
import FieldSeasonsStore from '../../../../modules/FieldSeasons/mobx/stores/FieldSeasons.store';
import { EMapModeId } from '../../../../../../constants/MapModeId.enum';

import {
  CulturesListWrapper,
  CultureWrapper,
  CultureColor,
  CultureLabel,
} from './AvailableCultures.styles';

interface IProps {
  mapModeId?: EMapModeId;
}

export const AvailableCultures: FC<IProps> = observer(({ mapModeId }) => {
  const fieldsStore = useStore(FieldsStore);
  const fieldSeasonsStore = useStore(FieldSeasonsStore);

  // TODO Завести отдельный дата-слой под работу легенды

  const cultureZones = useMemo(() => {
    if (mapModeId === EMapModeId.FieldSeasons) {
      let parsedCultureList = fieldSeasonsStore.fieldSeasonsList;

      if (fieldSeasonsStore?.selectedSeason?.season?.year) {
        parsedCultureList = fieldSeasonsStore?.fieldSeasonsList?.filter(
          season => season?.season?.year === fieldSeasonsStore?.selectedSeason?.season?.year
        );
      }

      return parsedCultureList
        ?.flatMap(season => season?.fieldVersion?.cultureZones?.flatMap(cz => cz))
        ?.filter(Boolean);
    }

    return [...fieldsStore.fieldsCultureZonesList.filter(Boolean)];
  }, [
    fieldsStore.fieldsCultureZonesList,
    fieldSeasonsStore.fieldSeasonsList,
    mapModeId,
    fieldSeasonsStore?.selectedSeason?.season?.year,
  ]);

  const [cultures, setCultures] = useState([]);

  useEffect(() => {
    const tmpCultures = [];
    const checkNames = [];

    cultureZones.forEach(({ culture }) => {
      if (culture?.name && !checkNames.includes(culture?.name)) {
        checkNames.push(culture.name);
        tmpCultures.push(culture);
      }
    });

    setCultures(tmpCultures.sort((a, b) => (a.name > b.name ? 1 : -1)));
  }, [cultureZones]);

  if (!cultures.length) {
    return null;
  }

  return (
    <CulturesListWrapper data-test-id="map-legend-culture-wrapper">
      <SwiperSlider>
        {cultures.map((c, i) => (
          <CultureWrapper data-test-id={`map-legend-culture-item-${i}`} key={c.id}>
            <CultureColor color={c.attrs.assistanceColorLegend} />
            <CultureLabel data-test-id={`map-legend-culture-item-${i}-name`}>{c.name}</CultureLabel>
          </CultureWrapper>
        ))}
      </SwiperSlider>
    </CulturesListWrapper>
  );
});
