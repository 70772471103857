import styled from 'styled-components';

import { Colors } from '../../../../../../../../../shared/constans/colors';
import { BorderRadius, FontSizes } from '../../../../../../../../../shared/constans/sizes';

const Wrapper = styled.div`
  display: block;
  border-radius: ${BorderRadius.default};
  position: relative;
  background: ${Colors.white};
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const CulturePic = styled.img.attrs(props => ({
  src: props.src,
}))`
  ${({ src }) => {
    if (!src) return `display: none;`;
  }}
  display: none;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: rotateY(180deg);
  z-index: 0;
  width: 120px;
  max-width: 100%;
  border-bottom-left-radius: 16px;
  @media (min-width: 1366px) {
    display: flex;
  }
`;

const Content = styled.div`
  padding: 24px;
  @media (max-width: 1024px) {
    padding: 16px 16px 20px;
  }
  position: relative;
  z-index: 1;
`;

const FieldNameWrapper = styled.div`
  height: 56px;
  padding: 0 16px 0 24px;

  display: flex;
  align-items: center;
  border-bottom: 1px solid ${Colors.lightnessGray};

  @media (max-width: 1024px) {
    padding: 0 2px 0 16px;
  }

  @media (max-width: 1365px) {
    height: 52px;
  }
`;

const SquareLabel = styled.div`
  ${FontSizes.medium}
  @media (max-width: 1024px) {
    ${FontSizes.default}
    margin-bottom: 4px;
  }
`;

const CulturesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  height: 40px;
  max-width: 192px;
`;

const CulturesLabel = styled.div`
  ${FontSizes.medium}
  @media (max-width: 1024px) {
    ${FontSizes.default}
    margin-bottom: 4px;
  }
  max-height: 40px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 192px;
`;

export default {
  Wrapper,
  Content,
  SquareLabel,
  CulturesWrapper,
  CulturesLabel,
  CulturePic,
  FieldNameWrapper,
};
