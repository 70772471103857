import { Colors, ESpaceSize } from '@farmlink/farmik-ui';
import styled from 'styled-components';

import { ReactComponent as BinIcon } from '../../../../../../../../../../../../static/basket.svg';
import { ReactComponent as MenuSvg } from '../../../../../../../../../../../../../../static/menu.svg';

const PopupWrapper = styled('div')({
  borderRadius: ESpaceSize.L,
  overflow: 'hidden',
});

const PopupItem = styled('div')({
  padding: '12px 16px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  width: '100%',

  '&:hover': {
    backgroundColor: Colors.hoverGray,
  },

  ':not(:last-child)': {
    borderBottom: `1px solid ${Colors.borderGrey}`,
  },
});

interface IProps {
  $color?: string;
}

const Text = styled('h6')<IProps>(
  {
    margin: 0,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    marginLeft: ESpaceSize.S,
  },
  ({ $color }) => ({
    color: $color ? $color : Colors.generalBlack,
  })
);

const DeleteIcon = styled(BinIcon)({
  '*': {
    fill: Colors.red,
  },
});

const MenuIcon = styled(MenuSvg)({
  '&:hover': {
    path: {
      fill: Colors.green,
    },
  },
});

export default {
  PopupWrapper,
  PopupItem,
  Text,
  DeleteIcon,
  MenuIcon,
};
