import React, { FC, useCallback } from 'react';
import { Typography } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';

import { useDataTestIdV2 } from '../../../../../../../../../../../../../../../../../../../../../shared/features/utils/hooks/locators';
import { useStore } from '../../../../../../../../../../../../../../../../../../../../../shared/utils/IoC';
import { StoAttributeCoreStore as Store } from '../../../../../../mobx/stores';
import { StoAttributeCoreController as Controller } from '../../../../../../mobx/controllers';
import { useStoParams } from '../../../../../../../../../../../../../../../../../../hooks';
import { IStoAttributeOdzGroupPut as IOdzGroupPut } from '../../../../../../../../../../../../../../../../../../../../../../api/models/as-fields/new/sto';
import {
  StoAttributeOdzGroupContainer as Group,
  StoAttributeOdzDependencyContainer as Dependency,
} from '../../containers';
import { ButtonRemove } from '../../../../../../../../../../../../../../../../../../../../../shared/features/UI/new/ButtonRemove';

import Styled from './StoAttributeOdzDependencyGroup.styles';
interface IProps {
  group: IOdzGroupPut;
  isShowButtonRemove: boolean;
}

const StoAttributeOdzDependencyGroup: FC<IProps> = ({ isShowButtonRemove, group }) => {
  const store = useStore(Store);
  const controller = useStore(Controller);

  const params = useStoParams();

  const [firstDependency] = group.dependencyAttributes;

  const handleClickOnButtonRemove = useCallback((): void => {
    controller.removeOdzGroup(group.id, params);
  }, []);

  const getDataTestId = useDataTestIdV2('sto__attribute__odz__dependency__group');

  return (
    <Styled.Wrapper {...getDataTestId()}>
      <Styled.Container {...getDataTestId('dependency-container')}>
        <Styled.Header {...getDataTestId('dependency-header')}>
          <Styled.TitleWrapper>
            <Typography
              dataTestId={getDataTestId('dependency-title')['data-test-id']}
              variant={'bodyAccent'}
            >
              При показателях определяющего параметра:
            </Typography>

            {isShowButtonRemove ? (
              <ButtonRemove
                dataTestId={getDataTestId()['data-test-id']}
                onClick={handleClickOnButtonRemove}
              />
            ) : null}
          </Styled.TitleWrapper>

          <Typography
            dataTestId={getDataTestId('dependency-title')['data-test-id']}
            variant={'body'}
          >
            {firstDependency.systemData.attribute.attribute.name}
          </Typography>
        </Styled.Header>

        <Styled.Dependencies
          {...getDataTestId('dependencies')}
          $width={firstDependency?.numericValues ? '284px' : '1fr'}
        >
          <Dependency groupId={group.id} dependency={firstDependency} />
        </Styled.Dependencies>
      </Styled.Container>

      <Styled.Container {...getDataTestId('attribute-container')}>
        <Styled.Header {...getDataTestId('attribute-header')}>
          <Typography
            dataTestId={getDataTestId('attribute-title')['data-test-id']}
            variant={'bodyAccent'}
          >
            Область допустимых значений составит:
          </Typography>

          <Typography dataTestId={getDataTestId('attribute-name')['data-test-id']} variant={'body'}>
            {store.attribute.attribute.name}
          </Typography>
        </Styled.Header>

        <Group group={group} />
      </Styled.Container>
    </Styled.Wrapper>
  );
};

StoAttributeOdzDependencyGroup.displayName = 'StoAttributeOdzDependencyGroup';

export default observer(StoAttributeOdzDependencyGroup);
