import { Input } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { FC, useMemo } from 'react';

import { calculateArea } from '../../../../../../../../../shared/features/map/utils/helpers';
import { toFixedWithCeilBackEnd } from '../../../../../../../../../shared/utils/toFixedWithCeil';
import { IInputProps } from '../../types/CommonProps.types';

const AreaInput: FC<IInputProps> = ({ cultureZone }) => {
  // Этой переменной можно избежать. На данный момент бэк по какой-то причине возвращает area 0 для MultiPolygon
  const area = useMemo(() => {
    if (!cultureZone?.geometry) {
      return 0;
    }

    const polygonArea = calculateArea(cultureZone?.geometry);
    return toFixedWithCeilBackEnd(polygonArea);
  }, [cultureZone?.geometry]);

  return (
    <Input
      type={'text'}
      label={'Площадь, Га'}
      onChange={() => 1}
      value={area}
      blocked
      disabled={true}
      dataTestId="field-square"
    />
  );
};

export default observer(AreaInput);
