import { Geometry } from '@turf/turf';
import { makeAutoObservable } from 'mobx';
import { cloneDeep } from 'lodash';

import { CultureModel } from '../../../../../../../../api/models/culture.model';
import { CultureZone, Field } from '../../../../../../../../api/models/field.model';
import { CulturePolygon } from '../../../../../../../shared/features/map/utils/MapElements';
import { arrayToMap } from '../../../../../../../shared/utils/helpers/map/arrayToMap';
import { mapToArray } from '../../../../../../../shared/utils/helpers/map/mapToArray';
import { provide } from '../../../../../../../shared/utils/IoC';
import cutCultureZones from '../../utils/helpers/cutCultureZones';
import getEmptyCultureZoneModel from '../../utils/helpers/getEmptyCultureZoneModel';

/**
 * Данный стор хранит информацию о культурных зонах.
 * Все операции обрезки культурных зон выполняются в данном сторе.
 * После любой модификации КЗ в сторе, необходимо выполнить ререндер слоя на карте
 */
@provide.singleton()
class FieldCulturesStore {
  // Текущее поле
  private _activeField: Field | null = null;

  // Главная коллекция культурных зон. На её основе рендерятся полигоны
  private _cultureZonesCollection = new Map<string, CultureZone>();

  // Используется для отката изменений
  private _cultureZonesSnapshotCollection = new Map<string, CultureZone>();

  // Хранит id редактируемой культурной зоны
  private _editableCultureZoneId: string | null = null;

  // Хранит id выбранной культурной зоны (используется для подсветки в списке)
  private _selectedCultureZoneId: string | null = null;

  private _isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  get activeField() {
    if (this._activeField) {
      this._activeField.cultureZones = mapToArray(this._cultureZonesCollection);
    }

    return this._activeField;
  }

  /**
   * Сетает поле и извлекает его культурные зоны в отдельную коллекцию
   * Если культурных зон нет, то добавляет одну пустую КЗ с геометрией поля-родителя
   */
  setActiveField(field: Field): Field | null {
    if (!field) {
      return null;
    }

    this._activeField = field;

    const emptyModel = getEmptyCultureZoneModel(field.geometry, field.area);
    const cultureZoneList = field.cultureZones?.length ? field.cultureZones : [emptyModel];

    cultureZoneList.forEach(zone => {
      this._cultureZonesCollection.set(zone.id, zone);
    });

    return this.activeField;
  }

  get culturesZonesList(): CultureZone[] {
    return mapToArray(this._cultureZonesCollection);
  }

  get editableCultureZone() {
    const id = this._editableCultureZoneId;
    return this._cultureZonesCollection.get(id) ?? null;
  }

  get selectedCultureZone() {
    const id = this._selectedCultureZoneId;
    return this._cultureZonesCollection.get(id) ?? null;
  }

  get isLoading() {
    return this._isLoading;
  }

  set isLoading(value) {
    this._isLoading = value;
  }

  setEditableCultureZone(cultureZone: CultureZone) {
    this._editableCultureZoneId = cultureZone?.id ?? null;
  }

  setSelectedCultureZone(cultureZone: CultureZone) {
    this._selectedCultureZoneId = cultureZone?.id ?? null;
  }

  getCultureZone(id: string) {
    return this._cultureZonesCollection.get(id);
  }

  /**
   * Добавляет новую КЗ в коллекцию. Вычитает новую геометрию из каждого полигона. (Изменяет геометрию).
   * После любой модификации КЗ в сторе, необходимо произвести ререндер слоя на карте
   */
  addCultureZone = (geometry: Geometry): CultureZone => {
    const cutZonesList = cutCultureZones(geometry, this.culturesZonesList);
    const emptyZone = getEmptyCultureZoneModel(geometry) as CultureZone;

    const newCultureZones = [...cutZonesList, emptyZone];

    this._cultureZonesCollection = arrayToMap(newCultureZones, 'id');

    return emptyZone;
  };

  removeCultureZone = (zone: CultureZone) => {
    this._cultureZonesCollection.delete(zone.id);

    if (!this._cultureZonesCollection.size && this.activeField) {
      const { geometry, area } = this.activeField;
      const model = getEmptyCultureZoneModel(geometry, area) as CultureZone;

      this._cultureZonesCollection.set(model.id, model);
    }
  };

  updateCultureOfZone = (zoneId: string, culture: CultureModel) => {
    const cultureZone = this._cultureZonesCollection.get(zoneId);
    if (!cultureZone) {
      return;
    }

    const newCulture = {
      id: culture.id,
      name: culture.name,
      attrs: culture.attrs,
    } as any;

    this._cultureZonesCollection.set(zoneId, { ...cultureZone, culture: newCulture });
  };

  updateCultureZoneValue<K extends keyof CultureZone>(id: string, key: K, value: CultureZone[K]) {
    const cultureZone = this._cultureZonesCollection.get(id);
    if (!cultureZone) {
      return;
    }

    this._cultureZonesCollection.set(id, {
      ...cultureZone,
      [key]: value,
    });
  }

  updateCultureZoneGeometry(culturePolygon: CulturePolygon) {
    const zoneId = culturePolygon.dataModel.id;
    const { geometry, area } = culturePolygon.getInfo();

    this.updateCultureZoneValue(zoneId, 'geometry', geometry);
    this.updateCultureZoneValue(zoneId, 'area', area);
  }

  /**
   * Делает "снимок" текущих КЗ
   */
  makeCultureZonesSnapshot = () => {
    this._cultureZonesSnapshotCollection = new Map(cloneDeep(this._cultureZonesCollection));
  };

  /**
   * Сбрасывает текущие КЗ до состояния cultureZoneSnapshot.
   * После любой модификации КЗ в сторе, необходимо произвести ререндер слоя на карте
   */
  resetCultureZonesToSnapshot = () => {
    this._cultureZonesCollection = new Map(cloneDeep(this._cultureZonesSnapshotCollection));
  };

  clear = () => {
    this._activeField = null;
    this._editableCultureZoneId = null;
    this._selectedCultureZoneId = null;
    this._cultureZonesCollection.clear();
  };
}

export default FieldCulturesStore;
