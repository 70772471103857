import React, { FC } from 'react';

import { Field } from '../../../../../../../../../../../../api/models/field.model';
import OverflowTextTooltip from '../../../../../../../../../../../shared/components/ExtendedAutoTooltip/OverflowTextTooltip';

import Styled from './FieldName.styles';

interface IProps {
  field: Field;
}

const FieldName: FC<IProps> = ({ field }) => {
  return (
    <OverflowTextTooltip content={field?.name ?? ''}>
      {ref => <Styled.Label ref={ref} data-test-id="widget-field-info-name" />}
    </OverflowTextTooltip>
  );
};

export default FieldName;
