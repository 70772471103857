import { Colors, ESpaceSize } from '@farmlink/farmik-ui';
import styled from 'styled-components';

import SharedStyles from '../../shared/styles/Shared.styles';

const Wrapper = styled('div')({
  padding: '12px',
  display: 'flex',
  alignItems: 'center',
  marginBottom: ESpaceSize.XS,
  backgroundColor: Colors.generalWhite,
  borderBottom: `1px solid ${Colors.hoverGray}`,
});

const Title = styled('h3')({
  margin: 0,
  fontSize: 16,
  fontWeight: 600,
  color: Colors.generalBlack,

  ...SharedStyles.EllipsisTextCSS,
});

const IconWrapper = styled('div')({
  display: 'flex',
  cursor: 'pointer',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: ESpaceSize['2XL'],
  height: ESpaceSize['2XL'],

  ':hover': {
    backgroundColor: Colors.hoverGray,
  },
});

export default {
  Wrapper,
  Title,
  IconWrapper,
};
