import styled from 'styled-components';

import { FontSizes } from '../../../../../../../../../../../shared/constans/sizes';

const Label = styled.p`
  ${FontSizes.largeBold}
  white-space: nowrap;
  margin: 0;
  width: 100%;
  overflow: hidden;
`;

export default {
  Label,
};
