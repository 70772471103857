import moment from 'moment';
import { ReactNode } from 'react';

import { FieldsStore } from '../../../../../../../../shared/mobx/stores';
import { lazyInject, provide } from '../../../../../../../../shared/utils/IoC';
import { ISelectOption } from '../../../../../../../../../types/selectOption';
import { TaskStatuses } from '../../../../../models/TaskStatuses/TaskStatuses.enum';
import { taskLabels } from '../../../../../stores/tasks.store';
import { ITasksFilters } from '../../../models';
import { TTasksFiltersFilterConfig as TFilterConfig } from '../../../types/configs';
import { SeasonsStore } from '../../../../../../../stores/seasons.store';
import {
  TasksFiltersActionButton as ActionButton,
  TasksFiltersUploadButton,
} from '../../../containers/header';
import {
  ITableFiltersBuilderConfig,
  ITableFiltersBuilderPresetConfig,
} from '../../../../../../../../shared/features/TableFiltersBuilder/models/configs';
import { ITableFiltersBuilderValue } from '../../../../../../../../shared/features/TableFiltersBuilder/models/data';
import { OrganizationsStore } from '../../../../../../../stores/organizations.store';

@provide.transient()
class TasksFiltersConfigsService {
  @lazyInject(SeasonsStore)
  protected seasonsStore: SeasonsStore;

  @lazyInject(FieldsStore)
  protected fieldsStore: FieldsStore;

  @lazyInject(OrganizationsStore)
  protected organizationsStore: OrganizationsStore;

  createConfig = (ContextualHelpIcon: ReactNode): ITableFiltersBuilderConfig<ITasksFilters> => {
    const presetList = this.createPresetList();
    const filterList = this.createFilterList();

    const isOrgMy = this.organizationsStore.selectedOrganizationId === 'my';
    const isNoSeason = !this.seasonsStore.selectedSeason || this.seasonsStore.selectedSeason === '';
    const isNoFields = !this.fieldsStore.hasFields;

    if (isNoSeason || isNoFields) {
      return {
        id: 'tasks',
        headerConfig: {
          title: 'Задачи',
          ContextualHelpIcon,
          actionButtonConfig: {},
        },
      };
    }

    return {
      id: 'tasks',
      headerConfig: {
        title: 'Задачи',
        ContextualHelpIcon,
        iconsConfig: {
          iconList: !isOrgMy
            ? [{ id: 'upload-button', element: <TasksFiltersUploadButton /> }]
            : [],
          isShowToggleFilters: Boolean(!presetList.length && filterList.length),
        },
        actionButtonConfig: {
          customRenderConfig: {
            renderButton: () => <ActionButton />,
          },
        },
      },
      presetConfigList: presetList,
      filterConfigList: filterList,
    };
  };

  createPresetList = (): ITableFiltersBuilderPresetConfig<ITasksFilters>[] => {
    const isCurrentSeason = this.seasonsStore.selectedSeassonData?.isCurrent;

    if (!isCurrentSeason) {
      return [];
    }

    const weekPlan = this.createWeekPlanPreset();
    const weekActual = this.createWeekActualPreset();
    const today = this.createTodayPreset();

    return [weekPlan, weekActual, today];
  };

  createWeekPlanPreset = (): ITableFiltersBuilderPresetConfig<ITasksFilters> => {
    const statuses = this.createWeekPlanPresetRelatedStatuses();
    const dates = this.createWeekPlanPresetRelatedDates();

    const weekPlan: ITableFiltersBuilderPresetConfig<ITasksFilters> = {
      id: 'week-plan',
      name: 'План на неделю',
      relatedFilterValueList: [statuses, ...dates],
      isDefault: true,
    };

    return weekPlan;
  };

  createWeekPlanPresetRelatedStatuses = (): ITableFiltersBuilderValue<ITasksFilters> => {
    const statusOptionList: ISelectOption[] = [
      {
        value: TaskStatuses.New,
        label: taskLabels[TaskStatuses.New],
      },
      {
        value: TaskStatuses.InWork,
        label: taskLabels[TaskStatuses.InWork],
      },
    ];

    return { filterId: 'status', type: 'select', selectOptionList: statusOptionList };
  };

  createWeekPlanPresetRelatedDates = (): ITableFiltersBuilderValue<ITasksFilters>[] => {
    const startDate = moment().format('YYYY-MM-DD');
    const endDate = moment(startDate).add(6, 'days').format('YYYY-MM-DD');

    return [
      {
        filterId: 'planDateFrom',
        type: 'date-range',
        dateValue: startDate,
      },
      {
        filterId: 'planDateTo',
        type: 'date-range',
        dateValue: endDate,
        isHidden: true,
      },
    ];
  };

  createWeekActualPreset = (): ITableFiltersBuilderPresetConfig => {
    const statuses = this.createWeekActualPresetRelatedStatuses();
    const dates = this.createWeekActualPresetRelatedDates();

    const weekPlan: ITableFiltersBuilderPresetConfig = {
      id: 'week-actual',
      name: 'Факт за неделю',
      relatedFilterValueList: [statuses, ...dates],
    };

    return weekPlan;
  };

  createWeekActualPresetRelatedStatuses = (): ITableFiltersBuilderValue<ITasksFilters> => {
    const statusOptionList: ISelectOption[] = [
      {
        value: TaskStatuses.Completed,
        label: taskLabels[TaskStatuses.Completed],
      },
      {
        value: TaskStatuses.Canceled,
        label: taskLabels[TaskStatuses.Canceled],
      },
    ];

    return { filterId: 'status', type: 'select', selectOptionList: statusOptionList };
  };

  createWeekActualPresetRelatedDates = (): ITableFiltersBuilderValue<ITasksFilters>[] => {
    const endDate = moment().format('YYYY-MM-DD');
    const startDate = moment(endDate).subtract(6, 'days').format('YYYY-MM-DD');

    return [
      {
        filterId: 'planDateFrom',
        type: 'date-range',
        dateValue: startDate,
      },
      {
        filterId: 'planDateTo',
        type: 'date-range',
        dateValue: endDate,
        isHidden: true,
      },
    ];
  };

  createTodayPreset = (): ITableFiltersBuilderPresetConfig => {
    const dates = this.createTodayPresetRelatedDates();

    const weekPlan: ITableFiltersBuilderPresetConfig = {
      id: 'today',
      name: 'Сегодня',
      relatedFilterValueList: [...dates],
    };

    return weekPlan;
  };

  createTodayPresetRelatedDates = (): ITableFiltersBuilderValue<ITasksFilters>[] => {
    const startDate = moment().format('YYYY-MM-DD');

    return [
      {
        filterId: 'planDateFrom',
        type: 'date-range',
        dateValue: startDate,
      },
      {
        filterId: 'planDateTo',
        type: 'date-range',
        dateValue: startDate,
        isHidden: true,
      },
    ];
  };

  createFilterList = (): TFilterConfig[] => [
    this.createCulturesFilter(),
    this.createFieldsFilter(),
    this.createOperationTypesFilter(),
    this.createAssigneesFilter(),
    this.createStatusesFilter(),
    this.createPlanDateFromFilter(),
    this.createPlanDateToFilter(),
    this.createIsExpiredFilter(),
  ];

  createCulturesFilter = (): TFilterConfig => {
    return { id: 'cultureId', name: 'Культура', type: 'select' };
  };

  createFieldsFilter = (): TFilterConfig => {
    return {
      id: 'fieldId',
      name: 'Поля',
      type: 'select',
      selectOptions: { isDisplayAllTagsAsOne: true, useCustomLabel: true, customName: 'Поле' },
    };
  };

  createOperationTypesFilter = (): TFilterConfig => {
    return { id: 'operationTypeId', name: 'Операции', type: 'select' };
  };

  createAssigneesFilter = (): TFilterConfig => {
    return {
      id: 'assigneeId',
      name: 'Исполнитель',
      type: 'select',
    };
  };

  createStatusesFilter = (): TFilterConfig => {
    return { id: 'status', name: 'Статус', type: 'select' };
  };

  createPlanDateFromFilter = (): TFilterConfig => {
    const minDate = this.seasonsStore.selectedSeassonData?.startDate
      ? moment(this.seasonsStore.selectedSeassonData?.startDate).toDate()
      : null;

    const maxDate = this.seasonsStore.selectedSeassonData?.endDate
      ? moment(this.seasonsStore.selectedSeassonData?.endDate).toDate()
      : null;

    const filter: TFilterConfig = {
      id: 'planDateFrom',
      name: 'Укажите дату',
      type: 'date-range',
      dateRangeOptions: {
        relatedEndDateFilterId: 'planDateTo',
      },
    };

    if (minDate && maxDate) {
      filter.dateRangeOptions.dateRange = { minDate, maxDate };
    }

    if (minDate) {
      filter.defaultValue = {
        filterId: 'planDateFrom',
        type: 'date-range',
        dateValue: moment(minDate).format('YYYY-MM-DD'),
      };
    }

    return filter;
  };

  createPlanDateToFilter = (): TFilterConfig => {
    const maxDate = this.seasonsStore.selectedSeassonData?.endDate
      ? moment(this.seasonsStore.selectedSeassonData?.endDate).toDate()
      : null;

    const filter: TFilterConfig = {
      id: 'planDateTo',
      name: 'Укажите дату',
      type: 'date-range',
      isHidden: true,
    };

    if (maxDate) {
      filter.defaultValue = {
        filterId: 'planDateTo',
        type: 'date-range',
        dateValue: moment(maxDate).format('YYYY-MM-DD'),
        isHidden: true,
      };
    }

    return filter;
  };

  createIsExpiredFilter = (): TFilterConfig => {
    return {
      id: 'expired',
      name: 'Только просроченные',
      type: 'boolean',
      booleanOptions: { isNullOnFalse: true },
    };
  };
}

export default TasksFiltersConfigsService;
