import React, { FC } from 'react';

import {
  LegendWrapper,
  ColorBlocksWrapper,
  ColorBlock,
  LabelsBlock,
  SetWrapper,
  WeatherColorsWrapper,
  Label,
} from './StaticLegend.styles';

const StaticLegendColors = [
  '#C03333',
  '#FF3333',
  '#FE7C33',
  '#FFCC03',
  '#FFE41C',
  '#FEF913',
  '#EDEA48',
  '#E9F96A',
  '#C0F873',
  '#A8E74B',
  '#8FD93C',
  '#7FC23A',
  '#6AAE38',
  '#668E25',
  '#407520',
  '#2A5F17',
];

const WeatherColors = [
  { label: 'Снег', color: '#DEDEDE' },
  { label: 'Облака', color: '#B2BAB6' },
  { label: 'Вода', color: '#1E88E5' },
  { label: 'Земля', color: '#603D20' },
];

export const StaticLegend: FC = () => {
  return (
    <LegendWrapper data-test-id="static-legend-wrapper">
      <SetWrapper>
        <ColorBlocksWrapper>
          {StaticLegendColors.map(color => (
            <ColorBlock key={color} color={color} />
          ))}
        </ColorBlocksWrapper>
        <LabelsBlock>
          <Label>0</Label>
          <Label>0.5</Label>
          <Label>1</Label>
        </LabelsBlock>
      </SetWrapper>
      <SetWrapper>
        <WeatherColorsWrapper>
          {WeatherColors.map(({ color }) => (
            <ColorBlock key={color} color={color} />
          ))}
        </WeatherColorsWrapper>
        <LabelsBlock>
          {WeatherColors.map(({ label }) => (
            <Label key={label}>{label}</Label>
          ))}
        </LabelsBlock>
      </SetWrapper>
    </LegendWrapper>
  );
};
