import { Link } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';

import { IndexModel } from '../../../../../../../../../../../../api/models/indices.model';
import { ReactComponent as AlertIcon } from '../../../../../../../../../../../shared/assets/images/allert.svg';
import { ReactComponent as CloundIcon } from '../../../../../../../../../../../shared/assets/images/cloud.svg';
import { SwiperSlider } from '../../../../../../../../../../../shared/components/SwiperSlider/SwiperSlider';
import { formatDateToNameString } from '../../../../../../../../../../../shared/utils/formatDateToNameString';
import { useStore } from '../../../../../../../../../../../shared/utils/IoC';
import { FieldsMapCoreStore } from '../../../../../../../../mobx';
import { IndicesFillStrategy, IndicesStore } from '../../mobx';

import Styled from './style';

interface IProps {
  indicesFillStrategy: IndicesFillStrategy;
}

export const IndicesTimeline = observer(({ indicesFillStrategy }: IProps) => {
  // Сторы
  const indicesStore = useStore(IndicesStore);
  const sharedFieldsStore = useStore(FieldsMapCoreStore);

  const isActiveIndexItem = (item: IndexModel) => {
    return indicesStore.selectedIndex?.publicId === item.publicId;
  };

  const handleDateClick = (index: IndexModel) => {
    if ('fillIndex' in indicesFillStrategy) {
      indicesFillStrategy.fillIndex(index);
    }
  };

  const handleRefreshClick = () => {
    if ('fetchIndices' in indicesFillStrategy) {
      indicesFillStrategy.fetchIndices();
    }
  };

  if (!indicesStore.hasIndices) {
    return (
      <Styled.TimeLineWrapper data-test-id="map-timeline-wrapper">
        <Styled.TimelineInfoMessage data-test-id="map-timeline-no-data">
          <AlertIcon />
          <span>Мы уже начали обрабатывать снимки, но нам нужно еще немного времени</span>
          <Link
            underlined
            color="default"
            label="Обновить"
            onClick={handleRefreshClick}
            data-test-id="map-timeline-no-data-reload"
          />
        </Styled.TimelineInfoMessage>
      </Styled.TimeLineWrapper>
    );
  }

  return (
    <Styled.TimeLineWrapper data-test-id="map-timeline-wrapper">
      <Styled.TimelineItemsWrapper>
        <SwiperSlider
          scrollPosition="right"
          selectedFieldId={sharedFieldsStore.selectedField?.id}
          indices={indicesStore.indices}
        >
          {indicesStore.indices.map((item, i) =>
            item.status === 'COMPLETED' ? (
              <Styled.TimelineItem
                key={item.publicId}
                active={isActiveIndexItem(item)}
                onClick={() => handleDateClick(item)}
                data-test-id={`map-timeline-item-${i}`}
              >
                {formatDateToNameString(item.toDate)}
              </Styled.TimelineItem>
            ) : (
              <Styled.TimelineIconItem>
                <CloundIcon key={item.publicId} />
              </Styled.TimelineIconItem>
            )
          )}
        </SwiperSlider>
      </Styled.TimelineItemsWrapper>
    </Styled.TimeLineWrapper>
  );
});
