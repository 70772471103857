import { CSSProperties, FC, useEffect } from 'react';

import { useStore } from '../../../../utils/IoC';
import { IMapGlobalConfig as IGlobalConfig } from '../../models';
import { MapCoreController } from '../../modules';
import { Overlay } from '../Overlay';

import Styled from './Map.styles';
import './style.css';

interface IProps {
  globalConfig: IGlobalConfig;
  style?: CSSProperties;
}

const Map: FC<IProps> = ({ globalConfig, style }) => {
  const mapCoreController = useStore(MapCoreController);

  useEffect(() => {
    mapCoreController.initialize(globalConfig);

    return () => {
      mapCoreController.destroy();
    };
  }, []);

  return (
    <Styled.Composition>
      <Styled.Wrapper id={globalConfig.mapKey} data-test-id={globalConfig.mapKey} $style={style} />
      <Overlay config={globalConfig} />
    </Styled.Composition>
  );
};

Map.displayName = 'Map';

export default Map;
