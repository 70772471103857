import { AutoTooltip } from '@farmlink/farmik-ui';
import { IAutoTooltipProps } from '@farmlink/farmik-ui/dist/AutoTooltip/AutoTooltip';
import {
  FunctionComponentElement,
  MutableRefObject,
  useCallback,
  useEffect,
  useState,
  useRef,
} from 'react';

import { useResizeObserver } from '../../hooks';

interface IProps<T> extends Omit<IAutoTooltipProps, 'content' | 'children' | 'disabled'> {
  content: string;
  children: (ref: MutableRefObject<T>) => FunctionComponentElement<any>;
}

/**
 * !Возможно этот компонент стоит вынести в farmik-ui
 *
 * Надстройка над AutoTooltip из фармика для работы с overflow text.
 * В данной версии компонента нет необходимости вручную контролировать состояние overflow текста и выставлять disabled prop
 * @param props.content - Контент тултипа/элемента. Для элемента можно не указывать контент, он подставится автоматически через ref.innerText (Но можно и указать :))
 * @param props.children - функция c ref аргументом. Должна возвращать валидный JSX
 */
function OverflowTextTooltip<T extends HTMLElement = any>(props: IProps<T>) {
  const { content, children, ...rest } = props;

  const ref = useRef<T>(null);

  const [isOverflow, setIsOverflow] = useState<boolean>(false);

  const handleResizeElement = useCallback((target: T) => {
    setIsOverflow(target.offsetWidth < target.scrollWidth);
  }, []);

  useResizeObserver(handleResizeElement, ref);

  useEffect(() => {
    if (ref.current) {
      ref.current.innerText = content;

      setIsOverflow(ref.current?.offsetWidth < ref.current?.scrollWidth);
    }
  }, [content]);

  return (
    <AutoTooltip content={content} disabled={!isOverflow} {...rest}>
      {children(ref)}
    </AutoTooltip>
  );
}

export default OverflowTextTooltip;
