import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { FieldsStore } from '../../../../../shared/mobx/stores';
import { useStore } from '../../../../../shared/utils/IoC';
import { EFieldFill } from '../../../../constants/FieldFill.enum';
import { FieldFillStore } from '../TopPanelControls/components/FieldFillControls/mobx';
import { EMapModeId } from '../../../../constants/MapModeId.enum';

import { StaticLegend } from './components/StaticLegend/StaticLegend';
import { AvailableCultures } from './components/AvailableCultures/AvailableCultures';

interface IProps {
  mapModeId?: EMapModeId;
}

const LegendControls: FC<IProps> = ({ mapModeId }) => {
  // Сторы
  const { fieldFillValue } = useStore(FieldFillStore);
  const fieldsStore = useStore(FieldsStore);

  const isSelectedCultureFill = fieldFillValue === EFieldFill.Cultures;
  const isSelectedNdviOrMsaviFill = [EFieldFill.Msavi, EFieldFill.Ndvi].includes(fieldFillValue);

  if (isSelectedNdviOrMsaviFill && fieldsStore.hasFields) {
    return <StaticLegend />;
  }

  if (isSelectedCultureFill) {
    return <AvailableCultures mapModeId={mapModeId} />;
  }

  return null;
};

export default observer(LegendControls);
