import { observer } from 'mobx-react';
import { FC } from 'react';

import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { StoFieldsTabStore } from '../../mobx/stores';
import { ScrollableContainer } from '../../../../../../../../../shared/containers';

import { FieldsTabListingItem, NoFieldsPlaceholder, SkeletonFieldList } from './components';

const FieldsTabListing: FC<{ isBlocked?: boolean }> = ({ isBlocked }) => {
  const store = useStore(StoFieldsTabStore);

  const displayList = store?.potentialCultureZoneListToDisplay;
  const { isFieldsFetchingCompleted } = store;

  const getIsLast = (index: number) => displayList?.length - 1 === index;
  const maxHeight = displayList?.length >= 10 ? 469 : displayList?.length * 47;

  const isDisplayNoFieldsPlaceholder =
    displayList?.length === 0 && store.czIdToPotentialCultureZones?.size > 0;

  const renderRow = (fieldId: string, index: number) => (
    <FieldsTabListingItem
      key={fieldId}
      isBlocked={isBlocked}
      fieldId={fieldId}
      isLast={getIsLast(index)}
    />
  );

  if (!isFieldsFetchingCompleted) {
    return (
      <ScrollableContainer maxHeight="235px" dataTestId="sto-field-skeleton-list">
        <SkeletonFieldList listLength={5} />
      </ScrollableContainer>
    );
  }

  if (isDisplayNoFieldsPlaceholder) {
    return <NoFieldsPlaceholder />;
  }

  return (
    <ScrollableContainer
      maxHeight={`${maxHeight}px`}
      isDisabled={displayList?.length <= 10}
      dataTestId="sto-field-list"
    >
      {displayList.map((item, index) => renderRow(item.id, index))}
    </ScrollableContainer>
  );
};

export default observer(FieldsTabListing);
