import { FC } from 'react';

import { IMapGlobalConfig } from '../../models';

import Styled from './Overlay.styles';
import { Indices } from './components/';
import { Locate, Zoom } from './components/Controls';

interface IOverlayProps {
  config: IMapGlobalConfig;
}

const Overlay: FC<IOverlayProps> = ({ config }) => {
  const isDisplayIndices = config?.fieldIndicesOptions;
  const isDisplayLocate = config?.displayLocate;

  return (
    <Styled.Overlay>
      {isDisplayIndices && (
        <Styled.Topline>
          <Indices />
        </Styled.Topline>
      )}

      <Styled.RightAside>
        <Styled.RightAsideWrapper>
          <Zoom />

          {isDisplayLocate && <Locate />}
        </Styled.RightAsideWrapper>
      </Styled.RightAside>
    </Styled.Overlay>
  );
};

export default Overlay;
