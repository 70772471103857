import { observer } from 'mobx-react';
import React, { FC, ReactElement } from 'react';

import { GoogleSearch } from '../../../../../shared/components/GoogleSearch';
import { useStore } from '../../../../../shared/utils/IoC';
import { FieldsMapCoreStore } from '../../mobx';

import { FieldFillControls } from './components/FieldFillControls/FieldFillControls';
import { FieldFillStore } from './components/FieldFillControls/mobx';
import Styled from './TopPanelControls.styles';
import { VALUES_TO_HIDE_THE_SEARCH_BAR } from './utils';

interface IProps {
  createControls?: ReactElement;
}

/**
 * Переделать в будущих релизах
 */
export const TopPanelControls: FC<IProps> = observer(({ createControls }) => {
  // Сторы
  const fieldFillStore = useStore(FieldFillStore);
  const sharedFieldsStore = useStore(FieldsMapCoreStore);

  const showGoogleSearch = () => {
    const { selectedField } = sharedFieldsStore;
    const { fieldFillValue } = fieldFillStore;

    const isHideValue = VALUES_TO_HIDE_THE_SEARCH_BAR.includes(fieldFillValue);

    return !(selectedField && isHideValue);
  };

  return (
    <Styled.ControlPanelWrapper>
      {createControls}
      {showGoogleSearch() && (
        <Styled.ControlPanelItem isMarginRight>
          <GoogleSearch />
        </Styled.ControlPanelItem>
      )}

      <FieldFillControls />
    </Styled.ControlPanelWrapper>
  );
});
