import { useEffect, useRef, useState } from 'react';

import { FOCUSED_MARKER_CLASS } from '../../../../../constants/map/map.constants';
import { useStore } from '../../../../../utils/IoC';
import { useMapEvents } from '../../../hooks';
import { IMapVertexInfo } from '../../../models';
import { MapCoreStore } from '../../../modules';

function useSelectedVertex(): IMapVertexInfo | null {
  const mapCoreStore = useStore(MapCoreStore);

  const vertexRef = useRef<IMapVertexInfo | null>(null);
  const [vertex, setVertex] = useState<IMapVertexInfo | null>(null);

  const events = useMapEvents();

  /**
   * Сетает focused-marker класс выбранному верексу. Удаляет класс для предыдущего выбранного вертекса.
   * Вызывается на события: onVertexDrag, onVertexClick
   */
  const selectVertex = (info: IMapVertexInfo) => {
    if (!info.marker) {
      return;
    }

    if (vertexRef.current) {
      toggleFocusedClass(vertexRef.current, 'remove');
    }

    vertexRef.current = info;
    setVertex(info);

    toggleFocusedClass(info, 'add');
  };

  /**
   * Регистрирует подписку на контейнер со всеми вертексами.
   * Тригерит коллбэк на: изменение класса вертекса, добавление/удаление вертекса.
   * Коллбэк проверяет что выбранный вертекс имеет focused-marker class. Если класса нет - вертекс не выбран
   */
  const registerObserver = () => {
    const markerPaneContainer = mapCoreStore.instance.getPane('markerPane');

    const observer = new MutationObserver(() => {
      const markerEl = vertexRef.current?.marker.getElement();

      if (!markerEl?.classList.contains(FOCUSED_MARKER_CLASS)) {
        vertexRef.current = null;
        setVertex(null);
      }
    });

    observer.observe(markerPaneContainer, {
      subtree: true,
      attributeFilter: ['class'],
    });

    return observer;
  };

  useEffect(() => {
    const observer = registerObserver();

    events.onVertexClick(selectVertex);
    events.onMarkerDrag(selectVertex);

    events.onClickMap(() => {
      toggleFocusedClass(vertexRef.current, 'remove');
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return vertex;
}

function toggleFocusedClass(vertex: IMapVertexInfo | null, action: 'add' | 'remove') {
  const markerEl = vertex?.marker.getElement();

  if (markerEl) {
    markerEl.classList[action](FOCUSED_MARKER_CLASS);
  }
}

export default useSelectedVertex;
