import React, { FC } from 'react';
import { observer } from 'mobx-react';

import { CultureZone } from '../../../../../../../../../../../../api/models/field.model';
import { useDataTestIdV2 } from '../../../../../../../../../../../shared/features/utils/hooks/locators';
import { useStore } from '../../../../../../../../../../../shared/utils/IoC';
import {
  AccessRuleVisibility,
  ControlAccessRulesWrapper,
} from '../../../../../../../../../../components/AccessRulesWrapper/ControlAccessRulesWrapper';
import {
  FieldCulturesController as CulturesController,
  FieldCulturesStore as CulturesStore,
} from '../../../../../../mobx';

import CultureZoneCard from './component/CultureZoneCard/CultureZoneCard';
import AddNewButton from './component/AddNewButton/AddNewButton';
import ContextMenu from './component/ContextMenu/ContextMenu';
import Styled from './CulturesListContent.styles';

const CulturesListContent: FC = () => {
  // Сторы
  const culturesStore = useStore(CulturesStore);

  // Контроллеры
  const culturesController = useStore(CulturesController);

  const getDataTestId = useDataTestIdV2('field-season-card-cultures');

  const [firstCultureZone] = culturesStore.culturesZonesList;

  const isSelectedCard = (zone: CultureZone) => {
    return zone.id === culturesStore.selectedCultureZone?.id;
  };

  const hideContextDeleteButton = () => {
    if (firstCultureZone?.culture?.name || firstCultureZone?.experimentReady) {
      return false;
    }

    return culturesStore.activeField?.area === firstCultureZone?.area;
  };

  const handleClick = (zone: CultureZone) => {
    culturesController.highlightCultureZone(zone);
  };

  return (
    <React.Fragment>
      <Styled.ListWrapper {...getDataTestId('wrapper')}>
        {culturesStore.culturesZonesList.map(zone => (
          <CultureZoneCard
            key={zone.id}
            isSelected={isSelectedCard(zone)}
            cultureZone={zone}
            contextMenu={
              <ContextMenu
                cultureZone={zone}
                isInitial={culturesStore.culturesZonesList.length === 1}
                hideDeleteButton={hideContextDeleteButton()}
                onOpen={() => handleClick(zone)}
              />
            }
            onClick={handleClick}
          />
        ))}
      </Styled.ListWrapper>

      <ControlAccessRulesWrapper
        mode={AccessRuleVisibility.Hide}
        actionName="field.editCultureZone"
      >
        <AddNewButton dataTestId={getDataTestId('add-new-button')} />
      </ControlAccessRulesWrapper>
    </React.Fragment>
  );
};

export default observer(CulturesListContent);
