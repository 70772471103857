import { observer } from 'mobx-react';
import { FC, useEffect, useState } from 'react';
import { NewDropdown } from '@farmlink/farmik-ui';

import { CultureModel } from '../../../../../../../../../../api/models/culture.model';
import { FieldCulturesController as Controller } from '../../../../mobx';
import Service from '../../../../mobx/services/FieldSeasonsAPI.service';
import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { IInputProps } from '../../types/CommonProps.types';

const CultureSelect: FC<IInputProps> = ({ cultureZone }) => {
  // Контроллеры
  const controller = useStore(Controller);

  // Сервисы
  const service = useStore(Service);

  const [selectedId, setSelectedId] = useState<string>(cultureZone?.culture?.name);
  const [searchValue, setSearchValue] = useState<string>(cultureZone?.culture?.name);
  const [fetchedCultures, setFetchedCultures] = useState<CultureModel[]>([]);

  const isDisabled = !cultureZone;
  const isErrorState = !selectedId && !isDisabled;

  const fetchCultures = () => {
    service.fetchCulturesForSelect(searchValue).then(({ content }) => {
      setFetchedCultures(content);
    });
  };

  const getOptions = () => {
    return (fetchedCultures ?? []).map(culture => ({
      label: culture.name,
      value: culture.id,
    }));
  };

  const handleSelect = (cultureId: string) => {
    const selectedCulture = fetchedCultures.find(item => item.id === cultureId);

    if (isDisabled || !selectedCulture) {
      return;
    }

    controller.changeCultureOfZone(cultureZone, selectedCulture);

    setSelectedId(cultureId);
    setSearchValue(selectedCulture.name);
  };

  useEffect(fetchCultures, [searchValue]);

  return (
    <NewDropdown
      config={{
        field: {
          isDisabled,
          isRequired: true,
          type: {
            search: {
              options: {
                searchQueryHandler: setSearchValue,
              },
            },
          },
          defaultValue: {
            label: searchValue,
            value: searchValue,
          },
          placeholder: 'Введите культуру',
          onChange: handleSelect,
        },
        body: {
          optionList: getOptions(),
        },
        validation: {
          error: {
            errorList: isErrorState ? ['Обязательно для заполнения'] : [],
          },
        },
        visual: {
          label: 'Культура',
        },
        other: {
          dataTestId: 'select-field-culture',
        },
      }}
    />
  );
};

export default observer(CultureSelect);
