import { EModalSize, EModalType, TModalConfig, Typography } from '@farmlink/farmik-ui';

const warningBeforeOdzTypeChange: TModalConfig = {
  id: 'warningBeforeOdzTypeChange',
  name: 'warningBeforeOdzTypeChange',
  type: EModalType.Warning_Before_Living,
  dataTestId: 'warningBeforeOdzTypeChange',
  title: (
    <Typography variant={'title'} align={'center'}>
      Вы уверены что хотите изменить тип ОДЗ? <br /> Текущие настройки ОДЗ будут потеряны
    </Typography>
  ),
  styledProps: {
    $size: EModalSize.Medium,
  },
  successButton: {
    title: 'Да, продолжить',
    color: 'secondary',
  },
  denyButton: {
    title: 'Нет, отменить',
    color: 'default',
  },
};

const warningBeforeDependencyChange: TModalConfig = {
  id: 'warningBeforeDependencyChange',
  name: 'warningBeforeDependencyChange',
  type: EModalType.Warning_Before_Living,
  dataTestId: 'warningBeforeDependencyChange',
  title: (
    <Typography variant={'title'} align={'center'}>
      Вы уверены что хотите изменить определяющий параметр? <br /> текущие настройки ОДЗ будут
      потеряны
    </Typography>
  ),
  styledProps: {
    $size: EModalSize.Medium,
  },
  successButton: {
    title: 'Да, продолжить',
    color: 'secondary',
  },
  denyButton: {
    title: 'Нет, отменить',
    color: 'default',
  },
};

const StoAttributeOdzModals = {
  warningBeforeOdzTypeChange,
  warningBeforeDependencyChange,
};

export default StoAttributeOdzModals;
