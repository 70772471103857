import styled from 'styled-components';

const Header = styled.div`
  padding-bottom: 12px;
  border-bottom: 1px solid #e7f1fd;
`;

const Body = styled.div``;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  padding: 20px 16px 24px 16px;

  border-radius: 16px;

  background-color: #f9fcff;
`;

const StyledStoAttributeOdzDependency = {
  Header,
  Body,
  Wrapper,
};

export default StyledStoAttributeOdzDependency;
