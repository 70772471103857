import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  ButtonLink,
  Checkbox,
  EModalType,
  ModalFooter,
  TModalButton,
  TModalConfig,
  Typography,
  useModal,
} from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { v4 } from 'uuid';
import { saveAs } from 'file-saver';
import moment from 'moment/moment';

import { useDataTestIdV2 } from '../../../../../../../shared/features/utils/hooks/locators';
import { useStore } from '../../../../../../../shared/utils/IoC';
import { StoService } from '../../../../../../../shared/mobx/services/as-fields';
import { TypeApiRequest } from '../../../../../../../shared/utils/axios2';
import { ProfileStore } from '../../../../../profile/stores/ProfileStore';
import { OrganizationsStore } from '../../../../../../stores/organizations.store';
import { SeasonsStore } from '../../../../../../stores/seasons.store';

import Styled from './StoDownloadReportModal.styles';
import { StoDownloadReportEmployee as Employee } from './components';
import { TStoDownloadReportModalAuthorType as TAuthorType } from './types';
import { StoDownloadReportHelpers as Helpers } from './utils/helpers';
import { IStoDownloadReportModalEmployee as ITechEmployee } from './interfaces';

const StoDownloadReport: FC = () => {
  // Сторы
  const profileStore = useStore(ProfileStore);
  const organizationsStore = useStore(OrganizationsStore);
  const seasonsStore = useStore(SeasonsStore);

  // Сервисы
  const stoService = useStore(StoService);

  // Состояния
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [developedEmployeeList, setDevelopedEmployeeList] = useState<ITechEmployee[]>([]);
  const [approvedEmployeeList, setApprovedEmployeeList] = useState<ITechEmployee[]>([]);
  const [isIncludeDrafts, setIsIncludeDrafts] = useState<boolean>(false);
  const [isIncludeDictionaries, setIsIncludeDictionaries] = useState<boolean>(true);

  const modal = useModal();
  const getLocator = useDataTestIdV2('sto__download-report');

  useEffect(() => {
    setDevelopedEmployeeList([
      {
        id: v4(),
        name: profileStore.fullName,
        title: 'Руководитель отдела агроскаутинга',
      },
    ]);

    setApprovedEmployeeList([
      {
        id: v4(),
        name: '',
        title: 'Главный Агроном',
      },
    ]);
  }, []);

  const checkIfAllowedAddMore = (type: TAuthorType): boolean => {
    switch (type) {
      case 'developer':
        return Helpers.checkIfAllowedAddingMoreAuthors(developedEmployeeList);

      case 'lead':
        return Helpers.checkIfAllowedAddingMoreAuthors(approvedEmployeeList);

      default:
    }
  };

  const denyButton: TModalButton = {
    title: 'Отменить',
    dataTestId: getLocator('deny-button')['data-test-id'],
    color: 'default',
    handler: modal.closeModal,
  };

  const successButton: TModalButton = {
    title: isLoading ? 'Загружаем ...' : 'Скачать',
    dataTestId: getLocator('success-button')['data-test-id'],
    color: 'primary',
    disabled: isLoading,
    handler: async (): Promise<void> => {
      const payload: TypeApiRequest<'getStoReportBySeason'> = {
        seasonYear: Number(seasonsStore.selectedSeason),
        includeDrafts: isIncludeDrafts,
        includeDictionaries: isIncludeDictionaries,
        organizationId: organizationsStore.selectedOrganizationId,
        signatories: {
          developedBy: Helpers.validateEmployeeList(developedEmployeeList),
          approvedBy: Helpers.validateEmployeeList(approvedEmployeeList),
        },
      };

      await stoService.getStoReportBySeason(payload, {
        actions: {
          handleSuccess: response => {
            const blob = new Blob([response], {
              type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            });

            const formattedDate = moment().format('DD-MM-YYYY');

            saveAs(
              blob,
              `СТО ${organizationsStore.currentOrganization.name} ${seasonsStore.selectedSeason} ${formattedDate}`
            );

            modal.closeModal();
          },
          handleLoading: setIsLoading,
        },
      });
    },
  };

  const handleNameChange = useCallback((type: TAuthorType, id: string, v: string) => {
    switch (type) {
      case 'developer':
        return setDevelopedEmployeeList(prevState =>
          Helpers.changeEmployee(prevState, 'name', id, v)
        );

      case 'lead':
        return setApprovedEmployeeList(prevState =>
          Helpers.changeEmployee(prevState, 'name', id, v)
        );

      default:
    }
  }, []);

  const handlePositionChange = useCallback((type: TAuthorType, id: string, v: string) => {
    switch (type) {
      case 'developer':
        return setDevelopedEmployeeList(prevState =>
          Helpers.changeEmployee(prevState, 'title', id, v)
        );

      case 'lead':
        return setApprovedEmployeeList(prevState =>
          Helpers.changeEmployee(prevState, 'title', id, v)
        );

      default:
    }
  }, []);

  const handleAddMoreClick = useCallback((type: TAuthorType) => {
    switch (type) {
      case 'developer':
        return setDevelopedEmployeeList(prevState => [
          ...prevState,
          { id: v4(), name: '', title: '' },
        ]);

      case 'lead':
        return setApprovedEmployeeList(prevState => [
          ...prevState,
          { id: v4(), name: '', title: '' },
        ]);

      default:
    }
  }, []);

  const handleRemoveClick = useCallback((type: TAuthorType, id: string) => {
    switch (type) {
      case 'developer':
        return setDevelopedEmployeeList(prevState => Helpers.removeEmployee(prevState, id));

      case 'lead':
        return setApprovedEmployeeList(prevState => Helpers.removeEmployee(prevState, id));

      default:
    }
  }, []);

  return (
    <Styled.Wrapper {...getLocator()}>
      <Styled.Header {...getLocator('header')}>
        <Typography dataTestId={getLocator('title')['data-test-id']} variant={'h5'}>
          Скачать документ СТО
        </Typography>

        <Typography dataTestId={getLocator('subtitle')['data-test-id']} variant={'body'}>
          Документ СТО содержит все опубликованные СТО сезона. Если вы также хотите скачать
          неопубликованные версии, включите черновики
        </Typography>
      </Styled.Header>

      <Styled.Body {...getLocator('body')}>
        <Styled.EmployeeList
          {...getLocator('employees-developed')}
          $isDisabledGap={developedEmployeeList.length < 2}
        >
          {developedEmployeeList.map(e => (
            <Employee
              key={e.id}
              dataTestId={getLocator('employee')['data-test-id']}
              id={e.id}
              type={'developer'}
              name={e.name}
              position={e.title}
              isShowRemoveButton={developedEmployeeList.length > 1}
              onNameChange={handleNameChange}
              onPositionChange={handlePositionChange}
              onRemoveClick={handleRemoveClick}
            />
          ))}

          {checkIfAllowedAddMore('developer') ? (
            <Styled.WrapperButtonAddMore {...getLocator('wrapper-button-add-more')}>
              <ButtonLink
                dataTestId={getLocator('button-add-more')['data-test-id']}
                color={'accent'}
                onClick={() => handleAddMoreClick('developer')}
              >
                + Добавить ещё
              </ButtonLink>
            </Styled.WrapperButtonAddMore>
          ) : null}
        </Styled.EmployeeList>

        <Styled.EmployeeList
          {...getLocator('employees-approved')}
          $isDisabledGap={approvedEmployeeList.length < 2}
        >
          {approvedEmployeeList.map(e => (
            <Employee
              key={e.id}
              dataTestId={getLocator('employee')['data-test-id']}
              id={e.id}
              type={'lead'}
              name={e.name}
              position={e.title}
              isShowRemoveButton={approvedEmployeeList.length > 1}
              onNameChange={handleNameChange}
              onPositionChange={handlePositionChange}
              onRemoveClick={handleRemoveClick}
            />
          ))}

          {checkIfAllowedAddMore('lead') ? (
            <Styled.WrapperButtonAddMore {...getLocator('wrapper-button-add-more')}>
              <ButtonLink
                dataTestId={getLocator('button-add-more')['data-test-id']}
                color={'accent'}
                onClick={() => handleAddMoreClick('lead')}
              >
                + Добавить ещё
              </ButtonLink>
            </Styled.WrapperButtonAddMore>
          ) : null}
        </Styled.EmployeeList>

        <Styled.AdditionalData {...getLocator('additional-data')}>
          <Typography
            dataTestId={getLocator('additional-data__title')['data-test-id']}
            variant={'bodySubAccent'}
          >
            Включить в документ СТО:
          </Typography>

          <Styled.CheckboxList {...getLocator('additional-data__checkboxes')}>
            <Styled.Checkbox {...getLocator('include-draft')}>
              <Checkbox
                dataTestId={{ ...getLocator('include-draft-checkbox') }['data-test-id']}
                value={isIncludeDrafts}
                onChange={setIsIncludeDrafts}
              />

              <Typography
                dataTestId={getLocator('include-draft-label')['data-test-id']}
                variant={'body'}
              >
                Черновики
              </Typography>
            </Styled.Checkbox>

            <Styled.Checkbox {...getLocator('include-dictionaries')}>
              <Checkbox
                dataTestId={{ ...getLocator('include-dictionaries-checkbox') }['data-test-id']}
                value={isIncludeDictionaries}
                onChange={setIsIncludeDictionaries}
              />

              <Typography
                dataTestId={getLocator('include-dictionaries-label')['data-test-id']}
                variant={'body'}
              >
                Реестры
              </Typography>
            </Styled.Checkbox>
          </Styled.CheckboxList>
        </Styled.AdditionalData>
      </Styled.Body>

      <ModalFooter
        dataTestId={getLocator('footer')['data-test-id']}
        denyButton={denyButton}
        successButton={successButton}
      />
    </Styled.Wrapper>
  );
};

StoDownloadReport.displayName = 'StoDownloadReport';

const StoDownloadReportModal: TModalConfig = {
  id: 'sto__download-report',
  name: 'sto__download-report',
  dataTestId: 'sto__download-report',
  title: '',
  type: EModalType.Custom,
  children: observer(StoDownloadReport),
  styledProps: {
    $modalPaddings: '40px 60px',
    $size: '600px',
    $height: 'auto',
    $fullHeight: true,
  },
};

export default StoDownloadReportModal;
