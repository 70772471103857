import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { ENotificationType, NewScrollbar, useNotificator } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { saveAs } from 'file-saver';

import { StoCard } from '../StoCard';
import { useDataTestIdV2 } from '../../../../../../../shared/features/utils/hooks/locators';
import { useStore } from '../../../../../../../shared/utils/IoC';
import StoListStore from '../../mobx/stores/StoListStore/StoList.store';
import { StoListController } from '../../mobx/controllers';
import { useStoNavigation } from '../../../../hooks';
import { useDynamicPagination } from '../../../../../../../shared/features/DynamicPagination/hooks';
import { ContentLoader } from '../../../../../../../shared/features/UI/loaders/ContentLoader';
import getNotificationScheme, {
  ENotificationScheme,
} from '../../../../../../../shared/utils/helpers/getNotificationScheme/getNotificationScheme';

import Styled from './StoList.styled';

const StoList: FC = () => {
  const { isFetchingStoList, currentPage, totalPages, stoList } = useStore(StoListStore);
  const { increaseStoListPage, fetchStoList, fetchStoReport } = useStore(StoListController);

  const { navigateToSto } = useStoNavigation();
  const notificatorActions = useNotificator();

  const handleScroll = useCallback(async () => {
    await fetchStoList(true);
  }, []);

  const { scrollRef, isShowLoader: isShowLoaderByScroll } = useDynamicPagination<HTMLDivElement>({
    currentPage,
    totalPages,
    onScroll: handleScroll,
    onPageChange: increaseStoListPage,
  });

  useEffect(() => {
    if (!stoList.length) return;
    if (!scrollRef.current) return;
    if (currentPage + 1 > totalPages) return;

    if (scrollRef.current.scrollHeight < window.innerHeight) {
      increaseStoListPage();
      fetchStoList(true);
    }
  }, [stoList.length]);

  const loaderOverlayHeight = useMemo<`${number}px`>(() => {
    const height = scrollRef.current?.offsetHeight ?? 0;

    return `${height}px`;
  }, [scrollRef.current?.offsetHeight]);

  const loaderOverlayTopPos = useMemo<number>(() => {
    if (!scrollRef.current) return 0;

    return scrollRef.current.getBoundingClientRect().top;
  }, [scrollRef.current]);

  const handleStoCardClick = useCallback((id: string): void => {
    navigateToSto(id);
  }, []);

  const handleDownloadStoClick = useCallback((id: string, stoName: string): Promise<Blob> => {
    return fetchStoReport(
      { stoName },
      { id, includeDrafts: true },
      {
        actions: {
          handleFailure: () => {
            const notification = getNotificationScheme(
              ENotificationScheme.Dark,
              'Ошибка при скачивании СТО.\n Попробуйте снова или обратитесь к администратору',
              {
                type: ENotificationType.Warning,
              }
            );

            notificatorActions.setNotification(notification);
          },
        },
      }
    );
  }, []);

  const getDataTestId = useDataTestIdV2('sto__list');

  return (
    <NewScrollbar
      ref={scrollRef}
      scrollbarRightPosition={0}
      scrollbarPaddingOffset={4}
      dataTestId={getDataTestId()['data-test-id']}
    >
      <Styled.Wrapper {...getDataTestId()} ref={scrollRef}>
        <ContentLoader
          parentRef={scrollRef}
          overlayStyles={{
            borderRadius: '0 0 16px 16px',
            zIndex: '3',
            height: loaderOverlayHeight,
            top: loaderOverlayTopPos,
            display: isShowLoaderByScroll || isFetchingStoList ? 'flex' : 'none',
          }}
          dataTestId={getDataTestId('loader')['data-test-id']}
        />

        {stoList.map(sto => (
          <StoCard
            key={sto.id}
            sto={sto}
            onClick={handleStoCardClick}
            onDownloadClick={handleDownloadStoClick}
          />
        ))}
      </Styled.Wrapper>
    </NewScrollbar>
  );
};

StoList.displayName = 'StoList';

export default observer(StoList);
