import { Field } from '../../../../../../../../api/models/field.model';
import { IMapLayerGroupClickPayload } from '../../../../../../../shared/features/map/models';
import { MapEventBus, MapMarkerController } from '../../../../../../../shared/features/map/modules';
import { lazyInject, provide } from '../../../../../../../shared/utils/IoC';
import { FieldFillStore } from '../../../../components/TopPanelControls/components/FieldFillControls/mobx';
import { EFieldFill } from '../../../../../../constants/FieldFill.enum';
import { EFieldTooltip } from '../../../../../../constants/FieldTooltip.enum';
import { BottomWidgetStore } from '../../../../components/BottomPanel/mobx';
import { FieldsMapCoreController } from '../../../../mobx';
import { EFieldsMode } from '../../../../utils';

@provide.transient()
class FieldsViewController extends FieldsMapCoreController {
  @lazyInject(FieldFillStore)
  protected fieldFillStore: FieldFillStore;

  @lazyInject(MapMarkerController)
  protected mapMarkerController: MapMarkerController;

  @lazyInject(BottomWidgetStore)
  protected bottomWidgetStore: BottomWidgetStore;

  public async initialize() {
    this.coreStore.fieldsMode = EFieldsMode.DISPLAY;

    // Получаем список полей с бэка. Сетаем их в FieldsStore
    const fieldsList = await this.fieldsApiService.fetchFieldsList();

    // чистим карту если нет полей
    if (!fieldsList.length) {
      this.mapCoreController.clear();
      this.coreStore.fieldsMode = EFieldsMode.DISPLAY;
      return;
    }
    // Подготовка карты. Центрирование
    this.centerMapToFieldBounds(this.fieldToCenter ?? fieldsList[0]);

    // Инициализируем карту. Делаем связь слой - поле
    await this.buildMap(fieldsList, this.getLayerGroupConfig);
    this.registerMapEvents();

    // Выполняем заливку слоев культурными зонами и устанавливаем тултипы для слоев
    this.fieldFillController.setFillStrategy(EFieldFill.Cultures);
    this.fieldTooltipController.setTooltipContent(EFieldTooltip.Name);
  }

  public destroy() {
    super.destroy();
    this.bottomWidgetStore.close();
  }

  public selectField(field: Field | null) {
    if (!field) {
      return;
    }

    this.bottomWidgetStore.open(() => {
      super.selectField(field);

      setTimeout(() => {
        this.fillLayerAfterSelect(field);
      }, 500);
    });
  }

  public deselectField() {
    this.bottomWidgetStore.close(() => {
      super.deselectField();

      this.fieldFillController.setFillStrategy(EFieldFill.Cultures);
    });
  }

  public async deleteField(field: Field, seasonYear: number): Promise<Field> {
    const isSelected = this.coreStore.isSelectedField(field);

    await super.deleteField(field, seasonYear).then(() => {
      if (isSelected) {
        this.bottomWidgetStore.close();
      }
    });

    return field;
  }

  protected registerMapEvents() {
    const listener1 = MapEventBus.on('layerGroup.click', this.handleLayerGroupClick);
    const listener2 = MapEventBus.on('layerGroup.cancelSelected', () => this.deselectField());

    this.coreStore.setMapEventListeners([listener1, listener2]);
  }

  protected handleLayerGroupClick = (payload: IMapLayerGroupClickPayload) => {
    const fieldOfLayerGroup = this.getFieldByLayerGroup(payload.layerGroup);

    if (this.coreStore.isSelectedField(fieldOfLayerGroup)) {
      return;
    }

    this.selectField(fieldOfLayerGroup);
  };

  private fillLayerAfterSelect(field: Field): void {
    const { fieldFillValue } = this.fieldFillStore;

    // Выбрано поле и его заливка (переход с одного поля на другое)
    if (fieldFillValue) {
      this.fieldFillController.setFillStrategy(fieldFillValue, field);
    }
  }
}

export default FieldsViewController;
