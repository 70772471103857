import { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { NewDropdown } from '@farmlink/farmik-ui';
import { TNewDropdownConfig } from '@farmlink/farmik-ui/dist/new_Dropdown/Dropdown.types';

import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { TaskStore } from '../../../../../../mobx/stores';
import { TaskController } from '../../../../../../mobx/controllers';
import { useTasksParams } from '../../../../../../hooks';
import { FullscreenContext } from '../../../../../../containers/fullscreen';

const CultureSelect: FC = () => {
  const taskStore = useStore(TaskStore);
  const taskController = useStore(TaskController);

  const scrollValue = useContext(FullscreenContext);

  const params = useTasksParams();

  const isCreate = taskStore.isCreateOne || taskStore.isCreateMultiple;
  const isBlocked = !isCreate || taskStore.isEditMode;

  useEffect(() => {
    (async () => {
      if (isCreate && taskStore.taskCreate?.cultureId) {
        await taskController.fetchOperationListAfterCultureChange(params.orgId);
      }
    })();
  }, [taskStore.taskCreate?.cultureId, isCreate]);

  const handleChange = useCallback((cultureId: string) => {
    taskController.changeCulture(cultureId ?? '');
  }, []);

  const defaultValue = taskStore.cultureDefaultValue;

  const dropdownConfig: TNewDropdownConfig = {
    field: {
      onChange: handleChange,
      placeholder: 'Выберите культуру',
      defaultValue,
      icons: {
        clear: {},
      },
      type: {
        search: {
          options: {
            isHideSearchFieldAutocomplete: true,
          },
        },
      },
    },
    body: {
      optionList: taskStore.cultureOptionList,
      onScrollCloseOptions: { isEnable: true, parentScrollValue: scrollValue },
    },
    visual: {
      label: 'Культура',
      isBlocked,
    },
    validation: {
      error: {
        errorList: [],
        options: {
          isDoNotShowErrorText: true,
        },
      },
    },
    other: {
      dataTestId: 'task-culture-select',
    },
  };

  return <NewDropdown config={dropdownConfig} />;
};

CultureSelect.displayName = 'CultureSelect';

export default observer(CultureSelect);
