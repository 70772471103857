import styled from 'styled-components';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 62% minmax(200px, 38%);

  padding: 16px;

  @media (max-width: 1365px) {
    grid-template-columns: 62% minmax(200px, 38%);
  }
  @media (max-width: 1024px) {
    grid-template-columns: 1fr 200px;
  }
`;

export default {
  Wrapper,
};
