import React, { FC } from 'react';

import { FieldWeather } from '../../../../components/FullWeatherSlider/components/FieldWeather/FieldWeather';

import Styled from './BottomWidget.styles';
import { FieldInfo } from './components/FieldInfo/FieldInfo';

const FieldsViewBottomWidget: FC = () => {
  return (
    <Styled.Wrapper>
      <FieldWeather back_btn={false} />
      <FieldInfo />
    </Styled.Wrapper>
  );
};

export default FieldsViewBottomWidget;
