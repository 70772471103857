import { makeAutoObservable, reaction } from 'mobx';

import { FieldsMapCoreStore } from '../modules/fields/mobx/stores';
import { lazyInject, provide } from '../../shared/utils/IoC';
import { Axios, TypeApiResponse } from '../../shared/utils/axios2';

import { SeasonsStore } from './seasons.store';

@provide.singleton()
export class WeatherStore {
  @lazyInject(Axios)
  protected axios: Axios;

  @lazyInject(SeasonsStore)
  protected seasons: SeasonsStore;

  @lazyInject(FieldsMapCoreStore)
  private sharedFieldsStore: FieldsMapCoreStore;

  weatherForecastsById: any;
  weatherById: any;

  private _showFullWeather = false;

  private get selectedField() {
    return this.sharedFieldsStore.selectedField;
  }

  constructor() {
    makeAutoObservable(this);
    reaction(
      () => this.selectedField,
      () => {
        if (this.seasons.selectedSeason && !this.sharedFieldsStore.isDisplaySeasonsMode) {
          this.fetchWeatherById();
          this.fetchWeatherForecastsById();
        }
      }
    );
  }

  get showFullWeather() {
    return this._showFullWeather;
  }

  set showFullWeather(value: boolean) {
    this._showFullWeather = value;
  }

  fetchWeatherById = async () => {
    let response: TypeApiResponse<'getWeatherById'>;
    if (!this.selectedField) {
      return;
    }

    try {
      // this.fields.isLoading = true;
      response = await this.axios.api.getWeatherById({
        id: this.selectedField.id,
        seasonYear: Number(this.seasons.selectedSeason),
      });
      this.weatherById = response;
      // this.fields.isLoading = false;
    } catch (e) {
      // this.fields.isLoading = false;
      return;
    }
    return response;
  };

  fetchWeatherForecastsById = async () => {
    let response: TypeApiResponse<'getWeatherForecastsById'>;
    if (!this.selectedField) {
      return;
    }

    try {
      // this.fields.isLoading = true;
      response = await this.axios.api.getWeatherForecastsById({
        id: this.selectedField.id,
        seasonYear: Number(this.seasons.selectedSeason),
      });
      this.weatherForecastsById = response;
      // this.fields.isLoading = false;
    } catch (e) {
      // this.fields.isLoading = false;
      return;
    }
    return response;
  };
}
