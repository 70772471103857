import React, { FC, ReactElement, useEffect } from 'react';
import { observer } from 'mobx-react';

import { useStore } from '../../../../../shared/utils/IoC';

import Styled from './BottomPanel.styles';
import { BottomWidgetStore } from './mobx';

interface IProps {
  content: ReactElement;
  defaultOpen: boolean;
}

const BottomPanelContainer: FC<IProps> = props => {
  const store = useStore(BottomWidgetStore);

  useEffect(() => {
    if (props.defaultOpen) {
      void store.open();
    } else {
      void store.close();
    }
  }, []);

  return (
    <Styled.PSlider visibility={store.isOpened} transitionTime={store.cssTransitionTime}>
      {props.content}
    </Styled.PSlider>
  );
};

export default observer(BottomPanelContainer);
