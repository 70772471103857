import { Colors } from '@farmlink/farmik-ui';
import styled from 'styled-components';

const MenuIconWrapper = styled('div')({
  opacity: 0,
  width: '100%',
  display: 'flex',
  justifyContent: 'end',
  marginTop: '2px',
});

const IconWrapper = styled('div')({
  minWidth: 20,
  height: 20,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
});

interface IWrapperProps {
  active?: boolean;
}

const Wrapper = styled('div')<IWrapperProps>(
  {
    display: 'flex',
    padding: '10px 12px',
    cursor: 'pointer',

    borderBottom: `1px solid ${Colors.borderGrey}`,

    [`&:hover ${MenuIconWrapper}`]: {
      opacity: 1,
    },

    [`&:hover ${IconWrapper}`]: {
      border: `2px solid ${Colors.generalWhite}`,
    },
  },
  ({ active }) => ({
    backgroundColor: active ? Colors.lightGreen : 'inherit',

    '&:hover': {
      backgroundColor: active ? Colors.lightGreen : Colors.hoverGray,
    },

    [`${IconWrapper}`]: {
      border: `2px solid ${Colors.generalWhite}`,
    },
  })
);

const RightBlock = styled('div')({
  minWidth: 40,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'end',
});

export default {
  Wrapper,
  RightBlock,
  IconWrapper,
  MenuIconWrapper,
};
