import { lazyInject, provide } from '../../../../../utils/IoC';
import { ContextualLinkCoreStore as Store } from '../../stores';
import { IContextualLink as ILink, IContextualLinkConfig as ILinkConfig } from '../../../models';

@provide.transient()
class ContextualLinkCrudService {
  @lazyInject(Store)
  protected store: Store;

  public addLink = (link: ILink): void => {
    this.store.setLink(link);
  };

  public addLinkList = (linkList: ILink[]): void => {
    this.store.setLinkList(linkList);
  };

  public createLink = (config: ILinkConfig): ILink => {
    const link = this.createLinkByConfig(config);

    this.store.setLink(link);

    return link;
  };

  public removeLink = (id: string): void => {
    this.store.deleteLink(id);
  };

  protected createLinkByConfig = ({ id, from, to }: ILinkConfig): ILink => {
    const link: ILink = {
      id,
      from: {
        url: from.url,
      },
      to: {
        url: to.url,
      },
    };

    // Назад
    if (from?.backButtonLabel) link.from.label = from.backButtonLabel;
    if (from?.state) link.from.state = from.state;

    // Вперед
    if (to?.label) link.to.label = to.label;
    if (to?.state) link.to.state = to.state;

    return link;
  };
}

export default ContextualLinkCrudService;
