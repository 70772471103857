import { Listener } from 'eventemitter2';
import { makeAutoObservable } from 'mobx';

import { Field } from '../../../../../../../api/models/field.model';
import { provide } from '../../../../../../shared/utils/IoC';
import { EFieldsMode } from '../../../utils';

/**
 * Содержит общие переменные, которые используются во всех модах работы карты
 */
@provide.singleton()
class FieldsMapCoreStore {
  private _fieldsMode: EFieldsMode | null = null;

  private _selectedField: Field | null = null;

  private _fieldToCenter: (Field | string) | null = null;

  private _isBuildingMap = false;

  private _mapEventsListenersList: Listener[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * Хранит активный режим работы модуля полей.
   */
  get fieldsMode() {
    return this._fieldsMode;
  }

  set fieldsMode(mode) {
    this._fieldsMode = mode;
  }

  /**
   * Хранит текущее выбранное поле (для любого мода)
   */
  get selectedField() {
    return this._selectedField;
  }

  set selectedField(value) {
    this._selectedField = value;
  }

  /**
   * Хранит поле(или id поля) к которому нужно центрировать. Используется при переходе между режимами
   */
  get fieldToCenter() {
    return this._fieldToCenter;
  }

  set fieldToCenter(value) {
    this._fieldToCenter = value;
  }

  get isBuildingMap() {
    return this._isBuildingMap;
  }

  set isBuildingMap(value) {
    this._isBuildingMap = value;
  }

  // Хранит подписки на события карты. Нужен для отписки от событий по завершению работы мода
  get mapEventListeners() {
    return this._mapEventsListenersList;
  }

  setMapEventListeners(listeners: Listener[]) {
    this._mapEventsListenersList.push(...listeners);
  }

  get isDisplayMode() {
    return this._fieldsMode === EFieldsMode.DISPLAY;
  }

  get isCreateMode() {
    return this._fieldsMode === EFieldsMode.CREATE;
  }

  get isEditMode() {
    return this._fieldsMode === EFieldsMode.EDIT;
  }

  get isImportMode() {
    return this._fieldsMode === EFieldsMode.IMPORT;
  }

  get isDisplaySeasonsMode() {
    return this._fieldsMode === EFieldsMode.FIELD_SEASONS;
  }

  isSelectedField(field: Field | string): boolean {
    if (!field || !this.selectedField) {
      return false;
    }

    const fieldId = typeof field !== 'string' ? field.id : field;

    return fieldId === this.selectedField.id;
  }
}

export default FieldsMapCoreStore;
