import { Field } from '../../../../../../../../../../api/models/field.model';
import { MapCoreController } from '../../../../../../../../../shared/features/map/modules';
import { lazyInject, provide } from '../../../../../../../../../shared/utils/IoC';
import { EFieldTooltip } from '../../../../../../../../constants/FieldTooltip.enum';
import FieldsStore from '../../../../../../../../../shared/mobx/stores/FieldsStore/Fields.store';
import { makeTooltipVariants } from '../../../../../../utils';
import FieldFillStore from '../stores/FieldFill.store';

@provide.transient()
class FieldTooltipController {
  @lazyInject(FieldsStore)
  private fieldsStore: FieldsStore;

  @lazyInject(FieldFillStore)
  private fieldFillStore: FieldFillStore;

  @lazyInject(MapCoreController)
  private mapCoreController: MapCoreController;

  /**
   * Устанавливает тултип на элемент карты, который относится к переданному полю.
   * Если не было передано поле, то устанавливает тултипы для всех элементов на карте
   */
  public setTooltipContent(label: EFieldTooltip, field?: Field) {
    this.fieldFillStore.tooltipLabelValue = label;

    if (field) {
      const content = this.getTooltipContent(field, label.toLowerCase());
      const layer = this.mapCoreController.getMapLayerById(field?.polyId);

      layer?.setTooltipContent(content);

      return;
    }

    this.fieldsStore.fieldsList.forEach(fieldItem => {
      const content = this.getTooltipContent(fieldItem, label.toLowerCase());

      const layer = this.mapCoreController.getMapLayerById(fieldItem?.polyId);
      layer?.setTooltipContent(content);
    });
  }

  private getTooltipContent(field: Field, tooltipType: string) {
    const tooltipVariants = makeTooltipVariants(field);

    return tooltipVariants[tooltipType];
  }
}

export default FieldTooltipController;
