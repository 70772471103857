import { makeAutoObservable } from 'mobx';

import { lazyInject, provide } from '../../shared/utils/IoC';
import { Axios } from '../../shared/utils/axios2';
import { ProfileStore } from '../../dashboard/modules/profile/stores/ProfileStore';
import { OrganizationsStore } from '../../dashboard/stores/organizations.store';
import { SeasonsStore } from '../../dashboard/stores/seasons.store';
import { SeasonsController } from '../../dashboard/controllers/seasons/seasons.controller';
import { ProfileController } from '../../dashboard/modules/profile/controllers/profile.controller';
import { importedFieldsSessionStorageHelpers } from '../../dashboard/modules/fields/modules/FieldsImport/utils/helpers';

@provide.singleton()
export class SessionStore {
  @lazyInject(Axios)
  axios: Axios;

  @lazyInject(ProfileStore)
  profile: ProfileStore;

  @lazyInject(ProfileController)
  profileController: ProfileController;

  @lazyInject(OrganizationsStore)
  organizations: OrganizationsStore;

  @lazyInject(SeasonsStore)
  seasons: SeasonsStore;

  @lazyInject(SeasonsController)
  seasonsController: SeasonsController;

  constructor() {
    makeAutoObservable(this);
    document.addEventListener('logout', () => this.logout());
  }

  isLogout = false;
  isLoading = true;
  isLoggedIn = false;

  checkIsLoggedIn = async (authKey?: string) => {
    this.isLoading = true;

    try {
      if (authKey) {
        const authByKeyResponse = await this.axios.api
          .erqToken({ code: authKey })
          .then(response => response);

        localStorage.setItem('accessToken', authByKeyResponse['access-token']);
        localStorage.setItem('refreshToken', authByKeyResponse['refresh-token']);
      }

      const accessToken = localStorage.getItem('loginAs') || localStorage.getItem('accessToken');

      if (!accessToken) {
        return this.onCheckIsLoggedInError();
      }

      this.axios.api.verifyAccessToken({});
      this.onCheckPass();
    } catch (err) {
      this.onCheckIsLoggedInError();
    } finally {
      this.isLoading = false;
    }
  };

  onCheckPass = () => {
    this.organizations.selectedOrganizationId = 'my';
    this.seasons.selectedSeason = '';
    this.seasonsController.clear();
    // this.fields.clearIdToFields();
    // this.fields.selectedFieldId = '';
    // this.fields.prevSeason = '';
    // this.fields.prevOrganization = '';
    // this.fields.prevMapMode = null;
    // this.fields.prevPopupState = PopupPages.None;
    this.profileController.getUserInfo(true);
    this.isLoggedIn = true;
    this.isLoading = false;
  };

  onCheckIsLoggedInError = () => {
    this.isLoggedIn = false;
    this.isLoading = false;

    localStorage.removeItem('accessToken');
    localStorage.removeItem('externalKey');
    localStorage.removeItem('externalEmail');
  };

  setLoggedIn = async () => {
    this.isLoading = true;

    await new Promise(resolve => {
      setTimeout(() => resolve(0), 2000);
    });

    this.isLoggedIn = true;
    this.isLoading = false;
  };

  logout = () => {
    const { deleteToken } = importedFieldsSessionStorageHelpers;

    this.isLogout = true;
    this.isLoading = false;
    this.isLoggedIn = false;
    localStorage.removeItem('accessToken');
    localStorage.removeItem('externalKey');
    localStorage.removeItem('externalEmail');

    deleteToken();
  };
}
