import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AutoTooltip, useModal } from '@farmlink/farmik-ui';
import moment from 'moment';

import { PreparedTask, StatusLinks, taskLabels, TaskStatuses } from '../../stores/tasks.store';
import { TasksController } from '../../controllers/tasks.controller';
import { useStore } from '../../../../../shared/utils/IoC';
import { ITask } from '../../../../../../api/models/as-fields/task/task.model';

import boronovanieSvg from './boronovanie.svg';
import diskovanieSvg from './diskovanie.svg';
import kultivaciyaSvg from './kultivaciya.svg';
import monitoringSvg from './monitoring.svg';
import opriskivanieSvg from './opriskivanie.svg';
import pahotaSvg from './pahota.svg';
import posevSvg from './posev.svg';
import uborkaSvg from './uborka.svg';
import udobreniyaSvg from './udobreniya.svg';
import defaultOperation from './defaultOperation.svg';
import infoSvg from './info.svg';
import {
  AssigneeName,
  CultureBlock,
  DaysRemainingBlock,
  ExpiredInfoIcon,
  FieldName,
  OperationName,
  Status,
  StatusBlock,
  StatusMenuItem,
  StatusMenuOverlay,
  TaskLabel,
  TaskRow,
  TaskStatusMarker,
  TaskStatusMenu,
  TaskStatusMenuWrapper,
  TaskTypeSvg,
  TaskTypeWrapper,
} from './style';

export interface TaskItemProps {
  task: PreparedTask;
  showCulture?: boolean;
  profileUserName?: string;
  onClick: any;
  isHighlighted: boolean;
  unHighlightRow: () => void;
  isDisableStatusChange?: boolean;
}

export const getOperstionTypeSvg = (operationTypeId: string): any => {
  // todo Рефактор завязываться так на айди очень плохо на фронте
  switch (operationTypeId) {
    case '90ab2d38-5e84-4a96-a59f-b2f64ed727c8':
      return defaultOperation; // audit posevov
    case '00ecc3cd-b32d-4673-92a3-52e9ccc7b0b1':
      return boronovanieSvg;
    case '4644a5f4-e0a2-48ad-b0da-204d23495410':
      return udobreniyaSvg;

    case 'e015ef2a-c00b-47db-882d-b0320182f0a2':
      return pahotaSvg;
    case '0fa85f64-5717-4562-b3fc-2c963f66afa6':
      return diskovanieSvg;
    case '6a7cde39-1c04-45d2-912b-39bf94e491e1':
      return defaultOperation; // инокуляция

    case 'fb9cfdbd-baf1-44ca-8414-480bd5868590':
      return diskovanieSvg; // лущение
    case 'dd854e27-967f-4a37-93d5-a21828bffe02':
      return monitoringSvg;
    case '422cfb9d-7fe7-4330-998b-5043cfafe875':
      return opriskivanieSvg;
    case '341ef983-f8ef-4d91-8f4c-14aa7b71eaaa':
      return opriskivanieSvg;
    case '116576a8-d6cd-41a9-b87c-6801e6062f19':
      return opriskivanieSvg;
    case '65b3db9e-1b40-49b9-9274-9d1e29a6849c':
      return kultivaciyaSvg;
    case '8a6b16a6-f226-41d6-817d-43a791931a6d':
      return kultivaciyaSvg;
    case '33113393-3d2d-4219-97f4-c4727649f72c':
      return defaultOperation; // Предуборочный мониторинг

    case '9897d453-1705-4b3b-a43f-490d969c0b71':
      return defaultOperation; // Протравливание семян яровых и озимых культур
    case 'c8fa78f5-6616-4ab4-91c2-cdb4d07d5406':
      return posevSvg;
    case 'abfd4772-e18a-4d0c-a71a-4123d89fad21':
      return posevSvg; // ???Сев яровых и озимых культур

    case '7ed3a3ec-e743-4c81-b636-9e9f7395c4a4':
      return uborkaSvg; // Уборка. Контроль за комбайном
    case '860514c2-3818-4fdc-addd-3b16c6f9b779':
      return uborkaSvg; // Уборка. Общий контроль на поле

    default:
      return defaultOperation;
  }
};

export const TasksItem: FC<TaskItemProps> = ({
  task,
  onClick,
  showCulture,
  profileUserName,
  isDisableStatusChange,
  isHighlighted,
  unHighlightRow,
}) => {
  const taskController = useStore(TasksController);

  const { openModalByModalId } = useModal();

  const statusMenuRef = useRef(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const [showStatusMenu, setShowStatusMenu] = useState(false);

  useEffect(() => {
    if (!isHighlighted) return;

    setTimeout(() => {
      if (wrapperRef.current) {
        wrapperRef.current.scrollIntoView({
          behavior: 'auto',
          block: 'center',
          inline: 'center',
        });
      }
    }, 300);
  }, [isHighlighted]);

  const handleMouseLeave = useCallback((): void => {
    if (!isHighlighted) return;

    unHighlightRow();
  }, [isHighlighted]);

  const handleStatusClick = event => {
    event.stopPropagation();

    if (taskController.isTaskAvailableForEdit(task) && task.availableStatuses.length) {
      setShowStatusMenu(true);
    }
  };

  const handleStatusSave = async (status: TaskStatuses, event) => {
    event.stopPropagation();

    if (status === TaskStatuses.Canceled) {
      openModalByModalId(
        'cancellationModal',
        {
          id: task.id,
          status,
        },
        (_task: ITask) => taskController.updateTaskInList(_task)
      );
    } else {
      await taskController.setStatus(task.id, status);
    }

    setShowStatusMenu(false);
  };

  const handleTaskClick = (id: string, event) => {
    event.stopPropagation();
    event.preventDefault();
    onClick();
  };

  const onClose = event => {
    event.stopPropagation();
  };

  const isHideRemainingDays = useMemo(() => {
    return task.status === TaskStatuses.Canceled;
  }, [task.status]);

  const renderTaskStatuses = useCallback(statusItem => {
    return (
      <StatusMenuItem
        onClick={handleStatusSave.bind(this, statusItem)}
        key={statusItem}
        data-test-id={'task-item-status-menu-item'}
      >
        <TaskLabel>{taskLabels[statusItem]}</TaskLabel>
        <TaskStatusMarker status={statusItem} />
      </StatusMenuItem>
    );
  }, []);

  const getRemainingDaysTooltipText = useMemo(() => {
    if (task.status === TaskStatuses.Completed) {
      return 'Дней до даты окончания';
    }
    return task.daysLeft > 0 ? 'Дни до завершения' : 'Просроченные дни';
  }, [task.daysLeft, task.status]);

  return (
    <TaskRow
      onClick={handleTaskClick.bind(this, task.id)}
      onMouseLeave={handleMouseLeave}
      $isHighlighted={isHighlighted}
      ref={wrapperRef}
    >
      <TaskTypeWrapper className={'operation-name'} data-test-id={'task-item-operation-name'}>
        <TaskTypeSvg
          src={
            task.operationInfo?.operationTypeInfo?.attrs?.picUrl ??
            getOperstionTypeSvg(task.operationInfo.operationTypeId)
          }
        />
        <OperationName>{task.operationInfo.name}</OperationName>
      </TaskTypeWrapper>
      <FieldName className={'field-name'} data-test-id={'task-item-field-name'}>
        {task?.field?.name}
      </FieldName>
      <AssigneeName className={'assignee-name'} data-test-id={'task-item-assignee-name'}>
        {task.assignee?.fullName ? task.assignee?.fullName : profileUserName}
      </AssigneeName>
      {showCulture ? (
        <CultureBlock className={'culture-name'} data-test-id={'task-item-culture-name'}>
          {task?.culture?.name || 'Культура не указана'}
        </CultureBlock>
      ) : null}
      {!isHideRemainingDays ? (
        <AutoTooltip position="bottom" content={getRemainingDaysTooltipText}>
          <DaysRemainingBlock isExpired={task.daysLeft < 0} data-test-id={`days-left`}>
            {`${Math.abs(task.daysLeft)} д`}
            {task.daysLeft < 0 && <ExpiredInfoIcon src={infoSvg} />}
          </DaysRemainingBlock>
        </AutoTooltip>
      ) : (
        <DaysRemainingBlock />
      )}
      <StatusBlock className={'status-block'} onClick={handleStatusClick} onBlur={onClose}>
        <Status statusId={task.status} data-test-id={'task-item-status-label'}>
          {task.statusLabel}
        </Status>
        {!isDisableStatusChange &&
        showStatusMenu &&
        StatusLinks[task.status as TaskStatuses] &&
        StatusLinks[task.status as TaskStatuses].length ? (
          <>
            <StatusMenuOverlay
              onClick={event => {
                event.stopPropagation();
                setShowStatusMenu(false);
              }}
              show={showStatusMenu}
              data-test-id={'task-item-status-menu-overlay'}
            />
            <TaskStatusMenuWrapper
              show={showStatusMenu}
              data-test-id={'task-item-status-menu-wrapper'}
            >
              <TaskStatusMenu ref={statusMenuRef}>
                {StatusLinks[task.status as TaskStatuses].map(statusItem =>
                  renderTaskStatuses(statusItem)
                )}
              </TaskStatusMenu>
            </TaskStatusMenuWrapper>
          </>
        ) : null}
      </StatusBlock>
    </TaskRow>
  );
};
