import React, { FC, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';

import { useDataTestIdV2 } from '../../../../../../../../../../../../../../../../../../../../../shared/features/utils/hooks/locators';
import { useStore } from '../../../../../../../../../../../../../../../../../../../../../shared/utils/IoC';
import { StoAttributeCoreController as Controller } from '../../../../../../mobx/controllers';
import { useStoParams } from '../../../../../../../../../../../../../../../../../../hooks';
import { IStoAttributeOdzDependencyPut as IDependencyPut } from '../../../../../../../../../../../../../../../../../../../../../../api/models/as-fields/new/sto';
import { StoAttributeOdzInput as OdzInput } from '../StoAttributeNumericOdz/components';
import { StoStore } from '../../../../../../../../../../../../../../../../mobx';
import { StoAttributeCoreStore as Store } from '../../../../../../mobx/stores';
import { EStoOdzNumericError } from '../../../../../../constants';

import Styled from './StoAttributeOdzDependencyNumeric.styles';

const checkIfValueIsEmpty = (value: string | number): boolean => {
  return value === '' || value === undefined || value === null;
};

type TProps = { groupId: string } & IDependencyPut;

const StoAttributeOdzDependencyNumeric: FC<TProps> = ({
  groupId,
  id,
  numericValues,
  systemData,
}) => {
  const store = useStore(Store);
  const stoStore = useStore(StoStore);

  const controller = useStore(Controller);

  const params = useStoParams();
  const errorList = store.getOdzDepErrorList(groupId, id);

  useEffect(() => {
    const hasNumError = store.hasDepOdzError(groupId, id, EStoOdzNumericError.Dependency);

    if (checkIfValueIsEmpty(numericValues.from) || checkIfValueIsEmpty(numericValues.to)) {
      if (hasNumError) store.deleteOdzDepError(groupId, id, EStoOdzNumericError.Dependency);
      return;
    }

    const formattedFrom = Number(numericValues.from);
    const formattedTo = Number(numericValues.to);

    if (formattedFrom >= formattedTo) {
      if (!hasNumError) store.setOdzDepError(groupId, id, EStoOdzNumericError.Dependency);
    } else if (hasNumError) store.deleteOdzDepError(groupId, id, EStoOdzNumericError.Dependency);
  }, [numericValues.from, numericValues.to]);

  useEffect(() => {
    return () => {
      store.deleteOdzDepErrorList(groupId, id);
    };
  }, []);

  const checkIfIsBlockedByPermissions = (): boolean => {
    if (params.versionId || stoStore.isViewOnly) return true;
  };

  const handleChangeFrom = useCallback((enteredValue: string): void => {
    controller.changeOdzDependencyNumericValue(params, groupId, id, {
      from: enteredValue,
    });
  }, []);

  const handleChangeTo = useCallback((enteredValue: string): void => {
    controller.changeOdzDependencyNumericValue(params, groupId, id, {
      to: enteredValue,
    });
  }, []);

  const getDataTestId = useDataTestIdV2('sto__attribute__odz__dependency__numeric');

  return (
    <Styled.Wrapper {...getDataTestId()}>
      <Styled.Inputs {...getDataTestId('inputs')}>
        <OdzInput
          isBlocked={checkIfIsBlockedByPermissions()}
          numericType={systemData.attribute.attribute.type}
          precision={systemData.attribute.attribute?.precision}
          placeholder={'от'}
          value={numericValues.from}
          onChange={handleChangeFrom}
        />

        <OdzInput
          isBlocked={checkIfIsBlockedByPermissions()}
          numericType={systemData.attribute.attribute.type}
          precision={systemData.attribute.attribute?.precision}
          placeholder={'до'}
          value={numericValues.to}
          onChange={handleChangeTo}
        />
      </Styled.Inputs>

      {errorList.length ? (
        <Styled.Errors {...getDataTestId('errors')}>
          {errorList.map(error => (
            <Styled.Error key={error} {...getDataTestId('error')}>
              {error}
            </Styled.Error>
          ))}
        </Styled.Errors>
      ) : null}
    </Styled.Wrapper>
  );
};

StoAttributeOdzDependencyNumeric.displayName = 'StoAttributeOdzDependencyNumeric';

export default observer(StoAttributeOdzDependencyNumeric);
