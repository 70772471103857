import { observer } from 'mobx-react';
import React, { FC, ReactElement } from 'react';

import { CultureZone } from '../../../../../../../../../../../../../../api/models/field.model';
import { CheckAccessStore } from '../../../../../../../../../../../../../authorization/stores/checkAccess.store';
import OverflowTextTooltip from '../../../../../../../../../../../../../shared/components/ExtendedAutoTooltip/OverflowTextTooltip';
import { useDataTestIdV2 } from '../../../../../../../../../../../../../shared/features/utils/hooks/locators';
import { useStore } from '../../../../../../../../../../../../../shared/utils/IoC';
import { OrganizationsStore } from '../../../../../../../../../../../../stores/organizations.store';
import SharedStyled from '../../../../../../shared/styles/Shared.styles';

import ExpIcon from './components/ExpIcon/ExpIcon';
import Styled from './CultureZoneCard.styles';

interface IProps {
  cultureZone: CultureZone;
  contextMenu?: ReactElement;
  isSelected: boolean;
  onClick: (zone: CultureZone) => void;
}

const CultureZoneCard: FC<IProps> = props => {
  const checkAccessStore = useStore(CheckAccessStore);
  const organizationStore = useStore(OrganizationsStore);

  const getDataTestId = useDataTestIdV2('field-seasons-culture-zone-card');

  const getZoneName = () => {
    return props.cultureZone?.name || 'Участок';
  };

  const getCultureName = () => {
    return props.cultureZone.culture?.name || 'Нет культуры';
  };

  const getCultureArea = () => {
    return `${props.cultureZone.area.toFixed(1)} га`;
  };

  const hasEditAccess = () => {
    if (organizationStore.selectedOrganizationId === 'my') {
      return true;
    }

    return checkAccessStore.getAccessRuleValue('field.editCultureZone');
  };

  return (
    <Styled.Wrapper
      active={props.isSelected}
      onClick={() => props.onClick(props.cultureZone)}
      {...getDataTestId('wrapper')}
    >
      <Styled.IconWrapper>
        <ExpIcon cultureZone={props.cultureZone} />
      </Styled.IconWrapper>

      <SharedStyled.TextWrapper {...getDataTestId('text-wrapper')}>
        <OverflowTextTooltip content={getZoneName()}>
          {ref => <SharedStyled.Title ref={ref} {...getDataTestId('title')} />}
        </OverflowTextTooltip>

        <OverflowTextTooltip content={getCultureName()}>
          {ref => <SharedStyled.Subtitle ref={ref} {...getDataTestId('subtitle')} />}
        </OverflowTextTooltip>
      </SharedStyled.TextWrapper>

      <Styled.RightBlock>
        <SharedStyled.Title {...getDataTestId('area')}>{getCultureArea()}</SharedStyled.Title>

        {hasEditAccess() && <Styled.MenuIconWrapper>{props.contextMenu}</Styled.MenuIconWrapper>}
      </Styled.RightBlock>
    </Styled.Wrapper>
  );
};

export default observer(CultureZoneCard);
