import { Layer } from 'leaflet';

import { IFillCultureLayer } from '../../../../../../../../../../../shared/features/map/models';
import {
  MapCoreController,
  MapLayerGroupStore,
} from '../../../../../../../../../../../shared/features/map/modules';
import { FieldLayerGroup } from '../../../../../../../../../../../shared/features/map/utils/MapElements';
import { lazyInject, provide } from '../../../../../../../../../../../shared/utils/IoC';
import { EFieldFill } from '../../../../../../../../../../constants/FieldFill.enum';
import FieldsStore from '../../../../../../../../../../../shared/mobx/stores/FieldsStore/Fields.store';
import { FieldFillStore } from '../../../../mobx';
import { IFillStrategy } from '../../../../models';

@provide.transient()
class CultureZoneFillStrategy implements IFillStrategy {
  @lazyInject(FieldFillStore)
  private fillStore: FieldFillStore;

  @lazyInject(FieldsStore)
  private fieldsStore: FieldsStore;

  @lazyInject(MapLayerGroupStore)
  private mapLayerGroupStore: MapLayerGroupStore<FieldLayerGroup>;

  @lazyInject(MapCoreController)
  protected mapCoreController: MapCoreController;

  constructor(private layer?: Layer) {
    this.fillStore.fieldFillValue = EFieldFill.Cultures;

    if (this.isLayerValid(this.layer)) {
      this.layer.showCulturesPolygons();
      this.layer.openTooltip();

      return;
    }

    this.getAllLayerGroups().forEach(layerItem => {
      layerItem.showCulturesPolygons();
      layerItem.openTooltip();
    });
  }

  public reset(): void {
    if (this.isLayerValid(this.layer)) {
      this.layer.hideCulturesPolygons();

      return;
    }

    this.getAllLayerGroups().forEach(layerGroup => {
      layerGroup.hideCulturesPolygons();
    });
  }

  private getAllLayerGroups(): (Layer & IFillCultureLayer)[] {
    return this.mapCoreController.getAllMapLayers().filter(layer => {
      return this.isLayerValid(layer);
    }) as any;
  }

  private isLayerValid(layer: any): layer is IFillCultureLayer {
    const hasShowFn = typeof layer?.showCulturesPolygons === 'function';
    const hasHideFn = typeof layer?.hideCulturesPolygons === 'function';

    return hasShowFn && hasHideFn;
  }
}

export default CultureZoneFillStrategy;
