import { observer } from 'mobx-react';
import { FC, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import {
  IChecklistsExtendedFileValue as IExtendedFileValue,
  TChecklistsFileAttrToDraw as TFileAttrToDraw,
} from '../../../../models';
import { toBase64 } from '../../../../../../../../../shared/utils/getBase64';
import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { ChecklistsController } from '../../../../mobx/controllers';
import { EChecklistAttributeType as EAttrType } from '../../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';
import { useWarningBeforeDeleting } from '../../../../../../../../../shared/hooks';
import { createChecklistsAttributeId as createAttrId } from '../../../../helpers';
import { ChecklistsAttr as Attribute } from '../../../../components/elements';

import {
  AddFirstImgLabel,
  FileUploader,
  ImagesUploaderWrapper,
  ImgsRowBlock,
  PaperClipIconImg,
  Spinner,
  SpinnerWrapper,
} from './style';
import PaperClipIcon from './assets/paperClipIcon.svg';
import { ImageCarousel } from './ImageCarousel';
import { ImageMiniRow } from './ImageMiniRow';
import { ChecklistsSeparateFileAttrInput as SeparateFileAttrInput } from './components';

interface IProps {
  attrToDraw: TFileAttrToDraw;
  isSeparate?: boolean;
  maxInRow?: number;
  filesToDrawList?: IExtendedFileValue[];
  isForceVisible?: boolean;
}

const MAX_ITEM_IN_ROW = 10;

const ChecklistsFileAttr: FC<IProps> = ({
  attrToDraw: { groupId, id, isBlocked, initialModel, value, options, validationScheme },
  isSeparate,
  maxInRow,
  filesToDrawList,
  isForceVisible,
}) => {
  const checklistsController = useStore(ChecklistsController);
  const fileUploaderRef = useRef(null);
  const [selectedImgIndex, setSelectedImgIndex] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [maxImgIndex, setMaxImgIndex] = useState(0);
  const [parentElem, setParentElem] = useState<null | Element>(null);
  const [filesToRenderList, setFilesToRenderList] = useState(options.extendedFileList);
  const [filesRowLength, setFilesRowLength] = useState(value.fileValue.length);

  useEffect(() => {
    if (filesToDrawList) {
      setFilesToRenderList(filesToDrawList);
      setFilesRowLength(filesToDrawList.length);
    }
  }, [filesToDrawList]);

  const warningBeforeDeletingActions = useWarningBeforeDeleting();

  const maxItemInRow = maxInRow ? maxInRow : MAX_ITEM_IN_ROW;

  const isPaperClipButtonShow =
    !isBlocked && filesToRenderList.length < maxItemInRow && !isSeparate;

  const visibilityResult = initialModel.visibility
    ? checklistsController.calculateAttrVisibility(groupId, id)
    : { value: true };

  useEffect(() => {
    if (filesRowLength) {
      setMaxImgIndex(filesRowLength - 1);
    } else {
      setMaxImgIndex(0);
    }
  }, [value.fileValue]);

  useEffect(() => {
    checklistsController.toggleAttrVisibility(groupId, id, visibilityResult.value as boolean);
  }, [JSON.stringify(visibilityResult)]);

  const onAddImg = useCallback(() => {
    fileUploaderRef?.current?.click?.();
  }, []);

  const isShowAddImageLabel = useMemo(() => {
    if (isSeparate) return false;
    if (!isBlocked) return true;
  }, [isSeparate, isBlocked]);

  useLayoutEffect(() => {
    setParentElem(document.querySelector(`#images-uploader-wrapper-${id}`));
  }, []);

  const onSelectImage = useCallback((num: number) => {
    setSelectedImgIndex(num);
  }, []);

  const handleFileChange = async event => {
    event.stopPropagation();
    const [fileForUpload] = event.target.files;

    if (!fileForUpload) return;

    setIsLoading(true);

    const blob = await toBase64(fileForUpload);

    const uploadedData = await checklistsController.uploadFile(fileForUpload);

    if (uploadedData) {
      const updatedFileList = [...value.fileValue, uploadedData.id];
      setMaxImgIndex(maxImgIndex + 1);

      const newExtendedFile: IExtendedFileValue = {
        id: uploadedData.id,
        imagePreview: blob as string,
      };

      checklistsController.changeAttrValue<EAttrType.FileLink>(EAttrType.FileLink, groupId, {
        checkListAttributeId: id,
        fileValue: updatedFileList,
      });

      const updatedExtendedFileList = [...filesToRenderList, newExtendedFile];

      checklistsController.changeAttrOptions<EAttrType.FileLink>(groupId, id, {
        extendedFileList: updatedExtendedFileList,
      });

      setFilesToRenderList(updatedExtendedFileList);
      setFilesRowLength(updatedExtendedFileList.length);

      event.target.value = null;
    }

    setIsLoading(false);
  };

  const deleteByIndex = (index: number) => {
    const updatedFileValue = value.fileValue.slice();
    const [deletedFileId] = updatedFileValue.splice(index, 1);

    checklistsController.changeAttrValue<EAttrType.FileLink>(EAttrType.FileLink, groupId, {
      checkListAttributeId: id,
      fileValue: updatedFileValue,
    });

    const updatedExtendedFileList = filesToRenderList.filter(file => file.id !== deletedFileId);

    checklistsController.changeAttrOptions<EAttrType.FileLink>(groupId, id, {
      extendedFileList: filesToRenderList.filter(file => file.id !== deletedFileId),
    });

    setFilesToRenderList(updatedExtendedFileList);
    setFilesRowLength(updatedExtendedFileList.length);

    if (maxImgIndex) {
      setMaxImgIndex(maxImgIndex - 1);
    } else {
      setMaxImgIndex(0);
    }
  };

  const onDeleteByIndex = (index: number) => () => {
    warningBeforeDeletingActions.showWarningBeforeDeleting(
      'Вы уверены, что хотите удалить фотографию?',
      () => {
        deleteByIndex(index);
      }
    );
  };

  const onDeleteInCarousel = () => {
    warningBeforeDeletingActions.showWarningBeforeDeleting(
      'Вы уверены, что хотите удалить фотографию?',
      () => {
        deleteInCarouselBySelectedIndex();
      }
    );
  };

  const deleteInCarouselBySelectedIndex = () => {
    const updatedFileValue = value.fileValue.slice();
    const [deletedFileId] = updatedFileValue.splice(selectedImgIndex, 1);

    checklistsController.changeAttrValue<EAttrType.FileLink>(EAttrType.FileLink, groupId, {
      checkListAttributeId: id,
      fileValue: updatedFileValue,
    });

    checklistsController.changeAttrOptions<EAttrType.FileLink>(groupId, id, {
      extendedFileList: filesToRenderList.filter(file => file.id !== deletedFileId),
    });

    setMaxImgIndex(maxImgIndex - 1);

    if (filesToRenderList.length === 0) {
      setSelectedImgIndex(null);
    } else if (selectedImgIndex === maxImgIndex - 1) {
      setSelectedImgIndex(0);
    }
  };

  return (
    <ImagesUploaderWrapper
      id={`images-uploader-wrapper-${id}`}
      $isSeparate={isSeparate}
      $isVisible={isForceVisible ?? (visibilityResult.value as boolean)}
    >
      <FileUploader
        type="file"
        name="file"
        ref={fileUploaderRef}
        accept="image/jpeg,image/png,image/jpg"
        onChange={handleFileChange}
        onClick={event => event.stopPropagation()}
      />

      {isSeparate ? (
        <Attribute
          width={initialModel.position.width}
          isNewLine={initialModel.position.newLine}
          id={createAttrId(groupId, id)}
        >
          <SeparateFileAttrInput
            isRequired={initialModel.isRequired}
            label={initialModel.attribute?.name}
            tooltip={initialModel.toolTip}
            isBlocked={isBlocked}
            isError={validationScheme.isShowError}
            onClick={onAddImg}
            hasAttachedFiles={Boolean(filesRowLength)}
          />
        </Attribute>
      ) : null}

      {filesToRenderList.length > 0 ? (
        <ImgsRowBlock id={'imgs-row-block'}>
          {isLoading ? (
            <SpinnerWrapper>
              <Spinner />
            </SpinnerWrapper>
          ) : (
            <>
              {isPaperClipButtonShow ? (
                <PaperClipIconImg src={PaperClipIcon} onClick={onAddImg} />
              ) : null}
            </>
          )}

          <ImageMiniRow
            imgsArr={filesToRenderList}
            isBlocked={isBlocked}
            onDeleteByIndex={onDeleteByIndex}
            setSelectedImgIndex={onSelectImage}
            parentElem={parentElem}
            maxInRow={maxItemInRow}
            isPaperClipButtonShow={isPaperClipButtonShow}
          />
        </ImgsRowBlock>
      ) : isShowAddImageLabel ? (
        <AddFirstImgLabel $isRequired={initialModel.isRequired} onClick={onAddImg}>
          Прикрепить фотографию
        </AddFirstImgLabel>
      ) : null}

      {selectedImgIndex !== null && Boolean(filesToRenderList.length) && (
        <ImageCarousel
          imgUrl={(() => {
            return filesToRenderList[selectedImgIndex]?.downloadUrl
              ? filesToRenderList[selectedImgIndex]?.downloadUrl
              : filesToRenderList[selectedImgIndex]?.imagePreview;
          })()}
          isBlocked={isBlocked}
          isDeletionModalOpen={false}
          onClose={() => setSelectedImgIndex(null)}
          onDelete={onDeleteInCarousel}
          onSwitchLeft={() => {
            if (selectedImgIndex > 0) {
              setSelectedImgIndex(selectedImgIndex - 1);
            } else {
              setSelectedImgIndex(maxImgIndex);
            }
          }}
          onSwitchRight={() => {
            if (selectedImgIndex < maxImgIndex) {
              setSelectedImgIndex(selectedImgIndex + 1);
            } else {
              setSelectedImgIndex(0);
            }
          }}
        />
      )}
    </ImagesUploaderWrapper>
  );
};

ChecklistsFileAttr.displayName = 'ChecklistsFileAttr';

export default observer(ChecklistsFileAttr);
