import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { FC, memo, useEffect } from 'react';

import { Agreement } from '../pages/agreement/Agreement';
import { withBrowserTitle } from '../hocs/withBrowserTitle/withBrowserTitle';

import { AdminView } from './admin-view/AdminView';
import {
  AuthorizationWrapper,
  ExternalRedirect,
} from './authorization/containters/AuthorizationWrapper';
import { DashboardRouter } from './dashboard/dashboard.router';
import FieldsRouter from './dashboard/modules/fields/FieldsRouter';
import TaskEditPointPage from './dashboard/modules/tasks/modules/TaskEditPoint/TaskEditPoint';
import { ApplicationRoutes } from './routes';
import TaskViewPage from './dashboard/modules/tasks/modules/TaskView/TaskViewPage';
import TaskEditPage from './dashboard/modules/tasks/modules/TaskEdit/TaskEdit';
import { TasksRoute } from './dashboard/modules/tasks/tasks.route';
import { OperationsListing } from './dashboard/modules/operations/containers/listing/operations.listing';
import OrganizationRouter from './dashboard/organization.router';
import { TaskMiddleware } from './dashboard/modules/tasks/modules/TaskMiddleware';
import { Tasks } from './dashboard/modules/tasks/containers/Tasks';
import TaskCreate from './dashboard/modules/tasks/modules/TaskCreate/TaskCreate';
import TaskCreateOne from './dashboard/modules/tasks/modules/TaskCreateOne/TaskCreateOne';
import TaskCreateGroup from './dashboard/modules/tasks/modules/TaskCreateGroup/TaskCreateGroup';
import { EErrorStatusName } from './shared/constans/errorStatus';
import PageNotFound from './shared/components/PageNotFound/PageNotFound';
import StoRouter from './dashboard/modules/sto/sto.router';
import {
  FieldsViewPage,
  FieldsCreatePage,
  FieldEditPage,
  FieldsImportPage,
  FieldSeasonsPage,
} from './dashboard/modules/fields/modules';
import { EFieldsRoutes } from './dashboard/modules/fields/utils';

const FieldsWithTitle = withBrowserTitle('Поля')(FieldsRouter);
const OperationsWithTitle = withBrowserTitle('Операции')(OperationsListing);
const TasksWithTitle = withBrowserTitle('Задачи')(Tasks);
const StoWithTitle = withBrowserTitle('СТО')(StoRouter);

const AppRouter: FC<{
  needAdditionalInfo: boolean;
  redirectPath: string;
  registrationStep2Url: string;
}> = ({ needAdditionalInfo, redirectPath, registrationStep2Url }) => {
  const navigate = useNavigate();

  const authPath =
    process.env?.NODE_ENV === 'development'
      ? ApplicationRoutes.ExternalAuthorizationDev
      : `${window._env_.AUTH_URL}${ApplicationRoutes.ExternalAuthorization}`;

  useEffect(() => {
    if (window?.location?.search?.includes('authKey')) {
      navigate(redirectPath);
    }
  }, []);

  return (
    <AuthorizationWrapper excludedRoutes={[]} isZoneForAuthorized={true} redirectTo={authPath}>
      <Routes>
        <Route path={ApplicationRoutes.AdminView} element={<AdminView />} />

        <Route path="/" element={<DashboardRouter />}>
          <Route path=":orgId" element={<OrganizationRouter />}>
            <Route path="fields" element={<FieldsWithTitle />}>
              <Route path={EFieldsRoutes.View} element={<FieldsViewPage />} />
              <Route path={EFieldsRoutes.Add} element={<FieldsCreatePage />} />
              <Route path={EFieldsRoutes.Edit} element={<FieldEditPage />} />
              <Route path={EFieldsRoutes.Import} element={<FieldsImportPage />} />
              <Route path={EFieldsRoutes.Seasons} element={<FieldSeasonsPage />} />
            </Route>

            <Route path="operations" element={<OperationsWithTitle />} />

            <Route path="tasks">
              <Route path={TasksRoute.Listing} element={<TasksWithTitle />} />
              <Route path={TasksRoute.Task} element={<TaskMiddleware />}>
                <Route path={TasksRoute.Task} element={<TaskViewPage />} />
                <Route path={TasksRoute.TaskMap} element={<TaskViewPage />} />

                <Route path={TasksRoute.TaskMapCreatePoint} element={<TaskEditPage />} />
                <Route path={TasksRoute.TaskMapEditPoint} element={<TaskEditPointPage />} />

                <Route path={TasksRoute.TaskEdit} element={<TaskEditPage />} />

                <Route path={TasksRoute.ChecklistEdit} element={<TaskEditPage />} />
                <Route path={TasksRoute.ChecklistList} element={<TaskViewPage />} />
                <Route path={TasksRoute.Checklist} element={<TaskViewPage />} />
              </Route>

              <Route path={TasksRoute.TaskCreate} element={<TaskCreate />}>
                <Route path={TasksRoute.TaskCreateOne} element={<TaskCreateOne />} />
                <Route path={TasksRoute.TaskCreateGroup} element={<TaskCreateGroup />} />
              </Route>
            </Route>

            <Route path="sto/*" element={<StoWithTitle />} />
          </Route>

          <Route path={ApplicationRoutes.Agreement} element={<Agreement />} />
          <Route path={ApplicationRoutes.PageNotFound} element={<PageNotFound />} />
          <Route
            path={ApplicationRoutes.PageForbidden}
            element={<PageNotFound type={EErrorStatusName.NotEnoughRights} />}
          />

          {needAdditionalInfo ? <ExternalRedirect redirectTo={registrationStep2Url} /> : null}

          <Route path="*" element={<Navigate to="/my" replace />} />
        </Route>
      </Routes>
    </AuthorizationWrapper>
  );
};

export default memo(AppRouter);
