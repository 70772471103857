import { observer } from 'mobx-react';
import { FC, useEffect } from 'react';
import { List, ListRowProps } from 'react-virtualized';

import { Field } from '../../../../../../../../api/models/field.model';
import { useVirtualizedListScroll } from '../../../../../../../shared/hooks';
import { useStore } from '../../../../../../../shared/utils/IoC';
import { SeasonsStore } from '../../../../../../stores/seasons.store';
import { FieldsMapCoreStore } from '../../../../mobx';
import { ListWrapper, NoItemsPlaceholder, NoSeasonsPlaceholder } from '../../../../components';

import FieldsViewListItem from './components/ListItem/ListItem';
import FieldsViewListFooter from './components/Footer/Footer';

interface IProps {
  list: Field[];
}

const FieldsViewList: FC<IProps> = ({ list }) => {
  // Сторы
  const seasonsStore = useStore(SeasonsStore);
  const sharedFieldsStore = useStore(FieldsMapCoreStore);

  const scroll = useVirtualizedListScroll(list);

  const hasSeasons = Boolean(seasonsStore.selectedSeason);

  const rowRender = ({ index, key, style }: ListRowProps) => {
    const field = list[index];

    return (
      <div style={style} key={key}>
        <FieldsViewListItem key={key} field={field} index={index} />
      </div>
    );
  };

  useEffect(() => {
    scroll.to({ id: sharedFieldsStore.selectedField?.id });
  }, [sharedFieldsStore.selectedField?.id]);

  return (
    <ListWrapper title="Поля" footer={<FieldsViewListFooter />} dataTestId="fields-list-section">
      {hasSeasons ? (
        <List
          width={284}
          height={window?.innerHeight - 167}
          row
          rowCount={list.length}
          rowHeight={80}
          rowRenderer={rowRender}
          noRowsRenderer={() => <NoItemsPlaceholder />}
          scrollToIndex={scroll.activeIndex}
          scrollToAlignment="center"
        />
      ) : (
        <NoSeasonsPlaceholder />
      )}
    </ListWrapper>
  );
};

export default observer(FieldsViewList);
