import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDataTestIdV2 } from '../../../../../../../shared/features/utils/hooks/locators';
import { useStore } from '../../../../../../../shared/utils/IoC';
import { OrganizationsStore } from '../../../../../../stores/organizations.store';
import useFieldRoutes from '../../../../hooks/useFieldsRoutes';
import { FieldSeasonsViewStore as Store } from '../../mobx';

import Header from './components/PanelHeader/PanelHeader';
import Card from './components/SeasonCard/SeasonCard';
import Styled from './SeasonsListLeftPanel.styles';

const FieldSeasonsListLeftPanel: FC = () => {
  // Навигиация
  const navigate = useNavigate();
  const routes = useFieldRoutes();

  // Сторы
  const store = useStore(Store);
  const organizationStore = useStore(OrganizationsStore);

  const getDataTestId = useDataTestIdV2('field-seasons');

  const handleBackClick = () => {
    navigate(routes.fieldsViewRoute);
  };

  return (
    <Styled.Wrapper {...getDataTestId('list-wrapper')}>
      <Header
        title={store.field?.name}
        subtitle={organizationStore.currentOrganization?.name ?? 'Организация'}
        onBackClick={handleBackClick}
        mode="view"
      />

      <Styled.Body {...getDataTestId('list-body')}>
        {store.fieldSeasonsList.map(model => (
          <Card
            key={`season-card-${model.season.label}`}
            season={model}
            opened={model.season?.year === store.selectedSeason?.season?.year}
          />
        ))}
      </Styled.Body>
    </Styled.Wrapper>
  );
};

export default observer(FieldSeasonsListLeftPanel);
