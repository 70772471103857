import React, { FC, useEffect, useState } from 'react';
import {
  ModalFooter,
  NewDropdown,
  TModalButton,
  TNewDropdownProps,
  Typography,
  useModal,
} from '@farmlink/farmik-ui';

import { ISelectOption } from '../../../../../../../../../types/selectOption';
import { useDataTestIdV2 } from '../../../../../../../../shared/features/utils/hooks/locators';
import { StoService } from '../../../../../../../../shared/mobx/services/as-fields';
import { TypeApiRequest } from '../../../../../../../../shared/utils/axios2';
import { useStore } from '../../../../../../../../shared/utils/IoC';
import { EFieldSeasonsModalName } from '../../configs/fieldsCreateModalsConfig';
import { IFieldsAddStoModalPayload as IModalPayload } from '../../interfaces';

import Styled from './FieldsAddStoModal.styles';

const FieldsAddStoModal: FC = () => {
  // Сторы
  const stoService = useStore(StoService);

  const [selectedStoOption, setSelectedStoOption] = useState<ISelectOption | null>(null);
  const [optionList, setOptionList] = useState<ISelectOption[]>([]);

  const modal = useModal();
  const getDataTestId = useDataTestIdV2('fields__add-sto-modal');

  const modalPayload = (modal.getModalPayload() ?? {
    currentZoneId: '',
    zoneDataList: [],
  }) as IModalPayload;

  useEffect(() => {
    if (!modalPayload.currentZoneId) {
      return;
    }

    const currentZoneData = modalPayload.zoneDataList.find(
      ({ zoneId }) => zoneId === modalPayload.currentZoneId
    );

    setSelectedStoOption(currentZoneData.stoOptionList[0]);
    setOptionList(currentZoneData.stoOptionList);
  }, [modalPayload]);

  const handleChange: TNewDropdownProps['config']['field']['onChange'] = (_, otherData) => {
    setSelectedStoOption(otherData.option);
  };

  const handleSuccessClick = (): void => {
    const payload: TypeApiRequest<'addCultureZoneToSto'> = {
      stoId: selectedStoOption.value,
      cultureZoneId: modalPayload.currentZoneId,
    };

    stoService
      .addCultureZoneToSto(payload)
      .then(() => {
        if (modalPayload.zoneDataList.length === 1) {
          modal.closeModal();
          return;
        }

        const nextZoneId = modalPayload.zoneDataList[1].zoneId;
        const nextZoneList = modalPayload.zoneDataList.filter(
          ({ zoneId }) => zoneId !== modalPayload.currentZoneId
        );

        const addStoModalPayload: IModalPayload = {
          currentZoneId: nextZoneId,
          zoneDataList: nextZoneList,
        };

        modal.closeModal();
        modal.openModalByModalId(EFieldSeasonsModalName.fieldAddSto, addStoModalPayload);
      })
      .catch(() => modal.closeModal());
  };

  const denyButton: TModalButton = {
    title: 'Отменить',
    dataTestId: getDataTestId('deny-button')['data-test-id'],
    color: 'default',
    handler: modal.closeModal,
  };

  const successButton: TModalButton = {
    title: 'Сохранить',
    dataTestId: getDataTestId('success-button')['data-test-id'],
    color: 'primary',
    handler: handleSuccessClick,
  };

  return (
    <Styled.Wrapper {...getDataTestId()}>
      <Styled.Header {...getDataTestId('header')}>
        <Typography dataTestId={getDataTestId('title')['data-test-id']} variant={'h5'}>
          Выберите СТО
        </Typography>

        <Typography dataTestId={getDataTestId('subtitle')['data-test-id']} variant={'body'}>
          На поле изменилась культура. Выберите новый СТО, который хотите применить на поле
        </Typography>
      </Styled.Header>

      <div {...getDataTestId('body')}>
        <NewDropdown
          config={{
            field: {
              defaultValue: selectedStoOption,
              onChange: handleChange,
              placeholder: 'Выберите или укажите значение',
            },
            body: {
              optionList,
              zIndex: '10002',
            },
            visual: {
              label: 'СТО',
            },
            other: {
              dataTestId: getDataTestId('selector')['data-test-id'],
            },
          }}
        />
      </div>

      <ModalFooter
        dataTestId={getDataTestId('footer')['data-test-id']}
        denyButton={denyButton}
        successButton={successButton}
      />
    </Styled.Wrapper>
  );
};

FieldsAddStoModal.displayName = 'FieldsAddStoModal';

export default FieldsAddStoModal;
