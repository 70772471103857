import { Button, DropdownButton, TButtonMultiAction, useModal } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { SeasonsStore } from '../../../../../../../../stores/seasons.store';
import {
  AccessRuleVisibility,
  ControlAccessRulesWrapper,
} from '../../../../../../../../components/AccessRulesWrapper/ControlAccessRulesWrapper';
import useFieldRoutes from '../../../../../../hooks/useFieldsRoutes';
import { CREATE_SEASON_MODAL_ID } from '../../../../../../../seasons/modals/CreateSeasonModal/CreateSeasonModal.config';
import { OrganizationsStore } from '../../../../../../../../stores/organizations.store';
import { FieldsImportController } from '../../../../../FieldsImport';
import { EFieldsImportModalName } from '../../../../../FieldsImport/utils/constants';
import { importedFieldsSessionStorageHelpers } from '../../../../../FieldsImport/utils/helpers';

import alertSvg from './assets/alert-black-16x16.svg';
import plusSvg from './assets/plus-black-16x16.svg';
import downloadSvg from './assets/download-black-16x16.svg';
import Styled from './Footer.styles';

const FieldsViewListFooter = () => {
  // Навигация
  const navigate = useNavigate();
  const routes = useFieldRoutes();

  // Контроллеры
  const fieldsImportController = useStore(FieldsImportController);

  // Сторы
  const seasons = useStore(SeasonsStore);
  const organizationsStore = useStore(OrganizationsStore);

  const hasSelectedSeason = Boolean(seasons.selectedSeason);

  const modal = useModal();

  const importedFieldsToken = importedFieldsSessionStorageHelpers.getToken(
    organizationsStore.selectedOrganizationId,
    seasons.selectedSeason
  );

  const handleClickOnFieldCreation = (): void => {
    navigate(routes.fieldsCreateRoute());
  };

  const handleClickOnUploadFields = (): void => {
    modal.openModalByModalId(EFieldsImportModalName.FileImport, { isSomethingWentWrong: false });
  };

  const buttonMultiActionList: TButtonMultiAction[] = [
    {
      title: 'Продолжить с черновиком',
      description: 'Вернитесь к работе с загруженным файлом',
      onClick: async (): Promise<void> => {
        if (importedFieldsToken) {
          const isSuccess = await fieldsImportController.fetchImportedList(importedFieldsToken);

          if (isSuccess) {
            modal.openModalByModalId(EFieldsImportModalName.Loader);

            navigate(routes.fieldsImportRoute);
          } else {
            modal.openModalByModalId(EFieldsImportModalName.FileImport, {
              isSomethingWentWrong: true,
            });
          }
        }
      },
      icon: alertSvg,
      isHidden: !importedFieldsToken,
    },
    {
      title: 'Добавить поля',
      description: 'Соедините точки на карте для создания поля',
      onClick: () => {
        handleClickOnFieldCreation();
      },
      icon: plusSvg,
    },
    {
      title: 'Загрузить файлы',
      description: 'Добавьте контуры полей в форматах KML, geoJSON, shape',
      onClick: () => {
        handleClickOnUploadFields();
      },
      icon: downloadSvg,
    },
  ];

  return (
    <>
      {hasSelectedSeason ? (
        <ControlAccessRulesWrapper
          mode={AccessRuleVisibility.Hide}
          actionName="field.createFieldOrSeason"
        >
          <DropdownButton
            onClick={handleClickOnFieldCreation}
            title={'Добавить поле'}
            multiActionList={buttonMultiActionList}
            style={{ color: 'primary' }}
            dataTestId={'add-field-button'}
          />
        </ControlAccessRulesWrapper>
      ) : (
        <ControlAccessRulesWrapper
          mode={AccessRuleVisibility.Hide}
          actionName="field.createFieldOrSeason"
        >
          <Styled.ButtonWrapper>
            <Button
              color={'primary'}
              type={'button'}
              onClick={() => modal.openModalByModalId(CREATE_SEASON_MODAL_ID)}
              dataTestId={'add-season-button'}
            >
              Добавить сезон
            </Button>
          </Styled.ButtonWrapper>
        </ControlAccessRulesWrapper>
      )}
    </>
  );
};

export default observer(FieldsViewListFooter);
