import { makeAutoObservable } from 'mobx';

import { Field } from '../../../../../../../../api/models/field.model';
import { IGetImportedField } from '../../../../../../../../api/models/fields/getImportedField.model';
import { IMapPolygonInfo } from '../../../../../../../shared/features/map/models';
import { provide } from '../../../../../../../shared/utils/IoC';
import { makeAreaTooltip } from '../../../../utils';
import { EFieldsImportModalName } from '../../utils/constants';

@provide.singleton()
class FieldsImportStore {
  private _editableField: Field | null = null;

  private _isDataBeingProcessed = false;

  private _idToImportedField: Map<string, IGetImportedField> = new Map<string, IGetImportedField>();

  private _saveErrorModal: EFieldsImportModalName | null = null;

  private _isWaitingForEditRes = false;

  private _hasErrors = false;

  /**
   * Список хранит набор id, которые были удалены в процессе сессии
   * используется для блокировки обновления удалённых полей
   *
   * @private
   * @memberof FieldsImportStore
   */
  private _deletableFieldIdList = new Set<string>();

  constructor() {
    makeAutoObservable(this);
  }

  get hasErrors() {
    return this._hasErrors;
  }

  set hasErrors(value) {
    this._hasErrors = value;
  }

  get isDataBeingProcessed() {
    return this._isDataBeingProcessed;
  }

  get isEmptyImportedFieldsList() {
    return !Boolean(this._idToImportedField.size);
  }

  get isWaitingForEditRes() {
    return this._isWaitingForEditRes;
  }

  // Коллекия хранит поля, которые были импортированы
  get listOfImportedField() {
    return Array.from(this._idToImportedField.values());
  }

  get saveErrorModal() {
    return this._saveErrorModal;
  }

  // Редактируемое поле в режиме импорта
  get editableField() {
    return this._editableField;
  }

  get deletableFieldIdList() {
    return this._deletableFieldIdList;
  }

  setIsDataBeingProcessed = (value: boolean): void => {
    this._isDataBeingProcessed = value;
  };

  setIdToImportedField = (listOfImportedField: IGetImportedField[] = []): void => {
    const newIdToImportedField: Map<string, IGetImportedField> = new Map<
      string,
      IGetImportedField
    >();

    if (!listOfImportedField?.length) {
      return;
    }

    listOfImportedField.forEach(field => {
      newIdToImportedField.set(field.id, {
        ...field,
        name: field?.name ?? `Поле ${makeAreaTooltip(field?.area ?? 0)}`,
      });
    });

    this._idToImportedField = newIdToImportedField;
  };

  setSaveErrorModal = (errorName: EFieldsImportModalName): void => {
    this._saveErrorModal = errorName;
  };

  setIsWaitingForEditRes = (val: boolean): void => {
    this._isWaitingForEditRes = val;
  };

  setEditableField = (field: Field) => {
    this._editableField = field;
  };

  addDeletableFieldId = (fieldId: string) => {
    this._deletableFieldIdList.add(fieldId);
  };

  removeDeletableFieldId = (fieldId: string) => {
    this._deletableFieldIdList.delete(fieldId);
  };

  updateEditableField = (polygonInfo: IMapPolygonInfo) => {
    this._editableField.areaInHectare = polygonInfo.area;
    this._editableField.geometry = polygonInfo.geometry;
  };

  clearIsDataBeingProcessed = (): void => {
    this._isDataBeingProcessed = false;
  };

  clearIdToImportedField = (): void => {
    this._idToImportedField.clear();
  };

  clearSaveErrorModal = (): void => {
    this._saveErrorModal = null;
  };

  clearIsWaitingForEditRes = (): void => {
    this._isWaitingForEditRes = false;
  };

  clearEditableField = (): void => {
    this._editableField = null;
  };

  clearDeletableFieldIdList = (): void => {
    this._deletableFieldIdList.clear();
  };

  clearStore = (): void => {
    this.clearIsDataBeingProcessed();
    this.clearSaveErrorModal();
    this.clearIsWaitingForEditRes();

    this.clearIdToImportedField();

    this.clearEditableField();
    this.clearDeletableFieldIdList();
  };
}

export default FieldsImportStore;
