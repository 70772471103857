import { Colors, ESpaceSize } from '@farmlink/farmik-ui';
import styled from 'styled-components';

import { ReactComponent as BinIconSvg } from '../../../../static/bin.svg';

const Wrapper = styled('div')({
  width: '40px',
  position: 'relative',
  padding: ESpaceSize.S,
  marginRight: ESpaceSize.M,
  borderRadius: ESpaceSize.M,
  backgroundColor: Colors.generalWhite,
  pointerEvents: 'all',
});

interface IProps {
  disabled?: boolean;
  $bgColor?: Colors | string;
  $bgHoverColor?: Colors | string;
}

const Control = styled('div')<IProps>(
  {
    width: 24,
    height: 24,
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    padding: ESpaceSize.XS,
    borderRadius: ESpaceSize.S,
    marginBottom: ESpaceSize.S,

    '&:last-child': {
      marginBottom: 0,
    },
  },
  ({ disabled, $bgColor, $bgHoverColor }) => {
    if (disabled) {
      return {
        backgroundColor: Colors.technicalBgGrey,

        '& path': {
          // Временное решение до переноса/оптимизации иконок в farmik
          fill: `${Colors.hoverBorder} !important`,
        },
      };
    }

    return {
      backgroundColor: $bgColor ?? Colors.technicalBgGrey,

      '&:hover': {
        backgroundColor: $bgHoverColor ?? '#E9ECF0',
      },
    };
  }
);

const BinIcon = styled(BinIconSvg)({
  width: 16,
  height: 16,
  '& path': {
    fill: Colors.accentPink,
  },
});

export default {
  Wrapper,
  Control,
  BinIcon,
};
