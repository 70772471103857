import { ESpaceSize } from '@farmlink/farmik-ui';
import styled from 'styled-components';

import { Colors } from '../../../../../../../../../shared/constans/colors';

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const Label = styled('h6')({
  margin: 0,
  fontSize: 14,
  fontWeight: 600,
  lineHeight: '20px',
  color: Colors.generalBlack,
  marginBottom: ESpaceSize.S,
});

export default {
  Wrapper,
  Label,
};
