import type { Geometry } from '@turf/turf';
import type { PolylineOptions } from 'leaflet';

import { CultureZone } from '../../../../../../../api/models/field.model';
import { CULTURE_POLYGON_OPTIONS } from '../../../../../../dashboard/modules/fields/utils';

import { BasePolygon } from './index';

/**
 * @module BaseCulturePolygon
 * КЗ-полигон. Может быть как пустым (нет заливки), так и заполненным.
 * Хранит в себе информационную модель культурной зоны.
 * Автоматически извлекает цвет из инф. модели и устанавливает его как заливку
 */
class BaseCulturePolygon extends BasePolygon {
  private readonly _dataModel: CultureZone;

  constructor(geometry: Geometry, dataModel: CultureZone, options?: PolylineOptions) {
    const color = dataModel?.culture?.attrs?.assistanceColorLegend;
    const opts = options ?? CULTURE_POLYGON_OPTIONS.cultureZone(color);

    super(geometry, opts);

    this._dataModel = dataModel;
  }

  /**
   * Информационная модель текущего КЗ-полигона
   */
  public get dataModel(): CultureZone {
    return this._dataModel;
  }

  public changeGeometry(geometry?: Geometry) {
    super.changeGeometry(geometry);

    this._dataModel.geometry = geometry;
  }
}

export default BaseCulturePolygon;
