import styled, { keyframes } from 'styled-components';

import { Colors } from '../../../../../shared/constans/colors';

export const TaskViewForm = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 600px;
  z-index: 3001;
  background-color: #ffffff;
  padding: 16px 24px 24px;
  overflow-y: auto;
  scroll-behavior: smooth;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const TopBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  scrollbar-gutter: stable;
  padding-right: 8px;
`;

export const TooltipContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 224px;
`;

export const TooltipContentRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TooltipText = styled.span`
  color: ${Colors.generalWhite};
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
`;

export const TooltipDate = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: ${Colors.generalWhite};
`;

export const TooltipWrapper = styled.div`
  position: absolute;
  right: 0;

  & .task-status-data-chip {
    background: transparent;
    svg {
      fill: ${Colors.generalBlack};
    }
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 18px;

  & .task-status-tooltip span {
    white-space: break-spaces;
    overflow-wrap: break-word;
  }
`;

export const CreateNewTaskButton = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #3fb65f;
  cursor: pointer;
`;

export const ActionsBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const ActionDelete = styled.div<{ disabled?: boolean }>`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #f29993;
  margin-right: 24px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? `none` : `auto`)};
  cursor: ${({ disabled }) => (disabled ? `default` : `pointer`)};
`;

export const ActionEdit = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #9aa0ad;
  margin-right: 24px;
  cursor: pointer;
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-top: 24px;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 25px;
`;

export const DetailsButton = styled.button.attrs({ type: 'button' })`
  width: 160px;
  height: 40px;
  background: ${Colors.green};
  color: ${Colors.white};
  border-radius: 12px;
  border: none;
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  transition: background 0.3s ease-in-out;

  :hover {
    background: ${Colors.greenHover};
  }
`;

export const OperationInfoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const OperationTypeName = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #151f32;
  margin-right: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 406px;
  position: relative;
`;

export const TaskStatusBoldText = styled.span`
  display: block;
  margin-right: 4px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: ${Colors.black};
`;

export const TaskStatusNormalText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: ${Colors.black};
  white-space: pre-line;
`;

export const InfoBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  max-width: 100%;
  margin-bottom: 12px;
`;

export const CultureBlock = styled(InfoBlock);

export const FieldBlock = styled(InfoBlock);

export const PlanDateBlock = styled(InfoBlock);

export const AssigneeBlock = styled(InfoBlock);

export const PriorityBlock = styled(InfoBlock);

export const CommentBlock = styled.div`
  margin-top: 10px;
  width: 100%;
`;

export const CommentLabel = styled.div`
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  color: #9aa0ad;
  margin-bottom: 4px;
`;

export const Comment = styled.div`
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  color: #151f32;
`;

export const Label = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #9aa0ad;
  width: 162px;
  min-width: 162px;
  // margin-left: 15px;
`;

export const Info = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #151f32;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-left: 12px;
`;

export const SvgImg = styled.img`
  height: 32px;
  width: 32px;
  max-height: 32px;
  max-width: 32px;
`;

export const PrioritySvgImg = styled.img`
  height: 16px;
  width: 16px;
  max-height: 16px;
  max-width: 16px;
  margin: 0 8px;
`;

export const StatusActionsBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  & > button {
    justify-content: center;
    width: 160px;
  }
`;

export const SkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 585px;
  z-index: 4001;
  background-color: #ffffff;
  padding: 16px 24px 24px;
`;

export const SkHeader = styled.div`
  width: 100%;
  min-height: 46px;
  margin-top: 6px;
  & > .react-loading-skeleton {
    line-height: 2;
  }
`;

export const SkOperationTypeName = styled.div`
  height: 32px;
  margin-top: 9px;
  margin-bottom: 26px;
  & > .react-loading-skeleton {
    line-height: 2;
  }
`;

export const SkInfoRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 20px;
  margin-bottom: 22px;
  & > .react-loading-skeleton {
    line-height: 1;
  }
`;

export const SkLeftcolumn = styled.div`
  height: 20px;
  width: 122px;
  margin-right: 30px;
`;

export const SkRightColumn = styled.div`
  height: 20px;
  width: calc(100% - 152px);
`;

export const SkTextarea = styled.div`
  width: 100%;
  height: 60px;
`;

export const PointsOnTheMapWidgetWrapper = styled.div`
  width: 100%;
  & .instances-list {
    max-height: calc(100vh - 670px);
  }
  margin-bottom: 49px;
  margin-top: 24px;

  & > :not(:last-child) {
    margin-bottom: 24px;
  }
`;

export const StyledWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  svg {
    cursor: pointer;
    &:hover path {
      fill: ${Colors.green}!important;
    }

    &:hover rect {
      fill: ${Colors.lightGreen}!important;
    }
  }
`;

export const NoPointsHeader = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #9aa0ad;
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const spinner = keyframes`
  to {transform: rotate(360deg);} 
`;

export const Spinner = styled.div`
  box-sizing: border-box;
  width: 100px;
  height: 100px;
  min-width: 100px;
  min-height: 100px;
  border-radius: 50%;
  border-top: 2px solid #07d;
  border-right: 2px solid transparent;
  animation: ${spinner} 0.6s linear infinite;
  // margin: 24px 0;
  align-self: center;
  z-index: 3001;
`;
