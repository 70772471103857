import { makeAutoObservable, runInAction } from 'mobx';

import { EPolygonErrorType } from '../../../../../../../shared/features/map/models/PolygonErrors/PolygonErrors.model';
import { BasePolygon } from '../../../../../../../shared/features/map/utils/MapElements';
import { provide } from '../../../../../../../shared/utils/IoC';
import { toFixedWithCeilBackEnd } from '../../../../../../../shared/utils/toFixedWithCeil';
import { TCreatedField, ECurrDrawPolygonStatus } from '../../types';

@provide.singleton()
class FieldsCreateStore {
  // Коллекция созданных полей которые отображаются в правом сайдбаре
  private _createdFieldsById = new Map<number, TCreatedField>();

  private _isLoading = false;

  private _hasErrors = false;

  /**
   * Хранит статус текущего рисуемного полигона.
   * Если полигон замкнут, то status = FINISHED
   * Если полигон не замкнут, то статус = UNFINISHED
   * @private
   */
  private _drawStatus = ECurrDrawPolygonStatus.FINISHED;

  constructor() {
    makeAutoObservable(this);
  }

  get createdFieldsList() {
    return Array.from(this._createdFieldsById.values());
  }

  get createdFieldsPolygonsList() {
    return this.createdFieldsList.map(field => field.polygon);
  }

  set hasErrors(value) {
    this._hasErrors = value;
  }

  get hasErrors() {
    return this._hasErrors;
  }

  get isFinishedPolygon() {
    return this._drawStatus === ECurrDrawPolygonStatus.FINISHED;
  }

  get isAreaTooBig() {
    return this.createdFieldsList.some(field => field.areaInHectare > 1000);
  }

  get hasCreatedField() {
    return Boolean(this._createdFieldsById.size);
  }

  set drawStatus(status: ECurrDrawPolygonStatus) {
    this._drawStatus = status;
  }

  get isLoading() {
    return this._isLoading;
  }

  set isLoading(value) {
    this._isLoading = value;
  }

  updateErrors = () => {
    runInAction(() => {
      this.createdFieldsList.forEach(field => {
        const largeAreaError = field?.polygon.errors.get(EPolygonErrorType.LargeArea);

        this._createdFieldsById.set(field.id, {
          ...field,
          error: largeAreaError?.message ?? null,
        });
      });

      this._hasErrors = this.createdFieldsList.some(field => field.polygon.errors.has());
    });
  };

  getCreatedFieldById = (fieldId: number) => {
    return this._createdFieldsById.get(fieldId);
  };

  updateFiled = filed => {
    this._createdFieldsById.set(filed.id, filed);
  };

  setCreatedField = (polygon: BasePolygon): TCreatedField => {
    const polygonInfo = polygon.getInfo();

    const newField: TCreatedField = {
      id: polygon.id,
      name: `Поле ${toFixedWithCeilBackEnd(polygonInfo.area)} га`,
      areaInHectare: parseFloat(polygonInfo.area.toFixed(7)),
      polygon,
    };

    this._createdFieldsById.set(polygon.id, newField);

    return newField;
  };

  deleteCreatedFieldById = (fieldId: number) => {
    this._createdFieldsById.delete(fieldId);
  };

  clearCreatedFieldsById = () => {
    this._createdFieldsById.clear();
  };
}

export default FieldsCreateStore;
