import React, { FC, useState } from 'react';
import { usePortals } from 'react-portal-hook';

import { Field } from '../../../../../../../api/models/field.model';
import { useStore } from '../../../../../../shared/utils/IoC';
import { toFixedWithCeilBackEnd } from '../../../../../../shared/utils/toFixedWithCeil';
import { UiStore } from '../../../../../stores/ui.store';
import { getUniqueCulturesNameList } from '../../../utils';

import { ReactComponent as MenuSvg } from './assets/menu.svg';
import { ContextMenu, FieldNameAndCulture } from './components';
import Styled from './ViewListItem.styles';

type FieldItem = {
  field: Field;
  dataTestId: string;
  onClick: () => void;
  onDelete: () => void;
  isSelected?: boolean;
  isLast?: boolean;
};

const ViewListItem: FC<FieldItem> = props => {
  const portalManager = usePortals();

  const uiStore = useStore(UiStore);

  let ContextMenuXPosition = 0;
  let ContextMenuYPosition = 0;
  const [isCardFocused, setIsCardFocused] = useState<boolean>(false);

  const handleMenuClick = event => {
    event.stopPropagation();

    setIsCardFocused(true);

    if (uiStore.isTipOpen) {
      uiStore.closeTip();
      uiStore.closePortal();
      setIsCardFocused(false);

      return;
    }

    portalManager.open(
      portal => {
        uiStore.setPortal(portal);
        return renderContextMenu(portal.close);
      },
      {
        appendTo: uiStore.tipContainerRef,
      }
    );

    const bounds = event.target.getBoundingClientRect();
    ContextMenuXPosition = bounds.x;
    ContextMenuYPosition = bounds.y;
    uiStore.openTip(bounds.x + 35, bounds.y - 10);
  };

  const renderContextMenu = closeMenu => {
    return (
      <ContextMenu
        positions={{ x: ContextMenuXPosition, y: ContextMenuYPosition }}
        closeMenu={closeMenu}
        dataTestId={props.dataTestId}
        showModal={false}
        onDeleteClick={props.onDelete}
        field={props.field}
        setIsCardFocused={setIsCardFocused}
      />
    );
  };

  const culturesString = getUniqueCulturesNameList(props.field.cultureZones).join(', ');

  return (
    <Styled.Wrapper
      onClick={props.onClick}
      isActive={props.isSelected}
      isCardFocused={isCardFocused}
      className={props.isSelected ? 'active' : ''}
      data-test-id={'fields-list-item'}
    >
      {props.isSelected && <Styled.ActiveLine />}

      <Styled.Content>
        <Styled.ContentInformation isEdit={false}>
          <Styled.MapImage
            imgUrl={props.field?.icon?.downloadUrl || ''}
            data-test-id={`${props.dataTestId}-map-photo`}
          />

          <FieldNameAndCulture
            dataTestId={props.dataTestId}
            fieldName={props.field.name}
            cultures={culturesString}
          />

          <Styled.RightColumn>
            <Styled.AreaLabel data-test-id={`${props.dataTestId}-area`}>
              {props.field.area ? toFixedWithCeilBackEnd(props.field.area) : '0'} га
            </Styled.AreaLabel>

            <Styled.RightButton className="fieldMenuButton">
              <MenuSvg onClick={handleMenuClick} data-test-id={`${props.dataTestId}-menu-button`} />
            </Styled.RightButton>
          </Styled.RightColumn>
        </Styled.ContentInformation>
      </Styled.Content>

      {!props.isLast && <Styled.Line />}
    </Styled.Wrapper>
  );
};

export default ViewListItem;
