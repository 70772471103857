enum EImportedFieldErrorName {
  InvalidGeometry = 'Неверная геометрия поля',
  Intersection = 'Имеется пересечение',
  SelfIntersection = 'Имеется самопересечение',
  SmallArea = 'Площадь меньше 0 га',
  LargeArea = 'Площадь больше 1000 га',
  ParseError = 'Поле не распознано',
}

export default EImportedFieldErrorName;
