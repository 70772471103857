import { Chip } from '@farmlink/farmik-ui';
import { FC, memo } from 'react';
import moment from 'moment';

import { EStoStatus } from '../../../../../../../../api/models/as-fields/STO/STO.model';

const CHIP_SCHEME: Record<
  EStoStatus,
  { color: 'default' | 'primary' | 'secondary' | 'info' | 'warning'; text: string }
> = {
  [EStoStatus.Active]: { color: 'primary', text: 'Версия от' },
  [EStoStatus.Draft]: { color: 'default', text: 'Черновик от' },
  [EStoStatus.Inactive]: { color: 'secondary', text: 'Неактивно c' },
  [EStoStatus.Modified]: { color: 'warning', text: 'Изменено' },
};

const StoStatusChip: FC<{ calculatedStatus: { status: EStoStatus; date: string } }> = ({
  calculatedStatus,
}) => {
  const scheme = CHIP_SCHEME[calculatedStatus?.status];

  if (!calculatedStatus) return null;

  return (
    <div>
      <Chip color={scheme.color || 'default'} size="medium" dataTestId="sto-title-chip">
        {scheme.text} {moment(calculatedStatus.date).format('DD.MM.YYYY')}
      </Chip>
    </div>
  );
};

export default memo(StoStatusChip);
