import { Colors } from '@farmlink/farmik-ui';
import styled, { CSSProperties } from 'styled-components';

interface ITitleProps {
  active?: boolean;
}

const EllipsisTextCSS: CSSProperties = {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
};

const TextWrapper = styled('div')({
  width: '100%',
  margin: '0 8px',
  overflow: 'hidden',
});

const Title = styled('h4')<ITitleProps>(
  {
    margin: 0,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    ...EllipsisTextCSS,
  },
  ({ active }) => ({
    color: active ? Colors.accentGreen : Colors.generalBlack,
    fontWeight: 600,
  })
);

const Subtitle = styled('h6')({
  margin: 0,
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '20px',
  color: Colors.secondaryDarkGray,
  ...EllipsisTextCSS,
});

export default {
  TextWrapper,
  Title,
  Subtitle,
  EllipsisTextCSS,
};
