import { Ellipsis, Typography } from '@farmlink/farmik-ui';

import { IStoHistoryVersionItem } from '../../../../../../../../../../api/models/as-fields/STO/stoHistoryVersion.model';
import { ITableBuilderConfig } from '../../../../../../../../../shared/features/TableBuilder/models/configs';
import { ActionsCell, CommentCell } from '../../components';
import { StoStatusChip } from '../../../../common';
import { EStoStatus } from '../../../../../../../../../../api/models/as-fields/STO/STO.model';

export const getTableConfig: (
  onRedirect: (id: string | 'current') => void
) => ITableBuilderConfig<IStoHistoryVersionItem, 'sto-history-tab'> = onRedirect => ({
  id: 'sto-history-tab',
  rowConfig: {
    onRowClick: (row, event, index) => {
      event.preventDefault();
      event.stopPropagation();
      onRedirect(index === 0 ? 'current' : row.id);
    },
    visual: {
      isHoverable: true,
      presets: {
        cleared: {
          grid: {
            alignItems: 'flex-start',
          },
        },
      },
    },
  },
  columnConfigList: [
    { id: 'updatedBy', name: 'Изменил', width: { default: '172px' } },
    { id: 'versionComment', name: 'Причина изменений', width: { default: '1fr' } },
    { id: 'status', name: 'Статус', width: { default: '172px' } },
    { id: 'id', name: '', width: { default: '60px' } },
  ],
  cellConfigList: [
    {
      id: 'updatedBy',
      customRenderConfig: {
        renderCell: val => (
          <Typography>
            <Ellipsis>{val.updatedBy?.fullName || val.createdBy?.fullName || '—'}</Ellipsis>
          </Typography>
        ),
      },
    },
    {
      id: 'versionComment',
      customRenderConfig: {
        renderCell: val => <CommentCell value={val.versionComment} />,
      },
    },
    {
      id: 'status',
      customRenderConfig: {
        renderCell: val => <StoStatusChip calculatedStatus={val?.calculatedStatus} />,
      },
    },
    {
      id: 'id',
      customRenderConfig: {
        renderCell: val => (
          <ActionsCell
            id={val.id}
            isModified={val.calculatedStatus?.status === EStoStatus.Modified}
          />
        ),
      },
    },
  ],
});
