import { useCallback, useMemo } from 'react';
import { generatePath } from 'react-router-dom';

import { useStore } from '../../../../shared/utils/IoC';
import { OrganizationsStore } from '../../../stores/organizations.store';
import { FieldsMapCoreStore } from '../mobx';
import { EFieldsRoutes } from '../utils';

interface IUseFieldRoutes {
  fieldsViewRoute: string;
  fieldSeasonsViewRoute: (fieldId?: string) => string;
  fieldEditRoute: (fieldId?: string) => string;
  fieldsCreateRoute: (orgId?: string) => string;
  fieldsImportRoute: string;
}

const useFieldRoutes = (): IUseFieldRoutes => {
  const organizationsStore = useStore(OrganizationsStore);
  const sharedFieldsStore = useStore(FieldsMapCoreStore);

  const selectedOrgId = organizationsStore.selectedOrganizationId;
  const { selectedField } = sharedFieldsStore;

  const fieldsViewRoute = useMemo(
    () =>
      generatePath(EFieldsRoutes.View, {
        orgId: selectedOrgId,
      }),
    [selectedOrgId]
  );

  const fieldSeasonsViewRoute = useCallback(
    (fieldId?: string) =>
      generatePath(EFieldsRoutes.Seasons, {
        orgId: selectedOrgId,
        fieldId: fieldId ?? selectedField?.id ?? '',
      }),
    [selectedOrgId, selectedField?.id]
  );

  const fieldEditRoute = useCallback(
    (fieldId?: string) =>
      generatePath(EFieldsRoutes.Edit, {
        orgId: selectedOrgId,
        fieldId: fieldId ?? selectedField?.id ?? '',
      }),
    [selectedOrgId, selectedField?.id]
  );

  const fieldsCreateRoute = useCallback(
    (orgId?: string) => {
      return generatePath(EFieldsRoutes.Add, {
        orgId: orgId ?? selectedOrgId,
      });
    },
    [selectedOrgId]
  );

  const fieldsImportRoute = useMemo<string>(() => {
    return generatePath(EFieldsRoutes.Import, {
      orgId: selectedOrgId,
    });
  }, [selectedOrgId]);

  return {
    fieldsViewRoute,
    fieldSeasonsViewRoute,
    fieldEditRoute,
    fieldsCreateRoute,
    fieldsImportRoute,
  };
};

export default useFieldRoutes;
