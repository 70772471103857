import { Colors, ENotificationType, Icon, useModal, useNotificator } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import Popup from 'reactjs-popup';

import { CultureZone } from '../../../../../../../../../../../../../../api/models/field.model';
import { useDataTestIdV2 } from '../../../../../../../../../../../../../shared/features/utils/hooks/locators';
import { useStore } from '../../../../../../../../../../../../../shared/utils/IoC';
import { EDashboardExternalRoutes } from '../../../../../../../../../../../../dashboard.routes';
import {
  FieldCulturesController as CulturesController,
  FieldSeasonsViewStore as Store,
  FieldCulturesStore as CulturesStore,
} from '../../../../../../../../mobx';
import CulturesAPIService from '../../../../../../../../mobx/services/FieldCulturesAPI.service';
import { EFieldSeasonsModalName as EModalName } from '../../../../../../../../modals';

import Styled from './ContextMenu.styles';

type TClosePopupFn = () => void;

interface IProps {
  cultureZone: CultureZone;
  // Указывает что это единственная культурная зона у поля
  isInitial: boolean;
  hideDeleteButton: boolean;
  onOpen: () => void;
}

const CultureZoneCardContextMenu: FC<IProps> = props => {
  const { cultureZone, isInitial, hideDeleteButton, onOpen } = props;

  // Сторы
  const store = useStore(Store);
  const culturesStore = useStore(CulturesStore);

  // Контроллеры
  const culturesController = useStore(CulturesController);

  // Сервисы
  const culturesAPIService = useStore(CulturesAPIService);

  const modal = useModal();
  const notificator = useNotificator();
  const getDataTestId = useDataTestIdV2('field-season-culture-zone-card-menu');

  const handleEditClick = (closePopupFn: TClosePopupFn) => {
    culturesController.enableEditCultureZone(cultureZone);
    closePopupFn();
  };

  const handleGoToExperimentClick = (closePopupFn: TClosePopupFn) => {
    const currentStage = window._env_.CURRENT_STAGE;

    const expBaseUrl = window._env_[`EXPERIMENTS_${currentStage}_URL`] ?? 'http://localhost:3000/';
    const expPath = `${EDashboardExternalRoutes.Experiments}/${cultureZone.experiment?.experimentId}/execution`;

    const link = new URL(expPath, expBaseUrl).href;
    window.open(link, '_self');

    closePopupFn();
  };

  const handleDeleteClick = (closePopupFn: TClosePopupFn) => {
    const modalId = isInitial ? EModalName.resetField : EModalName.deleteField;

    modal.openModalByModalId(modalId, null, () => {
      culturesStore.makeCultureZonesSnapshot();
      culturesController.deleteCultureZone(cultureZone);

      culturesAPIService
        .saveCultureZones(store.selectedSeason)
        .then(() => {
          notificator.setNotification({
            message: `Данные ${isInitial ? 'Сброшены' : 'Удалены'}`,
            style: {
              type: ENotificationType.Warning,
              placement: 'top-right',
            },
          });
        })
        .catch(() => {
          culturesController.restoreSnapshot();
        });
    });

    closePopupFn();
  };

  return (
    <Popup
      className="menu-dropDown"
      trigger={
        <Styled.MenuIcon
          width={16}
          height={16}
          data-test-id={getDataTestId('trigger')['data-test-id']}
        />
      }
      onOpen={onOpen}
      position="right top"
      arrow={false}
      contentStyle={{
        width: 'auto',
      }}
    >
      {closePopupFn => (
        <Styled.PopupWrapper>
          <Styled.PopupItem
            onClick={() => handleEditClick(closePopupFn)}
            {...getDataTestId('edit-item')}
          >
            <Icon icon={'edit'} size={'medium'} />
            <Styled.Text>Редактировать</Styled.Text>
          </Styled.PopupItem>

          {cultureZone.experiment?.experimentId && (
            <Styled.PopupItem
              onClick={() => handleGoToExperimentClick(closePopupFn)}
              {...getDataTestId('go-to-exp-item')}
            >
              <Icon icon={'sidebarExperiences'} size={'medium'} />
              <Styled.Text>Перейти в опыт</Styled.Text>
            </Styled.PopupItem>
          )}

          {!hideDeleteButton && (
            <Styled.PopupItem
              onClick={() => handleDeleteClick(closePopupFn)}
              {...getDataTestId('remove-item')}
            >
              <Icon icon={'bin'} size={'medium'} fill={Colors.red} />
              <Styled.Text $color={Colors.red}>
                {isInitial ? 'Cбросить данные' : 'Удалить участок'}
              </Styled.Text>
            </Styled.PopupItem>
          )}
        </Styled.PopupWrapper>
      )}
    </Popup>
  );
};

export default observer(CultureZoneCardContextMenu);
