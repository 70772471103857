/**
 * Трансформирует Map Collection в массив
 */
const mapToArray = <T, T1>(map: Map<T, T1>): T1[] => {
  if (!map) {
    return [];
  }

  return Array.from(map.values());
};

export default mapToArray;
