import { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { NewDropdown as Dropdown, TNewDropdownProps } from '@farmlink/farmik-ui';

import { TChecklistsEnumAttrToDraw as TEnumAttrToDraw } from '../../../../models';
import {
  ChecklistsAttr as Attribute,
  ChecklistsCSSContainer as CSSContainer,
} from '../../../../components/elements';
import { useStore } from '../../../../../../../../../shared/utils/IoC';
import { ChecklistsController } from '../../../../mobx/controllers';
import { EChecklistAttributeType as EAttrType } from '../../../../../../../../../../api/models/checklist/attribute/checklist.attribute.model';
import { createChecklistsAttributeId as createAttrId } from '../../../../helpers';
import { InputFieldError } from '../../../../../../../../../shared/components/InputFieldError';
import {
  useChecklistAttrPlaceholder as useAttrPlaceholder,
  useChecklistsAttrErrorList as useAttrErrorList,
  useChecklistsAttrVisibility as useAttrVisibility,
} from '../../../../hooks';
import { ChecklistsFileAttrContainer as FileAttrContainer } from '../../ChecklistsFileAttr/ChecklistsFileAttrContainer';
import { ChecklistsStore } from '../../../../mobx/stores';
import {
  ChecklistInstancesStore,
  EChecklistMode,
} from '../../../../../../../operationsAndTasks/stores/checklist.instances.store';
import { useDataTestIdV2 } from '../../../../../../../../../shared/features/utils/hooks/locators';
import { FullscreenContext } from '../../../../../../containers/fullscreen';

interface IProps {
  attrToDraw: TEnumAttrToDraw;
}

const ChecklistsEnumAttr: FC<IProps> = ({ attrToDraw }) => {
  const { id, groupId, initialModel, value, isBlocked, dependentFileAttrId, options } = attrToDraw;

  const getDataTestId = useDataTestIdV2('checklists__enum-attribute');

  const scrollValue = useContext(FullscreenContext);

  const checklistInstancesStore = useStore(ChecklistInstancesStore);
  const checklistsStore = useStore(ChecklistsStore);
  const checklistsController = useStore(ChecklistsController);

  const isShowAttribute = useAttrVisibility(attrToDraw);
  const errorList = useAttrErrorList(attrToDraw);
  const placeholder = useAttrPlaceholder(initialModel.attribute);

  const hasNativeAutocompleteSupport = initialModel?.dependency?.autoComplete;

  const hasDependency = useMemo(() => {
    return Boolean(initialModel?.dependency?.dependencies?.length);
  }, []);

  // Отвечает за получение списка опций, когда нет зависимостей.
  useEffect(() => {
    if (hasDependency) return;

    checklistsController.fetchEnumList(groupId, id, {
      attributeId: id,
    });
  }, []);

  const dependencyValue = checklistsController.getEnumDependency(groupId, id);

  // Отвечает за получение списка опций, когда есть зависимости.
  useEffect(() => {
    if (!hasDependency) return;
    if (checklistsStore.mode !== EChecklistMode.Edit) return;

    const hasDepVal = Boolean(dependencyValue.value);
    const isNotEditedDepValue = dependencyValue.isNotEdited;
    const instanceHasAlreadyBeenSaved = Boolean(
      checklistInstancesStore.selectedInstance?.isComplete
    );

    // Чтобы не было пустых срабатываний.
    if (!hasDepVal && isNotEditedDepValue) return;

    if (!hasDepVal) {
      checklistsController.changeAttrValue(
        EAttrType.Enum,
        groupId,
        {
          ...value,
          enumValues: [],
        },
        {
          isWithoutChangeLogging: true,
        }
      );

      checklistsController.addSelectedSelectOptionList(EAttrType.Enum, groupId, id, []);
      checklistsController.addSelectOptionList(EAttrType.Enum, groupId, id, []);

      return;
    }

    const checkIfIsAllowAutocomplete = (): boolean => {
      if (!hasNativeAutocompleteSupport) return false;
      if (!instanceHasAlreadyBeenSaved) return true;
      if (isNotEditedDepValue) return false;
    };

    checklistsController.fetchEnumList(
      groupId,
      id,
      {
        attributeId: id,
        dependencyValueId: dependencyValue.value as string,
      },
      {
        isNeedToRemoveSelectedValue: !isNotEditedDepValue,
        isAllowAutocomplete: checkIfIsAllowAutocomplete(),
      }
    );
  }, [JSON.stringify(dependencyValue)]);

  const availableOptionList = useMemo(() => {
    const { selectOptionList: optionList, selectedSelectOptionList: selectedOptionList } = options;

    return optionList.filter(o => !selectedOptionList.some(so => o.value === so.value));
  }, [options.selectOptionList, options.selectedSelectOptionList]);

  const handleChange = useCallback<TNewDropdownProps['config']['field']['onChange']>(
    (newValue, otherData): void => {
      if (initialModel.attribute?.isMultiselect) {
        checklistsController.changeAttrValue(EAttrType.Enum, groupId, {
          ...value,
          enumValues: !otherData?.selectedValueList
            ? []
            : otherData.selectedValueList.map(o => o.value),
        });

        checklistsController.addSelectedSelectOptionList(
          EAttrType.Enum,
          groupId,
          id,
          !otherData?.selectedValueList ? [] : otherData.selectedValueList
        );

        return;
      }

      checklistsController.changeAttrValue(EAttrType.Enum, groupId, {
        ...value,
        enumValues: !newValue ? [] : [newValue],
      });

      checklistsController.addSelectedSelectOptionList(
        EAttrType.Enum,
        groupId,
        id,
        !newValue ? [] : [otherData.option]
      );
    },
    [options.selectOptionList]
  );

  const dropdownConfig: TNewDropdownProps['config'] = {
    field: {
      onChange: handleChange,
      isRequired: initialModel.isRequired,
      defaultValueList: options.selectedSelectOptionList,
      icons: {
        clear: {},
      },
      type: {
        search: {
          options: {
            isFullTextSearch: true,
          },
        },
        multiselect: initialModel.attribute?.isMultiselect ? {} : null,
      },
      placeholder,
    },
    body: {
      optionList: availableOptionList,
      onScrollCloseOptions: { isEnable: true, parentScrollValue: scrollValue },
    },
    visual: {
      label: initialModel.attribute?.name,
      isBlocked,
      tooltip: initialModel.toolTip,
    },
    validation: {
      error: {
        errorList,
        options: {
          isDoNotShowErrorText: true,
        },
      },
    },
    other: {
      dataTestId: getDataTestId('dropdown')['data-test-id'],
    },
  };

  return (
    <>
      {isShowAttribute ? (
        <Attribute
          width={initialModel.position.width}
          isNewLine={initialModel.position.newLine}
          id={createAttrId(groupId, id)}
          dataTestId={getDataTestId()['data-test-id']}
        >
          <Dropdown config={dropdownConfig} />

          <CSSContainer
            display={'flex'}
            justifyContent={'space-between'}
            dataTestId={getDataTestId('additional-info')['data-test-id']}
          >
            <CSSContainer dataTestId={getDataTestId('error-wrapper')['data-test-id']}>
              <InputFieldError error={{ errorList }} />
            </CSSContainer>

            <FileAttrContainer
              groupId={groupId}
              attrId={dependentFileAttrId}
              isNeedAdjustableContainer
            />
          </CSSContainer>
        </Attribute>
      ) : null}
    </>
  );
};

ChecklistsEnumAttr.displayName = 'ChecklistsEnumAttr';

export default observer(ChecklistsEnumAttr);
