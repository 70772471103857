import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import useOnclickOutside from 'react-cool-onclickoutside';

import { useStore } from '../../../../../shared/utils/IoC';
import { WeatherStore } from '../../../../stores/weather.store';
import { GetCkeckedImgUrl } from '../../../../utils/GetCkeckedImgUrl';
import { FieldsMapCoreStore } from '../../mobx';

import { FieldWeather } from './components/FieldWeather/FieldWeather';
import { PlantProtection } from './components/PlantProtection';
import { Forecast } from './components/Forecast';
import Dummy_map_bg from './assets/dummy.svg';
import {
  FWSlider,
  MainInformation,
  SnapshotOfMap,
  NoPicDummyWrapper,
  PicDummy,
  DummyText,
} from './style';

export const FullWeatherSlider: FC = observer(() => {
  // Сторы
  const store = useStore(WeatherStore);
  const sharedFieldsStore = useStore(FieldsMapCoreStore);

  const [weatherPic, SetWeatherPic] = useState<string>('');

  const idsPreventCloseFullWeatherMode = [
    'fieldsShortIcon',
    'seasonShortIcon',
    'fullContentAccordeon',
    'avatarImg',
    'avatarTitle',
  ];

  const fwSliderRef = useOnclickOutside((e: Event) => {
    if (!store.showFullWeather) {
      return;
    }
    // @ts-ignore
    if (e.path && e.path.length) {
      // @ts-ignore
      for (const iterator of e.path) {
        // @ts-ignore
        if (
          iterator.hasAttribute &&
          (iterator.hasAttribute('id') || iterator.hasAttribute('class'))
        ) {
          if (iterator.hasAttribute('id') && idsPreventCloseFullWeatherMode.includes(iterator.id))
            return;
          if (iterator.hasAttribute('class') && iterator.classList.contains('fieldMenuButton'))
            return;
        }
      }
    }

    store.showFullWeather = false;
  });

  useEffect(() => {
    const { selectedField } = sharedFieldsStore;

    GetCkeckedImgUrl(`${selectedField?.weatherPic?.downloadUrl}`, Dummy_map_bg)
      .then(SetWeatherPic)
      .catch(console.error);
  }, [sharedFieldsStore.selectedField]);

  return (
    <FWSlider
      data-visible={store.showFullWeather}
      visibility={store.showFullWeather}
      ref={fwSliderRef}
    >
      <MainInformation>
        <FieldWeather back_btn={true} forWeatherPage={true} />
        {weatherPic && <SnapshotOfMap imgUrl={weatherPic} />}
        {!weatherPic && (
          <NoPicDummyWrapper>
            <PicDummy />
            <DummyText>
              Отображение карты
              <br /> временно недоступно
            </DummyText>
          </NoPicDummyWrapper>
        )}
      </MainInformation>
      <PlantProtection />
      <Forecast />
    </FWSlider>
  );
});
