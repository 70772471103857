import { Accordeon } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import React from 'react';

import { useStore } from '../../../../../../../../../../../shared/utils/IoC';
import { EFieldTooltip } from '../../../../../../../../../../constants/FieldTooltip.enum';
import { LABEL_FIELD_FILL_OPTIONS_SET } from '../../../../../../utils';
import { FieldFillStore, FieldTooltipController } from '../../../../mobx';

interface IProps {
  isOpen: boolean;
  onOpen: () => void;
}

export const TooltipLabelAccordion = observer(({ isOpen, onOpen }: IProps) => {
  // Контроллеры
  const fieldTooltipController = useStore(FieldTooltipController);

  // Сторы
  const fieldFillStore = useStore(FieldFillStore);

  const setTooltipContent = (label: EFieldTooltip) => {
    fieldTooltipController.setTooltipContent(label);
  };

  return (
    <Accordeon
      onOpen={onOpen}
      onHover={() => 1}
      onChange={setTooltipContent}
      onMouseOut={() => 1}
      options={LABEL_FIELD_FILL_OPTIONS_SET}
      placeholder={'Выберите подпись'}
      isOpen={isOpen}
      dataTestId={'label-field-fill-switcher'}
      value={fieldFillStore.tooltipLabelValue}
    />
  );
});
