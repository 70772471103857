import React, { FC, memo } from 'react';
import { isFunction } from 'lodash';

import { useContainerDataTestId as useDataTestId } from '../../hooks';

import { TContainerTitle as TTitle } from './models';
import { ContainerDefaultTitle as DefaultTitle } from './components';

interface IHeaderProps {
  title: TTitle;
}

const ContainerTitle: FC<IHeaderProps> = ({ title }) => {
  const getDataTestId = useDataTestId();

  const formattedDataTestId = getDataTestId('title')['data-test-id'];

  if (isFunction(title)) {
    return (
      <>
        {title({
          dataTestId: formattedDataTestId,
        })}
      </>
    );
  }

  if (title?.default) {
    return <DefaultTitle dataTestId={formattedDataTestId} title={title.default} />;
  }

  return <></>;
};

ContainerTitle.displayName = 'ContainerTitle';

export default memo(ContainerTitle);
