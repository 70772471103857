import styled from 'styled-components';

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  padding-bottom: 12px;
  border-bottom: 1px solid #e7f1fd;
`;

const Dependencies = styled.div<{ $width: string }>`
  display: grid;
  grid-template-columns: ${({ $width }) => $width};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  padding: 20px 16px;

  border-radius: 16px;
  border: 1px solid #e7f1fd;
`;

const StyledStoAttributeOdzDependency = {
  TitleWrapper,
  Header,
  Dependencies,
  Container,
  Wrapper,
};

export default StyledStoAttributeOdzDependency;
