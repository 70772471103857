import { sortBy } from 'lodash';
import { observer } from 'mobx-react';
import { FC, useEffect, useMemo } from 'react';
import { useModal } from '@farmlink/farmik-ui';

import { PointCoordinatesControls } from '../../../../../shared/features/map/components';
import { FieldsStore } from '../../../../../shared/mobx/stores';
import { useStore } from '../../../../../shared/utils/IoC';
import FieldsLayout from '../../FieldsLayout';
import { TopPanelControls } from '../../components';

import useEditableFieldUpdater from './hooks/useEditableFieldUpdater';
import { FieldsImportController as Controller, FieldsImportStore as Store } from './mobx';
import FieldsImportList from './components/List/List';

const FieldsImportPage: FC = () => {
  // Контроллеры
  const controller = useStore(Controller);

  // Сторы
  const store = useStore(Store);
  const fieldsStore = useStore(FieldsStore);

  const modal = useModal();
  useEditableFieldUpdater(store.editableField);

  const fieldsList = useMemo(() => {
    const filteredList = fieldsStore.fieldsList.filter(field => field?.isImported);

    return sortBy(filteredList, ['errorType', 'area']);
  }, [fieldsStore.fieldsList]);

  useEffect(() => {
    void controller.initialize();
    modal.closeModal();

    return () => {
      controller.destroy();
    };
  }, []);

  return (
    <FieldsLayout
      id={'fields-import'}
      leftPanel={<FieldsImportList list={fieldsList} />}
      map={{
        config: {
          displayLocate: true,
        },
        controls: [<TopPanelControls />, <PointCoordinatesControls />],
      }}
    />
  );
};

export default observer(FieldsImportPage);
