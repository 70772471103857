import styled from 'styled-components';

const ButtonWrapper = styled.div`
  text-align: center;
  width: 100%;
  & > button {
    display: inline-block;
    cursor: pointer;
  }
`;

export default {
  ButtonWrapper,
};
